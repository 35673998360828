import { useRecoilState, useSetRecoilState } from "recoil";
import { orderDeleteModalState, orderListCheckedListState } from "../../atoms/orders";
import { useCallback, useEffect } from "react";
import { Avatar, Checkbox, IconButton } from "@mui/material";
import { CircleFlag } from "react-circle-flags";
import FlightTakeoffOutlinedIcon from "@mui/icons-material/FlightTakeoffOutlined";
import FlightLandOutlinedIcon from "@mui/icons-material/FlightLandOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import countries from "../../json/countriesDetailed.json";
import FormatDateTR from "../../utils/FormatDateTR";
import Currency from "../../utils/CurrencySymbol";
import LocalPrice from "../../utils/LocalPrice";
import AbbreviateName from "../../utils/AbbreviateName";

const OrderListItem = ({ item, exchangeRate, setOrderSelectedPackageSystem }) => {
  const [orderListCheckedList, setOrderListCheckedList] = useRecoilState(orderListCheckedListState);
  const setOrderDeleteModal = useSetRecoilState(orderDeleteModalState);

  const handleCheck = useCallback(
    (item) => {
      const isItemInTheCheckedList = orderListCheckedList.find((e) => e.id === item.id);
      if (isItemInTheCheckedList) {
        setOrderListCheckedList(
          orderListCheckedList?.filter((checkedItem) => checkedItem.id !== item.id)
        );
      } else {
        setOrderListCheckedList([...orderListCheckedList, item]);
      }
    },
    [orderListCheckedList, setOrderListCheckedList]
  );

  const isChecked = (item) => {
    if (orderListCheckedList.find((checkedItem) => checkedItem.id === item.id)) {
      return true;
    } else return false;
  };

  const tryPrice = Number(item?.remaining_payment) * exchangeRate;

  const handleDelete = useCallback(() => {
    setOrderDeleteModal({ status: true, data: item });
  }, [setOrderDeleteModal, item]);

  useEffect(() => {
    if (orderListCheckedList.length < 2) {
      setOrderSelectedPackageSystem("S");
    }
  }, [orderListCheckedList, setOrderSelectedPackageSystem]);

  return (
    <div key={item?.id} className="order-list-item">
      <Checkbox checked={isChecked(item)} onChange={() => handleCheck(item)} />
      <div className="item-info-wrapper">
        <div className="item-info">
          <div className="item-info-child marketplace">
            {item?.get_integration_parcel && (
              <>
                <img
                  className="avatar"
                  alt="iyziShip Entegrasyonu"
                  src={
                    item?.get_integration_parcel?.platform === "ETSY"
                      ? `images/integrations/etsy_fast.svg`
                      : `images/integrations/${item?.get_integration_parcel?.platform?.toLowerCase()}.svg`
                  }
                />
                {item?.get_integration_parcel?.receipt_id}
              </>
            )}
          </div>
          <div className="item-info-child tracking">
            <div className="item-info-child-item">
              <div className="item-info-country">
                {item?.sender_country_name}
                <div className="item-info-country-icons land-out">
                  <CircleFlag
                    countryCode={countries
                      .find((e) => e.iso_code === item?.sender_country)
                      .iso_code.toLowerCase()}
                    height="18"
                  />
                  <FlightTakeoffOutlinedIcon id="take-off" />
                </div>
              </div>
              <div className="item-info-name">{AbbreviateName(item?.receiver_name)}</div>
              <div className="item-info-country">
                {item?.receiver_country_name}
                <div className="item-info-country-icons land-in">
                  <CircleFlag
                    countryCode={countries
                      .find((e) => e.iso_code === item?.receiver_country)
                      .iso_code.toLowerCase()}
                    height="18"
                  />

                  <FlightLandOutlinedIcon id="take-off" />
                </div>
              </div>
            </div>
          </div>
          <img style={{ height: "40px", width: "50px"}} src={`/images/${item.get_parcel_supplier?.supplier_logo}`} />
        </div>
        <div className="item-info">
          <div className="item-info-doctime">
            <div className="item-info-doc">{item?.doc_type_name}</div>
            <div className="item-info-time">{FormatDateTR(item?.created_at)}</div>
          </div>
          <div className="item-info-parcel">{item?.parcel_type_name}</div>
          <div className="item-info-tracking">
            <div className="tracking-container">
              <span className="tracking-title">Takip No</span>
              <span className="tracking-text">
                {item?.tracking_awb ? item?.tracking_awb : "Takip No alınamadı!"}
              </span>
            </div>
          </div>
        </div>
        <div className="item-info">
          <div>{item?.cw} kg</div>
          <div className="item-info-price-wrapper">
            <div className="item-info-price">
              <span className="currency-price">
                {Currency(item?.currency)}
                {LocalPrice(item?.remaining_payment)}
              </span>
            </div>
            <div>
              Kur: {LocalPrice(exchangeRate)} {Currency("TRY")}
            </div>
          </div>
          <div className="item-info-price-wrapper">
            <div className="item-info-price">
              <span className="currency-price try">{LocalPrice(tryPrice)}</span>
              <span className="currency info">{Currency("TRY")}</span>
            </div>
          </div>
        </div>
        <IconButton
          onClick={handleDelete}
          aria-label="delete"
          sx={{ backgroundColor: "var(--pastelRed)", width: "52px", height: "52px" }}
        >
          <DeleteIcon fontSize="inherit" sx={{ color: "var(--shipmentRed)" }} />
        </IconButton>
      </div>
    </div>
  );
};

export default OrderListItem;
