import { Box, Button, Modal, createTheme, ThemeProvider } from "@mui/material";
import { useRecoilState } from "recoil";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { toast } from "react-toastify";
import axios from "../../../api/axios";
import {
  selectedParcelReturnState,
  deliveryTypeState,
  returnShipmentConfirmationOpenState,
  returnShipmentInformationOpenState,
  returnShipmentPaymentOpenState,
  selectedPaymentReturnShipmentState,
  showReturnShipmentInformationState,
} from "../../../atoms/returnShipment";
import Errors from "../../../utils/Errors";

const theme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          padding: "20px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
  },
});
const ReturnShipmentConfirmation = () => {
  const [selectedParcelReturn] = useRecoilState(selectedParcelReturnState);
  const [deliveryType] = useRecoilState(deliveryTypeState);
  const [returnShipmentConfirmationOpen, setReturnShipmentConfirmationOpen] = useRecoilState(
    returnShipmentConfirmationOpenState
  );
  const [, setReturnShipmentInformationOpen] = useRecoilState(returnShipmentInformationOpenState);
  const [, setReturnShipmentPaymentOpen] = useRecoilState(returnShipmentPaymentOpenState);
  const [, selectedPaymentReturnShipment] = useRecoilState(selectedPaymentReturnShipmentState);
  const [, setShowReturnShipmentInformation] = useRecoilState(showReturnShipmentInformationState);

  const renderModalContent = () => {
    const textNotfree = "Gönderi Masrafları Navlununuzdan düşülerek Cüzdanınıza iade edilecektir.";
    const textFree = "Navlununuz cüzdanınıza aktarılacaktır. Onaylıyor musunuz ?";
    const content = {
      returnWarehousePaidFreeFrontdesk: {
        text: textFree,
      },
      returnWarehousePaidFreeWarehouseCourier: {
        text: textFree,
      },
      returnWarehousePaidNotfreeWarehouseCourier: {
        text: textNotfree,
      },
      returnWarehousePaidNotfreeDomestic: {
        text: textNotfree,
      },
      returnWarehousePaidNewParcel: {
        text: textFree,
      },
      returnSupplierPaidNotinvoiceNewParcel: {
        text: textFree,
      },
      returnSupplierPaidInvoiceIndividualNewParcel: {
        text: textFree,
      },
      returnSupplierPaidInvoiceCompanyNewParcel: {
        text: textFree,
      },
    };
    if (
      !returnShipmentConfirmationOpen.modalType.includes("NewParcel") &&
      returnShipmentConfirmationOpen.modalType.includes("returnSupplierPaid")
    ) {
      content[returnShipmentConfirmationOpen.modalType] = {
        text: returnShipmentConfirmationOpen.modalType.includes("returnSupplierPaidFree")
          ? textFree
          : textNotfree,
      };
    }
    if (
      !content.hasOwnProperty(returnShipmentConfirmationOpen.modalType) &&
      returnShipmentConfirmationOpen.modalType
    ) {
      if (
        returnShipmentConfirmationOpen.modalType.includes("returnWarehouseUnpaidNotfree") ||
        returnShipmentConfirmationOpen.modalType.includes("returnAbroadPaidNotfreeDomestic")
      ) {
        setShowReturnShipmentInformation(false);
        setReturnShipmentPaymentOpen(true);
        selectedPaymentReturnShipment(selectedParcelReturn);
      } else if (returnShipmentConfirmationOpen.modalType !== "collectionAddress")
        sendParcelReturn();
      handleClose();
      setReturnShipmentInformationOpen({
        status: true,
        modalType: returnShipmentConfirmationOpen.modalType,
      });
    } else {
      return (
        <>
          <span>{content[returnShipmentConfirmationOpen.modalType]?.text}</span>
          <Box sx={{ display: "flex", justifyContent: "center", gap: "var(--gap)" }}>
            <Button
              onClick={handleClose}
              sx={{
                backgroundColor: "var(--error)",
                color: "var(--lighterBg)",
                textTransform: "none",
                transition: "all 150ms linear",
                borderRadius: "var(--buttonBorderRadius)",
                padding: "7px 16px",
                ":hover": {
                  backgroundColor: "var(--blueBrand)",
                  transition: "all 150ms linear",
                },
              }}
            >
              Onaylamıyorum
            </Button>
            <Button
              onClick={sendParcelReturn} // TODO Değişecek
              sx={{
                backgroundColor: "var(--success)",
                color: "var(--lighterBg)",
                textTransform: "none",
                transition: "all 150ms linear",
                borderRadius: "var(--buttonBorderRadius)",
                padding: "7px 16px",
                ":hover": {
                  backgroundColor: "var(--blueBrand)",
                  transition: "all 150ms linear",
                },
              }}
            >
              Onaylıyorum
            </Button>
          </Box>
        </>
      );
    }
  };
  const sendParcelReturn = () => {
    const data = {
      delivery_type: deliveryType,
    };
    axios
      .post(`parcel-return/update/${selectedParcelReturn.id}`, data)
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data.message);
          handleClose();
          setReturnShipmentInformationOpen({
            status: true,
            modalType: returnShipmentConfirmationOpen.modalType,
          });
        }
        if (response.data.status === 400) {
          toast.error(response.data.message);
        }
      })
      .catch((error) => Errors(error));
  };
  const handleClose = () => {
    setReturnShipmentConfirmationOpen({ status: false, modalType: "" });
  };

  return (
    <ThemeProvider theme={theme}>
      <Modal open={returnShipmentConfirmationOpen.status} onClose={handleClose}>
        <Box
          sx={{
            backgroundColor: "var(--lighterBg)",
            borderRadius: "var(--inputBorderRadius)",
            padding: "20px",
            outline: 0,
            display: "grid",
            gap: "var(--gap)",
            width: "100%",
            maxWidth: "400px",
            margin: "auto",
            position: "relative",
          }}
          style={{
            maxHeight: "90vh",
            overflowY: "auto",
          }}
        >
          <CancelOutlinedIcon
            sx={{
              position: "absolute",
              right: 1,
              top: 1,
              cursor: "pointer",
              color: "var(--priceText)",
              transition: "all 80ms linear",
              ":hover": {
                color: "var(--notRed)",
                transition: "all 80ms linear",
              },
            }}
            onClick={handleClose}
          />
          {renderModalContent()}
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default ReturnShipmentConfirmation;
