import {
  Box,
  createTheme,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  ThemeProvider,
} from "@mui/material";
import { useCallback, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import axios from "../../api/axios";
import {
  awbState,
  basicSearchState,
  detailedStatusState,
  receiverCountryCodeState,
  receiverState,
  refIdState,
  selectedExportStatusState,
  selectedPriceStatusState,
  selectedSupplierState,
  senderCountryCodeState,
  senderState,
  shipmentListOrderDirectionState,
  shipmentStatusState,
  timeState,
} from "../../atoms/myShipments";
import ReturnShipmentListItem from "./ReturnShipmentListItem";
import miniLogo from "../../img/logos/logo_white_mini_loading.svg";
import {
  currentPageReturnState,
  returnListArrayLengthState,
  returnShipmentListLimitState,
  returnShipmentListLoadingState,
  returnShipmentListState,
  returnTotalPagesState,
} from "../../atoms/returnShipment";
import Errors from "../../utils/Errors";

const theme = createTheme({
  components: {
    MuiSelect: {
      styleOverrides: {
        select: {
          display: "flex",
        },
      },
    },
  },
});

const MyShipmentsList = () => {
  const shipmentOrderDirectionPage = useRecoilValue(shipmentListOrderDirectionState);

  const [returnShipmentListLoading, setReturnShipmentListLoading] = useRecoilState(
    returnShipmentListLoadingState
  );
  const [returnShipmentList, setReturnShipmentList] = useRecoilState(returnShipmentListState);
  const [returnShipmentListLimit, setReturnShipmentListLimit] = useRecoilState(
    returnShipmentListLimitState
  );
  const [currentPageReturn, setCurrentPageReturn] = useRecoilState(currentPageReturnState);
  const [, setReturnListArrayLength] = useRecoilState(returnListArrayLengthState);
  const [returnTotalPages, setDeductionCostTryPages] = useRecoilState(returnTotalPagesState);

  const time = useRecoilValue(timeState);
  const senderCountryCode = useRecoilValue(senderCountryCodeState);
  const receiverCountryCode = useRecoilValue(receiverCountryCodeState);
  const selectedSupplier = useRecoilValue(selectedSupplierState);
  const selectedPriceStatus = useRecoilValue(selectedPriceStatusState);
  const selectedExportStatus = useRecoilValue(selectedExportStatusState);
  const awb = useRecoilValue(awbState);
  const refId = useRecoilValue(refIdState);
  const sender = useRecoilValue(senderState);
  const receiver = useRecoilValue(receiverState);
  const shipmentStatus = useRecoilValue(shipmentStatusState);
  const detailedStatus = useRecoilValue(detailedStatusState);
  const returnBasicSearch = useRecoilValue(basicSearchState);

  const getShipment = useCallback(async () => {
    setReturnShipmentListLoading(true);

    await axios
      .get(
        detailedStatus === "1"
          ? `parcel-return?order_direction=${shipmentOrderDirectionPage}&limit=${returnShipmentListLimit}&page=${currentPageReturn}&tracking_awb=${awb}&parcel_ref_no=${refId}&supplier_id=${
              selectedSupplier !== "" ? Number(selectedSupplier) : selectedSupplier
            }&status=${shipmentStatus}&tracking_awb_date=${time}&sender_name=${sender}&receiver_name=${receiver}&sender_country=${senderCountryCode}&receiver_country=${receiverCountryCode}&parcel_type=${selectedExportStatus}&paid=${selectedPriceStatus}&detailed=1`
          : `parcel-return?order_direction=${shipmentOrderDirectionPage}&limit=${returnShipmentListLimit}&page=${currentPageReturn}`
      )
      .then((response) => {
        setReturnShipmentList(response.data.data);
        setDeductionCostTryPages(response.data.totalPages);
        setReturnShipmentListLoading(false);
        setReturnListArrayLength(response.data.data.length);
      })
      .catch((error) => {
        Errors(error);
        setReturnShipmentListLoading(false);
      });
  }, [
    setReturnShipmentList,
    setReturnShipmentListLoading,
    setReturnListArrayLength,
    setDeductionCostTryPages,
    returnShipmentListLimit,
    currentPageReturn,
    shipmentOrderDirectionPage,
    time,
    senderCountryCode,
    receiverCountryCode,
    selectedSupplier,
    selectedPriceStatus,
    selectedExportStatus,
    awb,
    refId,
    sender,
    receiver,
    shipmentStatus,
    detailedStatus,
  ]);

  const handlePageChange = useCallback(
    async (e, pageValue) => {
      setCurrentPageReturn(pageValue);
      setReturnShipmentListLoading(true);

      await axios
        .get(
          detailedStatus === 1
            ? `parcel-return?order_direction=${shipmentOrderDirectionPage}&limit=${returnShipmentListLimit}&page=${pageValue}&tracking_awb=${awb}&parcel_ref_no=${refId}&supplier_id=${
                selectedSupplier !== "" ? Number(selectedSupplier) : selectedSupplier
              }&status=${shipmentStatus}&tracking_awb_date=${time}&sender_name=${sender}&receiver_name=${receiver}&sender_country=${senderCountryCode}&receiver_country=${receiverCountryCode}&parcel_type=${selectedExportStatus}&paid=${selectedPriceStatus}&detailed=${Number(
                detailedStatus
              )}`
            : `parcel-return?order_direction=${shipmentOrderDirectionPage}&limit=${returnShipmentListLimit}&page=${pageValue}&search=${returnBasicSearch}`
        )
        .then((response) => {
          setReturnShipmentList(response.data.data);
          setDeductionCostTryPages(response.data.totalPages);
          setReturnShipmentListLoading(false);
        })
        .catch((error) => {
          Errors(error);
          setReturnShipmentListLoading(false);
        });
    },
    [
      setReturnShipmentList,
      setDeductionCostTryPages,
      setReturnShipmentListLoading,
      detailedStatus,
      selectedSupplier,
      shipmentOrderDirectionPage,
      awb,
      returnBasicSearch,
      returnShipmentListLimit,
      receiver,
      receiverCountryCode,
      refId,
      selectedPriceStatus,
      selectedExportStatus,
      sender,
      senderCountryCode,
      setCurrentPageReturn,
      shipmentStatus,
      time,
    ]
  );

  useEffect(() => {
    getShipment();
  }, [getShipment]);

  return (
    <ThemeProvider theme={theme}>
      {returnShipmentListLoading ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "500px",
          }}
        >
          <img className="loading-logo" src={miniLogo} alt="" />
        </div>
      ) : returnShipmentList.length === 0 ? (
        <Box className="returnShipment-list-none">İade bulunamadı.</Box>
      ) : (
        <Box className="returnShipment-list">
          <Box className="returnShipment-list-selectors">
            <Box className="list-selectors-position start">
              <FormControl className="selector-limit" size="small" sx={{ minWidth: 100 }}>
                <InputLabel sx={{ fontSize: "13px" }} id="limit-label">
                  İade Sayısı
                </InputLabel>
                <Select
                  labelId="limit-label"
                  id="limit"
                  label="İade Sayısı"
                  sx={{
                    color: "var(--parcelText)",
                    fontSize: "13px",
                    display: "flex",
                  }}
                  value={returnShipmentListLimit}
                  onChange={(e) => setReturnShipmentListLimit(e.target.value)}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>
              <Pagination
                showFirstButton
                showLastButton
                size="small"
                count={returnTotalPages}
                page={currentPageReturn}
                onChange={handlePageChange}
              />
            </Box>
          </Box>
          {returnShipmentList.map((shipment, key) => (
            <ReturnShipmentListItem keys={key} key={key} shipment={shipment} />
          ))}
          <Box
            sx={{
              marginBottom: "20px",
              backgroundColor: "var(--lighterBg)",
              borderRadius: "var(--inputBorderRadius)",
              width: "100%",
              padding: "20px",
              boxShadow: "0px 2px 10px rgb(0 0 0 / 10%)",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Pagination
              showFirstButton
              showLastButton
              size="small"
              count={returnTotalPages}
              page={currentPageReturn}
              onChange={handlePageChange}
            />
          </Box>
        </Box>
      )}
    </ThemeProvider>
  );
};

export default MyShipmentsList;
