import { Avatar, Button, Checkbox, IconButton, Tooltip } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useCallback, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import axios from "../../api/axios";
import {
  basketCheckListState,
  basketFinancialState,
  basketItemsState,
  basketTotalItemsState,
  basketTotalPriceState,
  basketTotalPriceTryState,
} from "../../atoms/basket";
import LocalPrice from "../../utils/LocalPrice";
import { CircleFlag } from "react-circle-flags";
import NavigateParcel from "../../utils/NavigateParcel";
import Errors from "../../utils/Errors";
import DeleteIcon from "@mui/icons-material/Delete";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import Currency from "../../utils/CurrencySymbol";

const BasketDetailedItemsChild = ({ basket }) => {
  const [basketTotalItems, setBasketTotalItems] = useRecoilState(basketTotalItemsState);
  const [basketItems, setBasketItems] = useRecoilState(basketItemsState);
  const [basketCheckList, setBasketCheckList] = useRecoilState(basketCheckListState);
  const setBasketTotalPrice = useSetRecoilState(basketTotalPriceState);
  const setBasketTotalPriceTry = useSetRecoilState(basketTotalPriceTryState);
  const setBasketFinancial = useSetRecoilState(basketFinancialState);

  const [deleteLoading, setDeleteLoading] = useState(false);

  const handleDelete = useCallback(
    (e) => {
      setDeleteLoading(true);

      axios
        .post(`basket/delete/${basket?.id}`)
        .then((response) => {
          if (response.data.status === 200) {
            setBasketItems(basketItems.filter((basket) => basket?.id !== e.id));
            setBasketTotalItems(basketTotalItems - 1);
            setDeleteLoading(false);
          }
        })
        .catch((error) => {
          Errors(error);
        });
    },
    [basketItems, setBasketItems, basket?.id, setBasketTotalItems, basketTotalItems]
  );

  const handleChangePayment = useCallback(() => {
    setBasketCheckList(
      basketCheckList.find((e) => e.id === basket?.id)
        ? basketCheckList.filter((e) => e.id !== basket?.id)
        : [...basketCheckList, basket]
    );

    const updatedBasket = basketCheckList.find((e) => e.id === basket?.id)
      ? [...basketCheckList.filter((e) => e.id !== basket?.id)]
      : [...basketCheckList, basket];

    const newTotalPrice = updatedBasket.reduce(
      (acc, item) => acc + parseFloat(item.get_payment.get_parcel.remaining_payment),
      0
    );

    const newTotalPriceTry = updatedBasket.reduce(
      (acc, item) => acc + parseFloat(item.get_payment.get_parcel.remaining_payment_try),
      0
    );

    setBasketTotalPrice(Number(newTotalPrice).toFixed(2));
    setBasketTotalPriceTry(Number(newTotalPriceTry).toFixed(2));
  }, [basket, basketCheckList, setBasketCheckList, setBasketTotalPrice, setBasketTotalPriceTry]);

  const handleBasketFinancial = useCallback(() => {
    setBasketFinancial({
      status: true,
      data: basket?.get_payment,
      date: basket?.created_at,
    });
  }, [setBasketFinancial, basket]);

  return (
    <div className="basket-detailed-list-item">
      <Checkbox
        checked={!!basketCheckList.find((item) => item.id === basket?.id)}
        onChange={handleChangePayment}
        className="check-area"
      />
      <div className="list-item-marketplace">
        {basket?.get_payment?.get_parcel?.get_integration_parcel && (
          <>
            <img
              className="avatar"
              alt="iyziShip Entegrasyon"
              src={`images/integrations/${basket?.get_payment?.get_parcel?.get_integration_parcel?.platform?.toLowerCase()}.svg`}
            />
            {basket?.get_payment?.get_parcel?.get_integration_parcel?.receipt_id}
          </>
        )}
      </div>

      <div className="list-item-flag">
        <div className="list-item-flag-country">
          <span className="flag-country-name">
            {basket?.get_payment?.get_parcel?.sender_country_name}
          </span>
          <div className="flag-country-icon-wrapper">
            <FlightTakeoffIcon className="flag-country-icon takeoff" />
            <CircleFlag
              height="18"
              countryCode={basket?.get_payment?.get_parcel?.sender_country?.toLowerCase()}
            />
          </div>
        </div>
        <NavigateParcel
          parcel={basket?.get_payment?.get_parcel?.parcel_ref_no}
          status={basket?.get_payment?.get_parcel?.status}
          fontSize={14}
        />
        <div className="list-item-flag-country">
          <span className="flag-country-name">
            {basket?.get_payment?.get_parcel?.receiver_country_name}
          </span>
          <div className="flag-country-icon-wrapper">
            <FlightLandIcon className="flag-country-icon land" />
            <CircleFlag
              height="18"
              countryCode={basket?.get_payment?.get_parcel?.receiver_country?.toLowerCase()}
            />
          </div>
        </div>
      </div>
      <Tooltip
        title={basket?.get_payment.get_parcel.get_parcel_supplier.supplier_name}
        placement="top"
      >
        <img
          src={`/images/${basket?.get_payment.get_parcel.get_parcel_supplier.supplier_logo}`}
          className="list-item-avatar"
          style={{
            width: "50px",
            height: "50px",
          }}
        />
      </Tooltip>
      <div className="list-item-pricing-area">
        <div className="pricing-area-weight">{basket?.get_payment.get_parcel?.cw} kg</div>
        <div className="pricing-area-foreign-money">
          <div className="foreign-money">
            {Currency(basket?.get_payment.get_parcel?.currency)}
            {LocalPrice(basket?.get_payment.get_parcel?.remaining_payment)}
          </div>
          <div className="foreign-money-exchange-rate">
            <b>Kur:</b> {LocalPrice(basket?.get_payment?.get_parcel?.exchange_rate)}
            {Currency("TRY")}
          </div>
        </div>
      </div>
      <div className="list-item-local-price">
        {LocalPrice(basket?.get_payment?.get_parcel?.remaining_payment_try)}
        {Currency("TRY")}
      </div>
      <Button className="iyzi-button" onClick={handleBasketFinancial}>
        Finansal Değerler
      </Button>
      {deleteLoading ? (
        <CircularProgress className="delete-progress" />
      ) : (
        <Tooltip title="Listeden Kaldır" placement="top">
          <IconButton className="list-item-delete-button" onClick={() => handleDelete(basket)}>
            <DeleteIcon className="list-item-delete-button-icon" />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

export default BasketDetailedItemsChild;
