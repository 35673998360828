import { Suspense, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./views/Home";
import Register from "./views/Register";
import HowItWork from "./views/HowItWork";
import HomeIntegrations from "./views/HomeIntegrations";
import HomePrice from "./views/HomePrice";
import SSS from "./views/SSS";
import Contact from "./views/Contact";
import EmailConfirmation from "./views/EmailConfirmation";
import Kvkk from "./views/Kvkk";
import CookiePolicy from "./views/CookiePolicy";
import PrivacyPolicy from "./views/PrivacyPolicy";
import TermsOfUse from "./views/TermsOfUse";
import CancellationsRefunds from "./views/CancellationsRefunds";
import Orders from "./views/Orders";
import LoginWP from "./views/LoginWP";
import CreateShipment from "./views/CreateShipment";
import Dashboard from "./views/Dashboard";
import PriceCalculation from "./views/PriceCalculation";
import ReturnShipment from "./views/ReturnShipment";
import ResetPassword from "./views/ResetPassword";
import CourierRegistration from "./views/CourierRegistration";
import MyShipments from "./views/MyShipments";
import Support from "./views/Support";
import BasketDetailed from "./views/BasketDetailed";
import FastShipment from "./views/FastShipment";
import Integrations from "./views/Integrations";
import DashInfo from "./views/DashInfo";
import ReturnInformationAdd from "./views/ReturnInformationAdd";
import ForgotPassword from "./views/ForgotPassword";
import Login from "./views/Login";
import PrivateRoutes from "./utils/PrivateRoutes";
import GeneralInfo from "./components/profilesettings/GeneralInfo";
import AddressInfo from "./components/profilesettings/AddressInfo";
import RegisteredCards from "./components/profilesettings/RegisteredCards";
import RegisteredIbans from "./components/profilesettings/RegisteredIbans";
import RegisteredDimensions from "./components/profilesettings/RegisteredDimensions";
import IossNumbers from "./components/profilesettings/IossNumbers";
import MyWalletBills from "./components/myWallet/MyWalletBills";
import MyWalletExtract from "./components/myWallet/MyWalletExtract";
import MyWalletBalance from "./components/myWallet/MyWalletiyziBalance";
import MyWalletPayment from "./components/myWallet/MyWalletPayment";
import MyWalletRefund from "./components/myWallet/MyWalletRefund";
import MyWalletArrangement from "./components/myWallet/MyWalletArrangement";
import IyziTeam from "./views/IyziTeam";
import AboutUs from "./views/AboutUs";
import Etsy from "./views/Etsy";

function App() {
  useEffect(() => {
    window.removeEventListener("message", (event) => event.data);
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        {/* -------------- Normal -------------- */}
        <Route exact path="/" element={<Home />} />
        <Route exact path="/login-w-p" element={<LoginWP />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/forgot" element={<ForgotPassword />} />
        <Route exact path="/reset-password" element={<ResetPassword />} />
        <Route exact path="/nasil-calisir" element={<HowItWork />} />
        <Route exact path="/entegrasyonlar" element={<HomeIntegrations />} />
        <Route exact path="/fiyatlandirma" element={<HomePrice />} />
        <Route exact path="/iletisim" element={<Contact />} />
        <Route exact path="/sss" element={<SSS />} />
        <Route exact path="/email-confirmation" element={<EmailConfirmation />} />
        <Route exact path="/kvkk" element={<Kvkk />} />
        <Route exact path="/cerez-politikasi" element={<CookiePolicy />} />
        <Route exact path="/gizlilik-politikasi" element={<PrivacyPolicy />} />
        <Route exact path="/kullanim-kosullari" element={<TermsOfUse />} />
        <Route exact path="/iptal-ve-iadeler" element={<CancellationsRefunds />} />
        <Route exact path="/iyziteam" element={<IyziTeam />} />
        <Route exact path="/hakkimizda" element={<AboutUs />} />
        <Route exact path="/etsy" element={<Etsy />} />

        {/* -------------- User -------------- */}
        <Route element={<PrivateRoutes />}>
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/profile-settings/general" element={<GeneralInfo />} />
          <Route exact path="/create-shipment" element={<CreateShipment />} />
          <Route exact loader={Suspense} path="/create-fast-shipment" element={<FastShipment />} />
          <Route exact path="/orders" element={<Orders />} />
          <Route exact path="/my-shipment" element={<MyShipments />} />
          <Route exact path="/calculator" element={<PriceCalculation />} />
          <Route exact path="/returns" element={<ReturnShipment />} />
          <Route exact path="/support" element={<Support />} />
          <Route exact path="/basket" element={<BasketDetailed />} />
          <Route exact path="/courier-registration" element={<CourierRegistration />} />
          <Route exact path="/profile-settings/address-info" element={<AddressInfo />} />
          <Route exact path="/profile-settings/registered-cards" element={<RegisteredCards />} />
          <Route exact path="/profile-settings/registered-ibans" element={<RegisteredIbans />} />
          <Route
            exact
            path="/profile-settings/registered-dimensions"
            element={<RegisteredDimensions />}
          />
          <Route exact path="/profile-settings/ioss-numbers" element={<IossNumbers />} />
          <Route exact path="/my-wallet" element={<MyWalletBalance />} />
          <Route exact path="/my-wallet/my-bills" element={<MyWalletBills />} />
          <Route exact path="/my-wallet/extract" element={<MyWalletExtract />} />
          <Route exact path="/my-wallet/add-balance" element={<MyWalletPayment />} />
          <Route exact path="/my-wallet/balance-refund-request" element={<MyWalletRefund />} />
          <Route
            exact
            path="/my-wallet/compensation-proceedings"
            element={<MyWalletArrangement />}
          />
          <Route exact path="/info" element={<DashInfo />} />
          <Route exact path="/integrations" element={<Integrations />} />
          <Route exact path="/return-information-add" element={<ReturnInformationAdd />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
