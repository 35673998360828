import React from "react";
import { Divider, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CurrencySymbol from "../../utils/CurrencySymbol";
import LocalPrice from "../../utils/LocalPrice";
import SupplierServicesType from "../../utils/SupplierServicesType";

const ShowPrice = ({ price }) => {
  const initialPrice = parseFloat(price.selling_price);

  return (
    <div className="get-card-wrapper">
      <div className="get-card">
        <div className="get-card-header">
          <div className="get-card-header-headline">
            {price.supplier_note_tr && (
              <Tooltip title={price.supplier_note_tr}>
                <InfoOutlinedIcon sx={{ color: "var(--info)" }} className="logo-warning" />
              </Tooltip>
            )}
            <img
              src={`/images/${price.supplier_logo}`}
              alt={`${price.supplier_name} Logo Görseli`}
              style={{
                marginLeft: price.supplier_note_tr ? 0 : "31px",
                height: "80px",
                width: "80px",
              }}
            />
            {SupplierServicesType(price.service_type)}
            {price.supplier_logo === "347.svg" && (
              <span>(ETGB'siz)</span>
            )}
            {price.supplier_id === 336 && (
              <span>(ETGB'li)</span>
            )}
          </div>
          <div className="get-card-header-subtext">
            <p> Teslimat Süresi:&nbsp;<span>{price.transit}</span>&nbsp;iş günü </p>
          </div>
        </div>
        <Divider />
        <div className="get-card-main">
          <div className="get-card-main-price-wrapper">
            <div className="get-card-main-price">
              <p className="main-price-out">{LocalPrice(initialPrice)}
                <span>
                  {CurrencySymbol(price.currency)}
                </span>
              </p>
              <p className="main-price-in">
                &nbsp;(
                {LocalPrice(Number(initialPrice) * Number(price.exchange_rate))}
                &nbsp;₺)
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowPrice;
