import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { currentIngtegrationsState } from "../../../atoms/integrations";
import { useRecoilValue } from "recoil";

const ShopNameSearch = ({ shopName, setShopName }) => {
  const currentIngtegrations = useRecoilValue(
    currentIngtegrationsState
  );

  const uniqueNames = currentIngtegrations.map((item) => item.shop_name);

  return (
    <FormControl fullWidth size="small">
      <InputLabel sx={{ fontSize: "14px" }} id="supplier-select-label">
        Mağaza Adı
      </InputLabel>
      <Select
        label="Tarih Tipi"
        value={shopName}
        onChange={(e) => setShopName(e.target.value)}
      >
        <MenuItem value="">
          <em>Temizle</em>
        </MenuItem>
        {uniqueNames?.map((item, index) => (
          <MenuItem key={index} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ShopNameSearch;
