import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Button,
  Checkbox,
  CircularProgress,
  createTheme,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  ThemeProvider,
  Tooltip,
  Modal,
  Box,
  TextField,
  Autocomplete,
  InputAdornment,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import axios from "../../api/axios";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveDoneOutlinedIcon from "@mui/icons-material/RemoveDoneOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  addressTypeState
} from "../../atoms/profileSettings";
import { startAnimationState } from "../../atoms/startAnimation";
import {
  fastCheckedState,
  fastDimensionState,
  fastListState,
  fastPricesState,
  fastSenderAddressesState,
  openFastDimensionState,
  selectedFastShipmentProductState,
  priceIDValueState,
  selectedPriceObjectState,
  contractCodeState,
  dimensionState,
  insuranceFastState,
  ddpFastState,
  lucidFastState,
  errorModalOpenState,
  selectedFilesIntState
} from "../../atoms/fastShipment";
import {
  editReceiverAddressOpenState,
  selectedShipmentForMyShipmentsState,
  editProformaUpdateOpenState,
  editReceiverAddressIntegratedOpenState
} from "../../atoms/myShipments";
import countries from "../../json/countriesDetailed.json";
import IOSSCountries from "../../json/iossCountries.json";
import Errors from "../../utils/Errors";
import FastShipmentPriceModal from "./FastShipmentPriceModal";
import LocalPrice from "../../utils/LocalPrice";
import Currency from "../../utils/CurrencySymbol";
import { CircleFlag } from "react-circle-flags";
import FastReceiverAddress from "./fastShipmentListItem/FastReceiverAddress";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  fastShipmentInfoModalState,
  integrationsState,
  newIossNumberAnimationState,
  proformaUpdateOpenState,
  selectedFileState,
  iossFastState,
  addressIDState,
} from "../../atoms/membership";
import {
  FormatFieldWithInteger,
  FormatFieldWithTwoDecimals,
  FormatFieldWithTwoDecimalsWithMaxNumber,
} from "../../utils/FormatField";
import { arrivalCountryCodeState } from "../../atoms/createShipment";
import { addressChangeState, proformaChangeState, itemPriceChangeState } from "../../atoms/profileSettings";
import PackageListModal from "./fastShipmentListItem/PackageListModal";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import DeleteOutlined from "@mui/icons-material/DeleteOutlined";
import DeleteFastDialog from "./fastShipmentDialog/DeleteFastDialog";
import HSCodeMissingFastDialog from "./fastShipmentDialog/HSCodeMissingFastDialog";
import EditShipmentAddressIntegrated from "../myShipments/modals/EditShipmentAddressIntegrated";
import MyShipmentProformaUpdate from "../myShipments/modals/MyShipmentProformaUpdate";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import PhoneInput from "react-phone-input-2";
import Warring from "../../utils/Warring";
import { validateEmail } from "../../utils/emailValidation";
import CountriesTurkishSorted from "../../utils/CountriesTurkishSorted";
import iyziWeightRoundSystem from "../../utils/iyziWeightRoundSystem";
import FormatDateTR from "../../utils/FormatDateTR";
import { useNavigate } from "react-router-dom";
import { red } from '@mui/material/colors';
import SenderAddressTable from '../profilesettings/addressInfo/senderAddress/SenderAddressTable';
import DowloadPdfView from '../dowloadPdfView/DowloadPdfView';

const theme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          margin: "20px",
          overflow: "auto",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
        input: {
          marginRight: "32px",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          display: "flex",
          alignItems: "center",
        },
      },
    },
  },
});

const FastShipmentListItem = ({ setUploadFileModal, uploadFileModal, profileSenderAddress, item, allChecked, setAllChecked }) => {
  const navigate = useNavigate();
  const dataID = item.id;
  const country = countries.find((country) => country.iso_code === item?.country)?.country_name_tr;
  const IOSSCountry = IOSSCountries.find((country) => country.iso_code === item?.country);
  const isHSCodeMissing = item?.get_commoduties.some(
    (child) => !child.hs_code
  );

  const [arrivalCountryCode, setArrivalCountryCode] = useRecoilState(arrivalCountryCodeState);
  const fastSenderAddresses = useRecoilValue(fastSenderAddressesState);
  const setAddressType = useSetRecoilState(addressTypeState);
  const setStartAnimation = useSetRecoilState(startAnimationState);
  const [addressID, setAddressID] = useState();
  const [senderName, setSenderName] = useState();
  const isFastSender = fastSenderAddresses.find((e) => e.id === addressID);
  const integrations = useRecoilValue(integrationsState);
  // const [addressID, setAddressID] = useRecoilState(addressIDState);

  const [insuranceFast, setInsuranceFast] = useRecoilState(insuranceFastState);
  const [ddpFast, setDdpFast] = useRecoilState(ddpFastState);
  const [lucidFast, setLucidFast] = useRecoilState(lucidFastState);

  const [iossFast, setIossFast] = useRecoilState(iossFastState);
  const [itemPriceChange, setItemPriceChange] = useRecoilState(itemPriceChangeState);
  const [parcelType, setParcelType] = useState(false);
  const [totalCw, setTotalCw] = useState(0);

  const [addressChange, setAddressChange] = useState(false);

  const fileInputRef = useRef(null);

  const [selectedFile, setSelectedFile] = useRecoilState(selectedFileState);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [openReceiverAddress, setOpenReceiverAddress] = useState(false);
  const [hSCodeMissingDialog, setHSCodeMissingDialog] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const setFastShipmentInfoModal = useSetRecoilState(fastShipmentInfoModalState);
  const setEditReceiverAddressOpen = useSetRecoilState(editReceiverAddressOpenState);
  const [selectedShipmentForMyShipments, setSelectedShipmentForMyShipments] = useRecoilState(selectedShipmentForMyShipmentsState);
  const setEditProformaOpen = useSetRecoilState(editProformaUpdateOpenState);

  const [editReceiverAddressIntegratedOpen, setEditReceiverAddressIntegratedOpen] = useRecoilState(
    editReceiverAddressIntegratedOpenState
  );

  const [selectedFastShipmentProduct, setSelectedFastShipmentProduct] = useRecoilState(selectedFastShipmentProductState);
  const setNewIossNumberAnimation = useSetRecoilState(newIossNumberAnimationState);

  const [fastChecked, setFastChecked] = useRecoilState(fastCheckedState);
  const [fastPrices, setFastPrices] = useRecoilState(fastPricesState);
  const [fastList, setFastList] = useRecoilState(fastListState);

  const [fastSenderAddress, setFastSenderAddress] = useState(
    fastSenderAddresses.find((e) => e.is_default === "1")
  );

  const [isChecked, setIsChecked] = useState(false);
  const [dimension, setDimension] = useRecoilState(dimensionState);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorModalList, setErrorModalList] = useState([]);
  const [proforma, setProforma] = useState("");
  const [address, setAddress] = useState("");
  const [openPackageList, setOpenPackageList] = useState(false);
  const [fastShipmentPriceModalStatus, setFastShipmentPriceModalStatus] = useState(false);
  const [fastReceiverAddressModalStatus, setFastReceiverAddressModalStatus] = useState(false);
  const [selectedPriceObject, setSelectedPriceObject] = useState(selectedPriceObjectState);
  const [loading, setLoading] = useState(false);
  const [openPriceUpdate, setOpenPriceUpdate] = useState(false);
  const [dimensionChangeLoading, setDimensionChangeLoading] = useState(false);
  const [priceID, setPriceID] = useState(null);
  const [priceIDValue, setPriceIDValue] = useRecoilState(priceIDValueState);
  const [openFastDimension, setOpenFastDimension] = useState(false);
  const [openSenderAddress, setOpenSenderAddress] = useState(false);
  const [updatedSenderAddress, setUpdatedSenderAddress] = useState(false);
  const [openPriceUpdateConfirmation, setOpenPriceUpdateConfirmation] = useState(false);
  const [openFastDimensionNew, setOpenFastDimensionNew] = useState(false);
  const [fastDimension, setFastDimension] = useRecoilState(fastDimensionState);

  const [en, setEn] = useState("");
  const [boy, setBoy] = useState("");
  const [yuk, setYuk] = useState("");
  const [kg, setKg] = useState("");
  const [qty, setQty] = useState("");
  const [enNew, setEnNew] = useState("");
  const [boyNew, setBoyNew] = useState("");
  const [yukNew, setYukNew] = useState("");
  const [kgNew, setKgNew] = useState("");
  const [qtyNew, setQtyNew] = useState(1);
  const [dimensions, setDimensions] = useState([{
    en: "",
    boy: "",
    yuk: "",
    gross: "",
    qty: "1",
    status: ""
  }]);
  const [priceBtnCheck, setPriceBtnCheck] = useState(false);
  const [priceTake, setPriceTake] = useState(false);
  const [fasterDimension, setFasterDimension] = useState([]);

  const [contractCode, setContractCode] = useRecoilState(contractCodeState);
  useEffect(() => {
    setContractCode(item?.prices?.contract_code)
  }, [item])
  const priceIsDisabled = item?.prices?.length === 0;

  useEffect(() => {
    const defaultAddress = profileSenderAddress.find(address => address.is_default === "1");
    if (defaultAddress && !addressID) {
      setAddressID(defaultAddress.id);
      setSenderName(defaultAddress.contact_name);
    }
  }, [profileSenderAddress, addressID]);


  // Alıcı Adresi

  const [cityError, setCityError] = useState(false);
  const [townError, setTownError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [stateCodeError, setStateCodeError] = useState(false);
  const [postalCodeError, setPostalCodeError] = useState(false);
  const [selectedCountryError, setSelectedCountryError] = useState(false);
  const [addressError, setAddressErrorError] = useState(false);

  const [contactName, setContactName] = useState();
  const [companyName, setCompanyName] = useState();
  const [address1, setAddress1] = useState();
  const [address2, setAddress2] = useState();
  const [address3, setAddress3] = useState();
  const [postalCode, setPostalCode] = useState();
  const [state, setState] = useState();
  const [town, setTown] = useState();
  const [city, setCity] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [stateCode, setStateCode] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(selectedShipmentForMyShipments?.country);

  const [contactNameUpdated, setContactNameUpdated] = useState();
  const [companyNameUpdated, setCompanyNameUpdated] = useState();
  const [address1Updated, setAddress1Updated] = useState();
  const [address2Updated, setAddress2Updated] = useState();
  const [address3Updated, setAddress3Updated] = useState();
  const [postalCodeUpdated, setPostalCodeUpdated] = useState();
  const [stateUpdated, setStateUpdated] = useState();
  const [townUpdated, setTownUpdated] = useState();
  const [cityUpdated, setCityUpdated] = useState();
  const [phoneUpdated, setPhoneUpdated] = useState();
  const [emailUpdated, setEmailUpdated] = useState();
  const [stateCodeUpdated, setStateCodeUpdated] = useState("");
  const [idUpdated, setIdUpdated] = useState("");
  const [updatedAddress, setUpdatedAddress] = useState(item?.updated_address);
  const [updatedCommoduty, setUpdatedCommoduty] = useState(item?.updated_commoduty);
  const [selectedCountryUpdated, setSelectedCountryUpdated] = useState(selectedShipmentForMyShipments?.country);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [emailError, setEmailError] = useState(false);
  const [initialPostalCode, setInitialPostalCode] = useState(item?.postal_code);

  const [showModal, setShowModal] = useState(false);
  const invalidKeywords = ["PO BOX", "POBOX", "P.O BOX", "P.O BOX", "P.O. BOX"];

  const [zipLoading, setZipLoading] = useState(false);
  const [zipIsDone, setZipIsDone] = useState(null);

  const [zipCodeError, setZipCodeError] = useState(false);
  const [phoneIsNotValid, setPhoneIsNotValid] = useState(false);

  const selectedCountryForIoss = selectedCountryUpdated;

  useEffect(() => {
    setCityError(!city ? !cityUpdated : !city);
    setTownError(!town ? !townUpdated : !town);
    setStateError(!state ? !stateUpdated : !state);
    setStateCodeError(!stateCode ? !stateCodeUpdated : !stateCode);
    setPostalCodeError(!postalCode ? !postalCodeUpdated : !postalCode);
    setSelectedCountryError(!selectedCountry ? !selectedCountryUpdated : !selectedCountry);
    setAddressErrorError(!address1 ? !address1Updated : !address1);
  }, [city, town, state, stateCode]);

  const handleQrNavigate = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);
      navigate("/orders");
    },
    [navigate]
  );

  const handleEditReceiverAddressModal = () => {
    setSelectedShipmentForMyShipments(item);
    setOpenReceiverAddress(true);
  };

  const handleErrorModal = () => {
    setErrorModalOpen(false);
    window.location.reload();
  };

  const handUploadFileModal = () => {
    setUploadFileModal(false);
  };

  const handleEditProformaModal = () => {
    setSelectedShipmentForMyShipments(item);
    setEditProformaOpen(true);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file && file.size > 5 * 1024 * 1024) {
      // 5MB'dan büyükse
      setSelectedFile(null); // Mevcut seçimi temizle
    } else {
      setSelectedFile(file); // Dosyayı seç
      setFastChecked([
        ...fastChecked.filter((e) => e?.integration_parcel_id !== item?.id),
        {
          id: item?.id,
          price_details: {
            contract_code: contractCode,
            price_id: Number(priceID),
          },
          sender_address_id: fastSenderAddress?.id,
          receiver_address: {
            contact_name: item?.contact_name,
            email: item?.buyer_email,
            address1: item?.address1,
            address2: item?.address2,
            postal_code: item?.postal_code,
            phone: item?.phone,
            city: item?.city,
            state: item?.state,
            state_code: item?.state_code,
            town: item?.town,
            country: item?.country,
            address_type: 5,
          },
          parcel_type: parcelType ? 3 : 1,
          files: [file],
          doc_type: "N",
          // incoterm: yurtdışı gümrükleme işaretliyse DDP, işaretli değilse DDU
          incoterm:
            Number(selectedPriceObject?.ddp?.ddp) === 0 &&
              (selectedPriceObject?.supplier_id === "337" ||
                selectedPriceObject?.supplier_id === "338")
              ? "DDP"
              : Number(selectedPriceObject.ddp.ddp) === 1
                ? "DDP"
                : "DDU",
          sender_name: fastSenderAddress?.contact_name,
          receiver_name: item?.contact_name,
          sender_country: "TR",
          receiver_country: item?.country,
          /*gw,
          vw,
          cw: vw > gw ? vw : gw,*/
          insurance: insuranceFast,
          qty: Number(
            item?.get_commoduties.reduce(
              (accumulator, currentValue) => accumulator + currentValue.quantity,
              0
            )
          ),
          ddp: ddpFast,
          /*dimensions: [
            {
              length: Number(dimension.length),
              width: Number(dimension.width),
              height: Number(dimension.height),
              gross: Number(dimension.gross),
              qty: Number(dimension.qty),
            },
          ],*/
          commoduties,
          ioss: iossFast?.ioss_number,
          integration_parcel_id: item?.id,
          source: item?.platform,
          platform: item?.platform,
          parcel_source: 1,
          receipt_id: item?.receipt_id,
        },
      ]);
    }
  };

  const handleChangeIOSS = (e, arrivalCode) => {
    const value = iossFast;

    if (value === "newIOSS") {
      setIossFast("");
      setNewIossNumberAnimation(true);
      setArrivalCountryCode(arrivalCode);
    } else {
      const value = e.target.value;
      setIossFast(value);
    }
  };

  const handleShowProduct = useCallback(() => {
    setSelectedFastShipmentProduct(item?.get_commoduties);
    setOpenPackageList(true);
  }, [item, setSelectedFastShipmentProduct, setOpenPackageList]);

  const price = (curr) => {
    let initialPrice = selectedPriceObject?.selling_price;

    if (selectedPriceObject?.insurance?.insurance_available === "1") {
      if (insuranceFast === 1) {
        initialPrice += Number(selectedPriceObject?.insurance?.insurance_price);
      }
    }

    if (selectedPriceObject?.ddp?.ddp === "1") {
      if (ddpFast === 1) {
        initialPrice += Number(selectedPriceObject?.ddp?.ex_custom_price);
      }
    }

    if (curr === "TRY") {
      return initialPrice * selectedPriceObject?.exchange_rate;
    } else return initialPrice;
  };

  const priceCreated = (curr) => {
    let initialPrice = item?.get_parcel?.selling_price;

    if (item?.get_parcel?.insurance?.insurance_available === "1") {
      if (insuranceFast === 1) {
        initialPrice += Number(item?.get_parcel?.insurance?.insurance_price);
      }
    }

    if (item?.get_parcel?.ddp?.ddp === "1") {
      if (ddpFast === 1) {
        initialPrice += Number(item?.get_parcel?.ddp?.ex_custom_price);
      }
    }

    if (curr === "TRY") {
      return initialPrice * item?.get_parcel?.exchange_rate;
    } else return initialPrice;
  };

  const gw = Number(dimension?.gross) * Number(dimension?.qty);
  const vw =
    ((Number(dimension?.width) * Number(dimension?.height) * Number(dimension?.length)) / 5000) *
    Number(dimension?.qty);

  const commoduties = item?.get_commoduties?.map((e) => ({
    title: e.title,
    sku: e.sku,
    hs_code: e.hs_code,
    country: "TR",
    qty: Number(e.quantity),
    price: Number(e.price),
    currency: e.currency,
    total_price: Number(e.total_price),
    ioss: e.ioss === "null" ? " " : e.ioss,
  }));

  const hasTRYCurrency = commoduties?.some((item) => item?.currency === "TRY");

  const handleShowPrices = () => {
    if (hasTRYCurrency) {
      handleShowProduct();
      setFastShipmentInfoModal(true);
    } else {
      setFastShipmentPriceModalStatus(true);
    }
  };

  const handleFastSubmit = (e) => {
    if (e) e.preventDefault();

    if (isHSCodeMissing) {
      setHSCodeMissingDialog(true);
    } else {
      handleCreateShipment();
    }
  };

  const handleCreateShipment = useCallback(async () => {
    setLoading(true);

    const formData = new FormData();
    const dadyFormData = new FormData();

    //* Price Details
    formData.append(`[price_details][contract_code]`, contractCode);
    formData.append(`[price_details][price_id]`, Number(priceID));

    //* Receiver Address
    formData.append(`[receiver_address][contact_name]`, item?.contact_name);
    formData.append(`[receiver_address][email]`, item?.buyer_email);
    formData.append(`[receiver_address][address1]`, item?.address1);
    formData.append(`[receiver_address][address2]`, item?.address2);
    formData.append(`[receiver_address][postal_code]`, item?.postal_code);
    formData.append(`[receiver_address][phone]`, item?.phone);
    formData.append(`[receiver_address][city]`, item?.city);
    formData.append(`[receiver_address][state]`, item?.state);
    formData.append(`[receiver_address][town]`, item?.town);
    formData.append(`[receiver_address][state_code]`, item?.state_code);
    formData.append(`[receiver_address][country]`, item?.country);
    formData.append(`[receiver_address][address_type]`, 5);

    //* Dimensions
    //! Sadece bir boyut olduğu varsayılarak ilerlendi.
    /*formData.append("[dimensions][0][length]", Number(dimension.length));
    formData.append("[dimensions][0][width]", Number(dimension.width));
    formData.append("[dimensions][0][height]", Number(dimension.height));
    formData.append("[dimensions][0][gross]", Number(dimension.gross));
    formData.append("[dimensions][0][qty]", Number(dimension.qty));*/

    //* Incoterm yurtdışı gümrükleme işaretliyse DDP, işaretli değilse DDU
    formData.append(
      `[incoterm]`,
      Number(selectedPriceObject?.ddp.ddp) === 0 &&
        (selectedPriceObject.supplier_id === "337" || selectedPriceObject.supplier_id === "338")
        ? "DDP"
        : Number(selectedPriceObject.ddp.ddp) === 1
          ? "DDP"
          : "DDU"
    );

    //* Commoduduties
    //! Kaç commoduties bilgisi olduğunu bilmiyoruz.
    Array.from(commoduties).forEach((com, index) => {
      formData.append(`[commoduties][${index}][title]`, com.title);
      formData.append(`[commoduties][${index}][sku]`, com.sku);
      formData.append(`[commoduties][${index}][hs_code]`, com.hs_code);
      formData.append(`[commoduties][${index}][country]`, com.country);
      formData.append(`[commoduties][${index}][qty]`, com.qty);
      formData.append(`[commoduties][${index}][price]`, com.price);
      formData.append(`[commoduties][${index}][currency]`, com.currency);
      formData.append(`[commoduties][${index}][total_price]`, com.total_price);
    });

    formData.append(`[sender_address_id]`, addressID);
    formData.append(`[parcel_type]`, item?.get_commoduties[0]?.parcel_type);

    parcelType && formData.append(`[files][0]`, selectedFile);

    formData.append(`[doc_type]`, "N");
    formData.append(`[sender_name]`, isFastSender?.contact_name);
    formData.append(`[receiver_name]`, item?.contact_name);
    formData.append(`[sender_country]`, "TR");
    formData.append(`[receiver_country]`, item?.country);
    /*formData.append(`[gw]`, gw);
    formData.append(`[vw]`, vw);
    formData.append(`[cw]`, vw > gw ? vw : gw);*/
    formData.append(`[insurance]`, insuranceFast);
    formData.append(`[ddp]`, ddpFast);
    formData.append(`[lucid]`, lucidFast);
    formData.append(
      `[qty]`,
      Number(
        item?.get_commoduties.reduce(
          (accumulator, currentValue) => accumulator + currentValue.quantity,
          0
        )
      )
    );
    formData.append(`[ioss]`, item?.get_commoduties[0]?.ioss === "null" ? " " : item?.get_commoduties[0]?.ioss);
    formData.append(`[integration_parcel_id]`, item?.id);
    formData.append(`[source]`, item?.platform);
    formData.append(`[platform]`, item?.platform);
    formData.append(`[parcel_source]`, 1);
    formData.append(`[receipt_id]`, item?.receipt_id);

    for (let [key, value] of formData.entries()) {
      dadyFormData.append(`items[0]${key}`, value);
    }

    await axios
      .post("general/create-parcel", dadyFormData)
      .then((response) => {
        setFastList((prevList) => {
          const updatedList = prevList.map((fast) => {
            if (fast.id === dataID) {
              return {
                ...fast,
                'priceStatus': 1,
                'additionalText': "Oluşturuldu",
                'parcelRefNo': response.data.data[0].get_parcel.parcel_ref_no,
              };
            }
            return fast;
          });
          return updatedList;
        });

        setFastPrices((prevPrices) => {
          const updatedPrices = prevPrices.map((price) => {
            if (price.id === dataID) {
              return {
                ...price,
                'priceStatus': 1
              };
            }
            return price;
          });
          return updatedPrices;
        });
        setPriceID(null);
        setPriceIDValue(null);
        setDimension("");
        // setSelectedPriceObject({});
        toast.success(response.data.message);
        setLoading(false);
        setFastChecked([]);
        setAllChecked(false);
      })
      .catch(error => {
        Errors(error); // Hata yönetimi fonksiyonunu çağır
        if (error.response.data.status === 400) {
          setErrorModalOpen(true);
          setErrorModalList(error.response.data.data)
        }
      })
      .finally(() => setLoading(false));
  }, [
    item,
    selectedFile,
    parcelType,
    commoduties,
    contractCode,
    dimension,
    fastSenderAddress,
    gw,
    priceID,
    vw,
    selectedPriceObject,
    insuranceFast,
    ddpFast,
    lucidFast,
    fastList,
    dataID,
    fastPrices,
    iossFast,
    setFastPrices,
    setFastList,
  ]);

  const handleChecked = (e) => {
    setIsChecked(e.target.checked);

    if (e.target.checked) {
      setFastChecked([
        ...fastChecked,
        {
          price_details: {
            contract_code: contractCode,
            price_id: Number(priceID),
          },
          sender_address_id: fastSenderAddress?.id,
          receiver_address: {
            contact_name: item?.contact_name,
            email: item?.buyer_email,
            address1: item?.address1,
            address2: item?.address2,
            postal_code: item?.postal_code,
            phone: item?.phone,
            city: item?.city,
            state: item?.state,
            state_code: item?.state_code,
            town: item?.town,
            country: item?.country,
            address_type: 5,
          },
          parcel_type: item?.get_commoduties[0]?.parcel_type,
          files: selectedFile ? [selectedFile] : [],
          doc_type: "N",
          // incoterm: yurtdışı gümrükleme işaretliyse DDP, işaretli değilse DDU
          incoterm:
            Number(selectedPriceObject.ddp.ddp) === 0 &&
              (selectedPriceObject.supplier_id === "337" || selectedPriceObject.supplier_id === "338")
              ? "DDP"
              : Number(selectedPriceObject.ddp.ddp) === 1
                ? "DDP"
                : "DDU",
          sender_name: fastSenderAddress?.contact_name,
          receiver_name: item?.contact_name,
          sender_country: "TR",
          receiver_country: item?.country,
          /*gw,
          vw,
          cw: vw > gw ? vw : gw,*/
          insurance: insuranceFast,
          qty: Number(
            item?.get_commoduties.reduce(
              (accumulator, currentValue) => accumulator + currentValue.quantity,
              0
            )
          ),
          ddp: ddpFast,
          lucid: lucidFast,
          /*dimensions: [
            {
              length: Number(dimension.length),
              width: Number(dimension.width),
              height: Number(dimension.height),
              gross: Number(dimension.gross),
              qty: Number(dimension.qty),
            },
          ],*/
          commoduties,
          ioss: item?.get_commoduties[0]?.ioss === "null" ? " " : item?.get_commoduties[0]?.ioss,
          integration_parcel_id: item?.id,
          source: item?.platform,
          platform: item?.platform,
          parcel_source: 1,
          receipt_id: item?.receipt_id,
        },
      ]);
    } else {
      setFastChecked(fastChecked.filter((e) => e.integration_parcel_id !== item?.id));
    }
  };

  const handleShowReceiverAddress = () => {
    setFastReceiverAddressModalStatus(true);
  };

  const handleCheckPrice = async () => {
    setDimensionChangeLoading(true);
    setFastPrices(fastPrices.filter((e) => e.id !== dataID));

    const index = fastList.findIndex((item) => item.id === dataID);
    let newFastList = [...fastList];

    const dimensionId = item && item.get_dimensions && item.get_dimensions.length > 0
      ? item.get_dimensions[0].id
      : fasterDimension?.id;

    try {
      const response = await axios.get(`general/integration-parcel-price/${item?.id}`, {
        params: {
          dimension_id: dimensionId,
        },
      });

      toast.success("Fiyatlar Başarılı Bir Şekilde Getirildi.", { autoClose: 2000 });

      if (index !== -1) {
        // Yeni veriyi aynı indekse ekle ve isPriceTake özelliğini ekle
        newFastList[index] = {
          ...response.data.data[0],
          isPriceTake: "1",
          addressID: addressID,
          senderName: senderName,
        };
      } else {
        // Eğer öğe bulunamazsa, listenin sonuna ekle ve isPriceTake özelliğini ekle
        newFastList.push({
          ...response.data.data[0],
          isPriceTake: "1",
          addressID: addressID,
          senderName: senderName,
        });
      }
      setFastList(newFastList);
      setPriceID(null);
      setPriceIDValue(null);
      setPriceBtnCheck(true);
      handleShowPrices();
      setOpenPriceUpdate(false);
      setDimensions("");
    } catch (error) {
      Errors(error);
    } finally {
      setDimensionChangeLoading(false);
    }
  };


  const handleDeleteIntegerShipment = () => {
    setDeleteDialog(true);
  };

  const handleDeleteFastShipment = () => {
    setDeleteLoading(true);

    axios
      .post(`general/integration-delete/${item?.id}`)
      .then((response) => {
        setFastList(fastList?.filter((child) => child?.id !== item?.id));
        setFastChecked(fastChecked?.filter((child) => child?.id !== item?.id));
        toast.success(response.data.message);
      })
      .catch((error) => Errors(error))
      .finally(() => setDeleteLoading(false));
  };

  const matchedItem = fastChecked?.find((e) => e.integration_parcel_id === item?.id);

  useEffect(() => {
    setIsChecked(matchedItem ? true : false);
    if (matchedItem) {
      setPriceID(matchedItem.price_details.price_id);
      setPriceIDValue(matchedItem.price_details.price_id);
    } else {
      setPriceID(null);
      setPriceIDValue(null);
    }
  }, [matchedItem]);

  useEffect(() => {
    setArrivalCountryCode(arrivalCountryCode);
  }, [arrivalCountryCode, setArrivalCountryCode]);

  const handleClose = useCallback(() => {
    setLoading(false);
    setOpenFastDimension(false);
  }, [setOpenFastDimensionNew]);

  const handleCloseSenderAddress = useCallback(() => {
    setOpenSenderAddress(false);
  }, [setOpenSenderAddress]);

  const handleUpdateSenderAddress = () => {
    toast.success("Gönderi Adresi Kaydedildi", {
      autoClose: 500,
    });
    setUpdatedSenderAddress(true);
    setOpenSenderAddress(false);
  };

  const handleClosePriceUpdate = useCallback(() => {
    setLoading(false);
    setOpenPriceUpdateConfirmation(false);
  }, [setOpenPriceUpdateConfirmation]);

  const handlePriceUpdate = () => {
    setOpenPriceUpdateConfirmation(false);
    setOpenFastDimension(false);
    setOpenPriceUpdate(false);
    handleCheckPrice();
  };

  const handleCloseNew = useCallback(() => {
    setEn("");
    setBoy("");
    setYuk("");
    setKg("");
    setQty("");
    setLoading(false);
    setOpenFastDimensionNew(false);
  }, [setOpenFastDimension]);

  const handleNavigateSenderAddress = () => {
    navigate("/profile-settings/address-info");
    setAddressType("4");
    setStartAnimation(true);
  }

  const handleDecimalChange = (index, name) => (e) => {
    const value = e.target.value.replace(/,/g, '.'); // Convert commas to dots

    setDimensions((prevDimensions) => {
      const newDimensions = [...prevDimensions];
      newDimensions[index][name] = value;

      // Update total cumulative weight
      setTotalCw(calculateTotalCw(newDimensions));

      return newDimensions;
    });

    if (name === "en") {
      FormatFieldWithTwoDecimals(e, setEn);
    } else if (name === "boy") {
      FormatFieldWithTwoDecimals(e, setBoy);
    } else if (name === "yuk") {
      FormatFieldWithTwoDecimals(e, setYuk);
    } else if (name === "gross") {
      FormatFieldWithTwoDecimalsWithMaxNumber(e, setKg, 999);
    } else if (name === "qty") {
      FormatFieldWithInteger(e, setQty);
    }
  };
  const handleDecimalChangeNew = (e) => {
    const { name } = e.target;

    if (name === "en") {
      FormatFieldWithTwoDecimals(e, setEnNew);
    } else if (name === "boy") {
      FormatFieldWithTwoDecimals(e, setBoyNew);
    } else if (name === "yuk") {
      FormatFieldWithTwoDecimals(e, setYukNew);
    } else if (name === "gross") {
      FormatFieldWithTwoDecimalsWithMaxNumber(e, setKgNew, 999);
    } else if (name === "qty") {
      FormatFieldWithInteger(e, setQtyNew);
    }
  };

  const handleAddInput = () => {
    setEn("");
    setBoy("");
    setYuk("");
    setKg("");
    const newDimension = {
      id: null,
      en: "",
      boy: "",
      yuk: "",
      gross: "",
      qty: "1",
      status: "new"
    };
    setDimensions((prevDimensions) => {
      const updatedDimensions = [...prevDimensions, newDimension];

      // Update total cumulative weight
      setTotalCw(calculateTotalCw(updatedDimensions));

      return updatedDimensions;
    });
  };

  const handleChangeDimension = (index) => (e) => {
    const selectedDimensionId = e.target.value;
    const selectedDimension = fastDimension.find(dim => dim.id === selectedDimensionId);

    setDimensions((prevDimensions) => {
      const newDimensions = [...prevDimensions];
      if (selectedDimension) {
        newDimensions[index] = {
          ...newDimensions[index],
          en: selectedDimension.width,
          boy: selectedDimension.length,
          yuk: selectedDimension.height,
          gross: selectedDimension.gross,
          qty: '1',
        };
      } else {
        // Clear values if no dimension is selected
        newDimensions[index] = {
          ...newDimensions[index],
          en: '',
          boy: '',
          yuk: '',
          gross: '',
          qty: '1',
        };
      }

      // Update total cumulative weight
      setTotalCw(calculateTotalCw(newDimensions));

      return newDimensions;
    });
  };


  // useEffect(() => {
  //   handleCheckPrice();
  // },[fasterDimension?.id])

  // Alıcı Adresi

  const zipCodeHandler = useCallback(async () => {
    if (postalCode.length === 0) {
      setZipIsDone(null);
    } else {
      setZipLoading(true);
      setZipIsDone(null);
      await axios
        .post("get-geo-details", {
          postalcode: postalCode,
          country: selectedCountry,
        })
        .then((res) => {
          setZipLoading(false);
          if (res.data.data.postalcodes.length === 0) {
            setZipIsDone(false);
            setZipCodeError(false);
            setStateCode("");
          } else if (res.data.data.postalcodes.length > 1) {
            setPostalCode("");
            setStateCode("");
            setZipCodeError(true);
            setZipIsDone(false);
          } else {
            setZipIsDone(true);
            setState(res.data.data.postalcodes[0].placeName);
            setTown(
              res.data.data.postalcodes[0].adminName2
                ? res.data.data.postalcodes[0].adminName2
                : res.data.data.postalcodes[0].placeName
            );
            setCity(res.data.data.postalcodes[0].adminName1);
            setStateCode(res.data.data.postalcodes[0].adminCode1);
            setZipCodeError(false);
          }
        })
        .catch((error) => {
          Errors(error);
          setZipLoading(false);
          setZipIsDone(false);
        });
    }
  }, [postalCode, openReceiverAddress]);

  useEffect(() => {
    if (item) {
      setContactName(item.contact_name || '');
      setCompanyName(item.company_name || '');
      setAddress1(item.address1 || '');
      setAddress2(item.address2 || '');
      setAddress3(item.address3 || '');
      setPostalCode(item.postal_code || '');
      setState(item.state || '');
      setTown(item.town || '');
      setCity(item.city || '');
      setPhone(item.phone || '');
      setEmail(item.buyer_email || '');
      setStateCode(item.state_code || '');
      setSelectedCountry(item.country || '');
    }
  }, [item, openReceiverAddress]);

  const handleCloseReceiver = useCallback(() => {
    setContactName("");
    setCompanyName("");
    setAddress1("");
    setAddress2("");
    setAddress3("");
    setPostalCode("");
    setState("");
    setTown("");
    setCity("");
    setPhone("");
    setEmail("");
    setStateCode("");
    setOpenReceiverAddress(false);
  }, [openReceiverAddress, setOpenReceiverAddress]);

  const handleCloseWarning = useCallback(() => {
    setShowModal(false);
  }, [openReceiverAddress, setOpenReceiverAddress]);

  const updateReceiverAddress = async (data) => {
    if (data.phone.length < 6) {
      setPhoneIsNotValid(true);
      return;
    } else {
      await axios
        .post(`general/update-integration-receiver-address/${data.id}`, {
          contact_name: data.contact_name,
          company_name: data.company_name,
          address1: data.address1,
          address2: data.address2,
          address3: data.address3,
          postal_code: data.postal_code,
          state: data.state,
          state_code: data.state_code,
          town: data.town,
          city: data.city,
          phone: data.phone,
          buyer_email: data.email,
          country: data.country,
          address_type: data.address_type,
          address_description: data.address_description,
          force_address: 1,
        })
        .then((response) => {
          if (response.data.status === 200) {
            toast.success(response.data.message);
          }
          if (response.data.status === 400) {
            toast.error(response.data.message);
          }
          setOpenReceiverAddress(false);
          // setTimeout(() => {
          //   window.location.reload(true);
          // }, 1000);
        })
        .catch((error) => {
          Errors(error);
          setOpenReceiverAddress(false);
        })
        .finally(() => {
          setPhoneIsNotValid(false);
        });
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const updateAddress = async (e) => {
    e.preventDefault();

    const fullAddress = `${address1} ${address2} ${address3}`;
    // Check for invalid keywords
    const hasInvalidKeywords = invalidKeywords.some((keyword) =>
      fullAddress.toUpperCase().includes(keyword)
    );

    if (hasInvalidKeywords) {
      setShowModal(hasInvalidKeywords);
      return;
    } else {
      try {

        const response = await axios.post(`general/update-integration-receiver-address`, {
          id: selectedShipmentForMyShipments?.id,
          contact_name: contactNameUpdated && idUpdated === item?.id ? contactNameUpdated : contactName,
          company_name: companyNameUpdated && idUpdated === item?.id ? companyNameUpdated : companyName,
          address1: address1Updated && idUpdated === item?.id ? address1Updated : address1,
          address2: address2Updated && idUpdated === item?.id ? address2Updated : address2,
          address3: address3Updated && idUpdated === item?.id ? address3Updated : address3,
          postal_code: postalCodeUpdated && idUpdated === item?.id ? postalCodeUpdated : postalCode,
          state: stateUpdated && idUpdated === item?.id ? stateUpdated : state,
          state_code: stateCodeUpdated && idUpdated === item?.id ? stateCodeUpdated : stateCode,
          town: townUpdated && idUpdated === item?.id ? townUpdated : town,
          city: cityUpdated && idUpdated === item?.id ? cityUpdated : city,
          phone: phoneUpdated && idUpdated === item?.id ? phoneUpdated : phone,
          buyer_email: emailUpdated && idUpdated === item?.id ? emailUpdated : email,
          country: !selectedCountry ? selectedCountryUpdated : selectedCountry,
          address_type: selectedShipmentForMyShipments?.address_type,
          address_description: selectedShipmentForMyShipments?.address_description,
        });

        setAddressChange(true);

        if (response.data.status === 200) {
          toast.success(response.data.message, {
            autoClose: 500,
          });

          // Update state with the new address values
          const updatedContactName = response.data.data.contact_name;
          const updatedCompanyName = response.data.data.company_name;
          const updatedAddress1 = response.data.data.address1;
          const updatedAddress2 = response.data.data.address2;
          const updatedAddress3 = response.data.data.address3;
          const updatedPostalCode = response.data.data.postal_code;
          const updatedState = response.data.data.state;
          const updatedStateCode = response.data.data.state_code;
          const updatedTown = response.data.data.town;
          const updatedCity = response.data.data.city;
          const updatedPhone = response.data.data.phone;
          const updatedEmail = response.data.data.buyer_email;
          const updatedCountry = response.data.data.country;
          const updatedId = response.data.data.id;
          const updatedAddress = response.data.data.updated_address;
          const updatedCommoduty = response.data.data.updated_commoduty;

          setContactNameUpdated(updatedContactName);
          setCompanyNameUpdated(updatedCompanyName);
          setAddress1Updated(updatedAddress1);
          setAddress2Updated(updatedAddress2);
          setAddress3Updated(updatedAddress3);
          setPostalCodeUpdated(updatedPostalCode);
          setInitialPostalCode(updatedPostalCode);
          setStateUpdated(updatedState);
          setStateCodeUpdated(updatedStateCode);
          setTownUpdated(updatedTown);
          setCityUpdated(updatedCity);
          setPhoneUpdated(updatedPhone);
          setEmailUpdated(updatedEmail);
          setSelectedCountryUpdated(updatedCountry);
          setIdUpdated(updatedId);
          setUpdatedAddress(updatedAddress);

          // Check if the postal code has been updated
          if (!priceIsDisabled && postalCode !== initialPostalCode) {
            handleCheckPrice();
            setSelectedPriceObject("");
            setOpenPriceUpdate(true);
            setPriceID("");
            setPriceIDValue("");
          }
        } else if (response.data.status === 400) {
          toast.error(response.data.message, {
            autoClose: 500,
          });
        }

        setOpenReceiverAddress(false);

      } catch (error) {
        Errors(error);
        setOpenReceiverAddress(false);
      } finally {
        setPhoneIsNotValid(false);
      }
    }
  };


  const handleEmailChange = (e) => {
    const newEmail = e.target.value;

    setEmail(newEmail);
    setEmailUpdated(newEmail);

    if (!newEmail) {
      setIsEmailValid(false);
    } else {
      const isValidEmail = validateEmail(newEmail);
      setIsEmailValid(isValidEmail);
    }
  };

  const handlePostalCodeChange = (e) => {
    const newPostalCode = e.target.value;
    setPostalCode(newPostalCode);
    setPostalCodeUpdated(newPostalCode);
    setCity("");
    setCityUpdated("");
    setTown("");
    setTownUpdated("");
    setState("");
    setStateUpdated("");
    setStateCode("");
    setStateCodeUpdated("");
  };

  const handleRecoverDeleted = (e) => {
    e.preventDefault();
    setLoading(true);

    const data = {
      deleted: 1,
    };

    axios
      .post(`general/recover-deleted/${item?.id}`, data)
      .then((response) => {
        setFastList(fastList?.filter((child) => child?.id !== item?.id));
        setFastChecked(fastChecked?.filter((child) => child?.id !== item?.id));
        toast.success("İşlem Başarılı!", { autoClose: 2000 });
      })
      .catch((error) => Errors(error))
      .finally(() => setLoading(false));
  };

  const handleRemoveInput = (index) => {
    setDimensions((prevDimensions) => {
      const newDimensions = [...prevDimensions];
      newDimensions.splice(index, 1);

      // Update total cumulative weight
      setTotalCw(calculateTotalCw(newDimensions));

      return newDimensions;
    });
  };

  const handleAddNewDimension = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = {
      length: enNew,
      width: boyNew,
      height: yukNew,
      gross: kgNew,
      qty: qtyNew,
    };

    try {
      const response = await axios.post("member-dimension/add", data);
      toast.success(response.data.message);
      setFastDimension((prevDimensions) => [response.data.data, ...prevDimensions]);
      setOpenFastDimensionNew(false);
    } catch (error) {
      Errors(error);
    } finally {
      setLoading(false);
    }
  };

  const areDimensionsEqual = (prevDimensions, newDimensions) => {
    if (prevDimensions.length !== newDimensions.length) return false;
    for (let i = 0; i < prevDimensions.length; i++) {
      if (
        prevDimensions[i].length !== newDimensions[i].length ||
        prevDimensions[i].width !== newDimensions[i].width ||
        prevDimensions[i].height !== newDimensions[i].height ||
        prevDimensions[i].gross !== newDimensions[i].gross ||
        prevDimensions[i].qty !== newDimensions[i].qty
      ) {
        return false;
      }
    }
    return true;
  };

  const handleAddNewDimensionUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);

    const updatedDimensions = dimensions
      .filter(dim => dim.status === "new" || dim.status === "")
      .map((dim) => ({
        dimension_id: dim.status === "new" ? 0 : dim.id,
        integration_parcel_id: item?.id,
        length: dim.boy || dim.length,
        width: dim.en || dim.width,
        height: dim.yuk || dim.height,
        gross: dim.gross || dim.gross,
        qty: dim.qty,
        vw: "1",
        gw: "1",
        cw: "1",
        ds: "1",
      }));

    try {
      const response = await axios.post(`general/update-dimensions`, { dimensions: updatedDimensions });
      toast.success("Ürün ölçüleri güncellendi.");
      setFasterDimension(response.data.data.get_dimensions);
      const newDimensions = response.data.data.get_dimensions.map(dim => ({
        ...dim,
        status: ""
      }));
      setDimensions(newDimensions);

      const dimensionsHaveChanged = !areDimensionsEqual(item.get_dimensions, newDimensions);

      if (!priceIsDisabled && dimensionsHaveChanged) {
        handleCheckPrice();
        setSelectedPriceObject("");
        setPriceID("");
        setPriceIDValue("");
      }
    } catch (error) {
      Errors(error);
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  const handleAddNewDimensionManuel = async (e) => {
    e.preventDefault();
    setLoading(true);

    const dimensionsData = dimensions.filter(dimension => dimension.status === "new").map(dimension => ({
      integration_parcel_id: item?.id,
      length: dimension.boy || dimension.length,
      width: dimension.en || dimension.width,
      height: dimension.yuk || dimension.height,
      gross: dimension.gross || dimension.gross,
      qty: dimension.qty,
      vw: "1",
      gw: "1",
      cw: "1",
      ds: "1",
    }));

    try {
      const response = await axios.post("general/add-dimensions", { dimensions: dimensionsData });
      toast.success(response.data.message);
      setFasterDimension(response.data.data.get_dimensions);
      setDimensions(response.data.data.get_dimensions.map(dim => ({
        ...dim,
        status: ""
      })));  // Update di
      if (!priceIsDisabled) {
        handleCheckPrice();
        setSelectedPriceObject("");
      }
    } catch (error) {
      Errors(error);
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  useEffect(() => {
    if (item && item.get_dimensions && item.get_dimensions.length > 0) {
      const itemDimensions = item.get_dimensions.map((dim) => ({
        id: dim.id,
        en: dim.width,
        boy: dim.length,
        yuk: dim.height,
        gross: dim.gross,
        qty: dim.qty,
        status: dim.status || "" // Mevcut durumu korur
      }));

      let combinedDimensions = [...itemDimensions];

      if (fasterDimension && fasterDimension.length > 0) {
        const uniqueFasterDimensions = fasterDimension.filter(dim => !itemDimensions.some(itemDim => itemDim.id === dim.id));
        combinedDimensions = [...itemDimensions, ...uniqueFasterDimensions.map(dim => ({
          id: dim.id,
          en: dim.width,
          boy: dim.length,
          yuk: dim.height,
          gross: dim.gross,
          qty: '1',
          status: "new"
        }))];
      }

      setDimensions(combinedDimensions);
    } else if (fasterDimension && fasterDimension.length > 0) {
      setDimensions(fasterDimension.map(dim => ({
        id: dim.id,
        en: dim.width,
        boy: dim.length,
        yuk: dim.height,
        gross: dim.gross,
        qty: '1',
        status: "new"
      })));
    } else {
      setDimensions([{
        id: null,
        en: '',
        boy: '',
        yuk: '',
        gross: '',
        qty: '1',
        status: "new"
      }]);
    }
  }, [item, fasterDimension, openFastDimension]);

  const handleClearInputs = () => {
    setEn("");
    setBoy("");
    setYuk("");
    setKg("");
    setDimensions([{
      en: "",
      boy: "",
      yuk: "",
      gross: "",
      qty: "1",
      status: ""
    }]);
  }

  // useEffect(() => {
  //   if (postalCodeUpdated !== postalCode) {
  //     handleCheckPrice();
  //     setSelectedPriceObject([]);
  //   };
  // }, [addressChange]);

  useEffect(() => {
    setEmailError(!isEmailValid);
  }, [isEmailValid]);

  const uniqueDimensions = fastDimension.filter((dim, index, self) =>
    index === self.findIndex((d) => d.id === dim.id)
  );

  const getDisabledOn = () => {
    if (!postalCodeUpdated && !postalCode || !address1) {
      return !address1Updated ? true : false;
    }
    if (!address1 || !city) {
      return !cityUpdated ? true : false;
    }
    if (!city || !town) {
      return !townUpdated ? true : false;
    }
    if (!town || !stateCode) {
      return !stateCode ? true : false;
    }
    if (!stateCode || !state) {
      return !stateUpdated ? true : false;
    }
    if (!stateCode || !state) {
      return !stateUpdated ? true : false;
    }
    if (!selectedCountry) {
      return !selectedCountryUpdated ? true : false;
    }
    return "";
  };

  const [timer, setTimer] = useState(false);

  const bgColor = "var(--btnBgBlue)"

  useEffect(() => {
    const timerId = setTimeout(() => {
      setTimer(true);
    }, []);

    return () => clearTimeout(timerId); // Cleanup function
  }, []);

  const isInvalidAddress = (address) => {
    return invalidKeywords.some(keyword => address?.toUpperCase().includes(keyword));
  };

  const currentAddress = address1Updated && idUpdated === item?.id ? address1Updated : address1;
  const isInvalid = isInvalidAddress(currentAddress);

  const className =
    addressChange === true || item?.updated_address === "1"
      ? "iyzi-button-greenBold fast-address"
      : (!timer && !addressChange)
        ? bgColor
        : isInvalid || ((!postalCodeUpdated && !postalCode) ||
          (!address1 && (!address1Updated || !address1)) ||
          (!city && (!cityUpdated || !city)) ||
          (!town && (!townUpdated || !town)) ||
          (!stateCode && (!stateCodeUpdated || !stateCode)) ||
          (!state && (!stateUpdated || !state)) ||
          (!selectedCountry && (!selectedCountryUpdated || !selectedCountry)))
          ? "iyzi-button-red fast-address"
          : "iyzi-button-yellow fast-address";

  const calculateTotalCw = (dimensions) => {
    return dimensions.reduce((acc, dim) => {
      const grossWeight = parseFloat(dim.gross || 0) * parseFloat(dim.qty || 1);
      const volumetricWeight = ((Math.ceil(Number(dim.width || dim.en)) *
        Math.ceil(Number(dim.length || dim.boy)) *
        Math.ceil(Number(dim.height || dim.yuk))) / 5000) *
        parseFloat(dim.qty || 1);

      const chargeableWeight = Math.max(grossWeight, volumetricWeight);
      return acc + chargeableWeight;
    }, 0);
  };

  useEffect(() => {
    if (dimensions.length > 0) {
      setTotalCw(calculateTotalCw(dimensions));
    }
  }, [dimensions]);
  return (
    <>
      <Modal open={openFastDimension} onClose={handleClose} className="fast-dimension-modal">
        <form
          className="fast-dimension"
          onSubmit={item?.get_dimensions.length > 0 ||
            fasterDimension.length > 0 ? handleAddNewDimensionUpdate : handleAddNewDimensionManuel}
          style={{
            maxHeight: "90vh",
          }}
        >
          <Box sx={{
            width: "100%",
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
          }}>
            <CancelOutlinedIcon
              sx={{

                right: 1,
                top: 1,
                color: "var(--priceText)",
                cursor: "pointer",
                transition: "all 80ms linear",
                ":hover": {
                  color: "var(--notRed)",
                  transition: "all 80ms linear",
                },
              }}
              onClick={handleClose}
            />
          </Box>
          {dimensions.map((dimension, index) => (
            <React.Fragment key={index}>
              <Box className="fast-dimension-input">
                <FormControl sx={{ width: '140px' }} size="small" className="list-item-select">
                  <InputLabel id={`dimension-select-label-${index}`}>Hızlı Ölçü Seç</InputLabel>
                  <Select
                    labelId={`dimension-select-label-${index}`}
                    id={`dimension-select-${index}`}
                    label="Hızlı Ölçü Seç"
                    value={`${dimension.en || dimension.width || en}x${dimension.boy || dimension.length || boy}x${dimension.yuk || dimension.height || yuk} cm / ${dimension.gross || dimension.gross || kg} kg`}
                    onChange={handleChangeDimension(index)}
                    sx={{ borderRadius: '5px' }}
                    IconComponent={null} // Aşağı doğru ikonu kaldırmak için
                  >
                    <MenuItem value="">
                      Temizle
                    </MenuItem>
                    {fastDimension.map((dim, key) => (
                      <MenuItem key={key} value={dim.id}>
                        {`${dim.width}x${dim.length}x${dim.height} cm / ${dim.gross} kg`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: "170px", borderRadius: "5px" }}
                  size="small"
                  value={dimension.en || dimension.width || en}
                  onChange={handleDecimalChange(index, "en")}
                  label="En (cm)"
                  name="en"
                  required
                  autoComplete="off"
                />
                <TextField
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: "170px", borderRadius: "5px" }}
                  size="small"
                  value={dimension.boy || dimension.length || boy}
                  onChange={handleDecimalChange(index, "boy")}
                  label="Boy (cm)"
                  name="boy"
                  required
                  autoComplete="off"
                />
                <TextField
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: "170px", borderRadius: "5px" }}
                  size="small"
                  value={dimension.yuk || dimension.height || yuk}
                  onChange={handleDecimalChange(index, "yuk")}
                  label="Yükseklik (cm)"
                  name="yuk"
                  required
                  autoComplete="off"
                />
                <TextField
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: "170px", borderRadius: "5px" }}
                  size="small"
                  value={dimension.gross || dimension.gross || kg}
                  onChange={handleDecimalChange(index, "gross")}
                  label="Kg"
                  name="gross"
                  required
                  autoComplete="off"
                />
                <TextField
                  disabled
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: "170px", borderRadius: "5px" }}
                  size="small"
                  value={dimension.qty}
                  onChange={handleDecimalChange(index, "qty")}
                  label="Adet"
                  name="qty"
                  required
                  autoCompete="off"
                />
                <Box>
                  <Button className="delete-dimension" style={{ minWidth: "5px", visibility: index !== 0 ? "" : "hidden" }} onClick={() => handleRemoveInput(index)}>
                    <DeleteIcon sx={{ height: "20px" }} />
                  </Button>
                </Box>
              </Box>
              <Box className="form-dimensions-weight">
                <div>
                  <span style={{ fontSize: "13px" }}>
                    <b style={{ fontSize: "inherit" }}>Brüt Ağırlık :</b>&nbsp;
                  </span>
                  <span style={{ fontSize: "13px" }}>
                    {(dimension.gross * dimension.qty).toFixed(2)} KG
                  </span>
                </div>
                <div>
                  <span style={{ fontSize: "13px" }}>
                    <b style={{ fontSize: "inherit" }}>Hacim / Desi :</b>&nbsp;
                  </span>
                  <span style={{ fontSize: "13px" }}>
                    {Number(
                      ((Math.ceil(Number(dimension.width || dimension.en)) *
                        Math.ceil(Number(dimension.length || dimension.boy)) *
                        Math.ceil(Number(dimension.height || dimension.yuk))) /
                        5000) *
                      1
                    ).toFixed(2)}{" "} KG
                  </span>
                </div>
              </Box>
            </React.Fragment>
          ))}
          <Box sx={{
            padding: "10px",
            minWidth: "250px",
            display: "flex",
            gap: "5px",
            justifyContent: "flex-end"
          }}>
            <span style={{
              fontSize: "inherit",
              fontWeight: "700",
              color: "var(--btnBgBlue)"
            }}>Toplam Ücret Ağırlığı</span>:{" "}
            <span style={{
              fontSize: "inherit",
              fontWeight: "700",
              color: "var(--priceText)"
            }}>{iyziWeightRoundSystem(totalCw)} KG</span>
          </Box>
          <Button sx={{
            maxWidth: "1100px"
          }} onClick={handleAddInput} className="add-dimension-button">
            <AddCircleOutlineIcon className="add-dimension-button-icon" />
          </Button>
          <Box className="fast-dimension-buttons">
            <Button onClick={() => setOpenFastDimensionNew(true)} className="iyzi-button-blue">
              Hızlı Ölçü Oluştur <AddCircleIcon />
            </Button>
            <Button type="button" onClick={handleClose} className="iyzi-button-blue">
              İptal
            </Button>
            <Button type="submit" className="iyzi-button-blue">
              Kaydet
            </Button>
          </Box>
        </form>
      </Modal>
      <Modal open={openPriceUpdateConfirmation} onClose={handleClosePriceUpdate}>
        <Box className="fast-dimension-modal">
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "20px",
            backgroundColor: "white",
            padding: "30px", // Optional: Add some padding for better appearance
            borderRadius: "8px", // Optional: Add rounded corners for a nicer look
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)" // Optional: Add a shadow for a floating effect
          }}>
            <p>
              Değişiklik yaptınız. Tekrar fiyat almanız gerekmektedir.
            </p>
            <Box sx={{
              display: "flex",
              gap: "20px"
            }}>
              <Button className="iyzi-button-blue" onClick={handlePriceUpdate}>
                Fiyat Al
              </Button>
              <Button className="iyzi-button-blue" onClick={handleClosePriceUpdate}>
                Kapat
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Modal open={errorModalOpen} onClose={handleErrorModal} className="fast-dimension-modal">
        <Box sx={{
          backgroundColor: "none"
        }} className="fast-dimension-modal">
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "20px",
            backgroundColor: "white",
            padding: "30px", // Optional: Add some padding for better appearance
            borderRadius: "8px", // Optional: Add rounded corners for a nicer look
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)" // Optional: Add a shadow for a floating effect
          }}>
            <p>
              Seçilen gönderilerin bazılarında sorun meydana geldi.
            </p>
            <p>
              İlgili gönderi sipariş numaraları:
            </p>
            <p>
              {errorModalList.map((item) => item.receipt_id)}
            </p>
            <Box sx={{
              display: "flex",
              gap: "20px"
            }}>
              <Button className="iyzi-button-blue" onClick={handleErrorModal}>
                Anladım
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Modal open={openFastDimensionNew} onClose={handleCloseNew} className="fast-dimension-modal">
        <form
          className="fast-dimension"
          onSubmit={handleAddNewDimension}
          style={{
            maxHeight: "90vh",
            overflowY: "auto",
          }}
        >
          <Box sx={{
            width: "100%",
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
          }}>
            <CancelOutlinedIcon
              sx={{
                right: 1,
                top: 1,
                color: "var(--priceText)",
                cursor: "pointer",
                transition: "all 80ms linear",
                ":hover": {
                  color: "var(--notRed)",
                  transition: "all 80ms linear",
                },
              }}
              onClick={handleCloseNew}
            />
          </Box>
          <Box className="fast-dimension-input">
            <TextField
              sx={{
                width: "170px",
                borderRadius: "5px"
              }}
              size="small"
              value={enNew}
              onChange={handleDecimalChangeNew}
              label="En (cm)"
              name="en"
              required
              autoComplete="off"
            />
            <TextField
              sx={{
                width: "170px",
                borderRadius: "5px"
              }}
              size="small"
              value={boyNew}
              onChange={handleDecimalChangeNew}
              label="Boy (cm)"
              name="boy"
              required
              autoComplete="off"
            />
            <TextField
              sx={{
                width: "170px",
                borderRadius: "5px"
              }}
              size="small"
              value={yukNew}
              onChange={handleDecimalChangeNew}
              label="Yükseklik (cm)"
              name="yuk"
              required
              autoComplete="off"
            />
            <TextField
              sx={{
                width: "170px",
                borderRadius: "5px"
              }}
              size="small"
              value={kgNew}
              onChange={handleDecimalChangeNew}
              label="Kg"
              name="gross"
              required
              autoComplete="off"
            />
            <TextField
              disabled
              sx={{
                width: "170px",
                borderRadius: "5px"
              }}
              size="small"
              value={"1"}
              onChange={handleDecimalChangeNew}
              label="Adet"
              name="qty"
              required
              autoCompete="off"
            />
          </Box>
          <div className="form-dimensions-weight">
            <div>
              <span style={{ fontSize: "13px" }}>
                <b style={{ fontSize: "inherit" }}>Brüt Ağırlık :</b>&nbsp;
              </span>
              <span style={{ fontSize: "13px" }}>
                {isNaN(Number(kgNew * 1).toFixed(2)) ? "0.00 KG" : `${Number(kgNew * 1).toFixed(2)} KG`}
              </span>
            </div>
            <div>
              <span style={{ fontSize: "13px" }}>
                <b style={{ fontSize: "inherit" }}>Hacim / Desi :</b>&nbsp;
              </span>
              <span style={{ fontSize: "13px" }}>
                {`${(
                  (Math.ceil(Number(enNew)) *
                    Math.ceil(Number(boyNew)) *
                    Math.ceil(Number(yukNew)) /
                    5000) *
                  qtyNew
                ).toFixed(2)} KG`}
              </span>
            </div>
          </div>
          <Box className="fast-dimension-buttons">
            <Button type="button" onClick={handleCloseNew} className="iyzi-button-blue">
              İptal
            </Button>
            <Button type="submit" className="iyzi-button-blue">
              Kaydet
            </Button>
          </Box>
        </form>
      </Modal>
      <Modal open={openSenderAddress} onClose={handleCloseSenderAddress} className="fast-dimension-modal">
        <Box
          style={{
            backgroundColor: "var(--lighterBg)",
            borderRadius: "var(--inputBorderRadius)",
            outline: 0,
            display: "grid",
            gap: "var(--gap)",
            width: "100%",
            maxWidth: "1200px",
            margin: "auto",
            position: "relative",
            maxHeight: "90vh",
            overFlowY: "auto",
          }}
        >
          <Box sx={{
            fontSize: "20px",
            fontWeight: "500",
            marginBottom: "15px",
            paddingLeft: "5px"
          }}
            className="fast-dimension"
          >
            Gönderici Adresleri
            <SenderAddressTable item={item} addressID={addressID} setAddressID={setAddressID} address={profileSenderAddress} setSenderName={setSenderName} />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "var(--gap)",
                marginTop: "10px",
                height: "40px"
              }}
            >
              <Button type="button" className="iyzi-button-blue" onClick={handleNavigateSenderAddress}>
                Adres Ekle <AddCircleIcon />
              </Button>
              <Button type="button" className="iyzi-button-blue" onClick={handleCloseSenderAddress}>
                İptal
              </Button>
              <Button onClick={handleUpdateSenderAddress} type="submit" className="iyzi-button-blue">
                Kaydet
              </Button>
              <Warring isOpen={showModal} onClose={setShowModal} />
            </div>
          </Box>
        </Box>
      </Modal>
      <Modal open={openReceiverAddress} onClose={handleCloseReceiver} className="fast-dimension-modal">
        <Box
          style={{
            backgroundColor: "var(--lighterBg)",
            borderRadius: "var(--inputBorderRadius)",
            padding: "20px",
            outline: 0,
            display: "grid",
            gap: "var(--gap)",
            width: "100%",
            maxWidth: "1300px",
            margin: "auto",
            position: "relative",
            maxHeight: "90vh",
            overflowY: "auto"
          }}
        >
          <Box sx={{
            fontSize: "20px",
            fontWeight: "500",
            marginBottom: "10px",
            paddingLeft: "5px"
          }}>
            Alıcı Adresi
          </Box>
          <CancelOutlinedIcon
            sx={{
              position: "absolute",
              right: 1,
              top: 1,
              color: "var(--priceText)",
              cursor: "pointer",
              transition: "all 80ms linear",
              ":hover": {
                color: "var(--notRed)",
                transition: "all 80ms linear",
              },
            }}
            onClick={handleCloseReceiver}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "var(--gap2x)",
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
                gap: "var(--gap2x)",
              }}
            >
              <TextField
                value={contactNameUpdated && idUpdated === item?.id ? contactNameUpdated : contactName}
                onChange={(e) => {
                  setContactName(e.target.value)
                  setContactNameUpdated(e.target.value)
                }}
                size="small"
                label="İsim Soyisim"
                required
                onFocus={(e) => {
                  let descValue = e.target.value;
                  e.target.value = "";
                  e.target.value = descValue;
                }}
              />
              <TextField
                value={companyNameUpdated && idUpdated === item?.id ? companyNameUpdated : companyName}
                onChange={(e) => {
                  setCompanyNameUpdated(e.target.value)
                  setCompanyName(e.target.value)
                }}
                size="small"
                label="Firma Adı"
              />
              <TextField
                value={emailUpdated && idUpdated === item?.id ? emailUpdated : email}
                onChange={handleEmailChange}
                error={emailError}
                helperText={emailError && "Geçerli bir email giriniz."}
                size="small"
                label="E-Posta"
                type={"email"}
                autoComplete="off"
                required
              />
            </div>
            <TextField
              fullWidth
              required
              value={address1Updated && idUpdated === item?.id ? address1Updated : address1}
              onChange={(e) => {
                setAddress1(e.target.value)
                setAddress1Updated(e.target.value)
              }}
              size="small"
              label="Adres Satırı"
              error={!address1 ? !address1Updated : !address1 || isInvalid}
              helperText={!address1 ? "Bu alan gereklidir." : isInvalid ? "Bu posta servisine gönderim yapılamamaktadır." : ""}
            />
            <TextField
              fullWidth
              value={address2Updated && idUpdated === item?.id ? address2Updated : address2}
              onChange={(e) => {
                setAddress2(e.target.value);
                setAddress2Updated(e.target.value);
              }}
              size="small"
              label="2.Adres Satırı"
            />
            {address3 && (
              <TextField
                fullWidth
                value={address3Updated && idUpdated === item?.id ? address3Updated : address3}
                onChange={(e) => {
                  setAddress3(e.target.value);
                  setAddress3Updated(e.target.value);
                }}
                size="small"
                label="Adres Satırı 3"
              />
            )}
            <div
              style={{
                display: "grid",
                gap: "var(--gap2x)",
                gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",

              }}
            >
              <Autocomplete
                id="country-typing-select"
                fullWidth
                size="small"
                disabled={!selectedCountry ? selectedCountryUpdated : selectedCountry}
                options={CountriesTurkishSorted()}
                value={
                  !selectedCountry ? !selectedCountryUpdated ? null : CountriesTurkishSorted().find((option) => option.iso_code === selectedCountryUpdated) : CountriesTurkishSorted().find((option) => option.iso_code === selectedCountry)
                }
                onChange={(e, val) => {
                  if (val) {
                    setSelectedCountry(val.iso_code);
                  } else {
                    setSelectedCountry("");
                  }
                }}
                autoHighlight
                openOnFocus={false}
                getOptionLabel={(option) => `${option.country_name_tr}, ${option.country_name_en}`}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${option.iso_code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.iso_code.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    {option.country_name_tr}, {option.country_name_en}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Ülke Seçiniz"
                    disabled
                    name="unique-country-field"
                    value={selectedCountry ? selectedCountryUpdated : selectedCountry}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "off", // disable autocomplete and autofill
                    }}
                    error={!selectedCountry ? !selectedCountryUpdated : !selectedCountry}
                    helperText={!selectedCountry ? !selectedCountryUpdated : !selectedCountry ? "Bu alan gereklidir." : ""}
                  />
                )}
              />
              <TextField
                required
                value={postalCodeUpdated && idUpdated === item?.id ? postalCodeUpdated : postalCode}
                onChange={handlePostalCodeChange}
                placeholder="06010"
                onBlur={zipCodeHandler}
                fullWidth
                size="small"
                autoComplete="off"
                label="Posta Kodu"
                error={zipCodeError}
                InputProps={
                  zipIsDone === true
                    ? {
                      endAdornment: (
                        <InputAdornment position="end">
                          <DoneOutlinedIcon style={{ color: "green" }} />
                        </InputAdornment>
                      ),
                    }
                    : zipIsDone === false
                      ? {
                        endAdornment: (
                          <InputAdornment position="end">
                            <CloseOutlinedIcon style={{ color: "red" }} />
                          </InputAdornment>
                        ),
                      }
                      : zipIsDone === null && zipLoading === true
                        ? {
                          endAdornment: (
                            <InputAdornment position="end">
                              <CircularProgress style={{ width: "20px", height: "20px" }} />
                            </InputAdornment>
                          ),
                        }
                        : {}
                }
              />
              <FormControl size="small" fullWidth>
                <PhoneInput
                  country={!selectedCountry ? selectedCountryUpdated?.toLowerCase() : selectedCountry?.toLowerCase()}
                  value={phoneUpdated && idUpdated === item?.id ? phoneUpdated : phone}
                  onChange={(e) => {
                    setPhone(e);
                    setPhoneUpdated(e);
                  }}
                  autoComplete="off"
                  isValid={(value, country) => {
                    if (value.match(/12345/)) {
                      return "Invalid value: " + value + ", " + country.name;
                    } else if (value.match(/1234/)) {
                      return false;
                    } else {
                      return true;
                    }
                  }}
                  inputProps={{
                    name: "phone",
                    id: "ind-phone-input",
                    style: {
                      height: "100%",
                      width: "100%",
                      minHeight: "40px",
                      maxHeight: "40px",
                      borderColor: "var(--inputBorderColor)",
                    },
                  }}
                />
              </FormControl>
            </div>
            <div className="receiver-address-details" style={{ display: "flex", gap: "var(--gap)" }}>
              <TextField
                value={cityUpdated && idUpdated === item?.id ? cityUpdated : city}
                fullWidth
                onChange={(e) => setCity(e.target.value)}
                size="small"
                label="Province / Eyalet"
                required
                error={cityError}
                helperText={cityError ? "Bu alan gereklidir." : ""}
              />
              <TextField
                value={townUpdated && idUpdated === item?.id ? townUpdated : town}
                fullWidth
                onChange={(e) => setTown(e.target.value)}
                size="small"
                label="Kent"
                required
                error={townError}
                helperText={townError ? "Bu alan gereklidir." : ""}
              />
              <TextField
                value={stateUpdated && idUpdated === item?.id ? stateUpdated : state}
                fullWidth
                onChange={(e) => setState(e.target.value)}
                size="small"
                label="İlçe"
                required
                error={stateError}
                helperText={stateError ? "Bu alan gereklidir." : ""}
              />
              <TextField
                value={stateCodeUpdated && idUpdated === item?.id ? stateCodeUpdated : stateCode}
                fullWidth
                onChange={(e) => setStateCode(e.target.value)}
                size="small"
                label="Eyalet Kodu"
                required
                error={stateCodeError}
                helperText={stateCodeError ? "Bu alan gereklidir." : ""}
              />
            </div>
          </div>
          {
            getDisabledOn() && (
              <div>
                <p style={{ display: "flex", gap: "var(--gap)", color: "red", marginTop: "10px", alignItems: "center" }}>
                  <InfoOutlinedIcon /> Posta kodu konusunda yardıma ihtiyacınız var ise lütfen bizleri 0850 532 4994 numaralı telefonumuzdan arayınız.
                </p>
              </div>
            )
          }

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "var(--gap)",
            }}
          >
            <Button type="button" className="iyzi-button-blue" onClick={handleCloseReceiver}>
              İptal
            </Button>
            <Button sx={{
              ":disabled": {
                opacity: "0.5"
              }
            }} disabled={isInvalid} onClick={updateAddress} type="submit" className="iyzi-button-blue">
              Kaydet
            </Button>
            <Warring isOpen={showModal} onClose={handleCloseWarning} />
          </div>
        </Box>
      </Modal>
      {/* Alıcı */}
      <FastShipmentPriceModal
        items={item}
        status={fastShipmentPriceModalStatus}
        setStatus={setFastShipmentPriceModalStatus}
        setSelectedPriceObject={setSelectedPriceObject}
        setPriceID={setPriceID}
        setPriceIDValue={setPriceIDValue}
        dataID={dataID}
        setInsuranceFast={setInsuranceFast}
        setDdpFast={setDdpFast}
        setLucidFast={setLucidFast}
      />
      <PackageListModal
        selectedCountryForIoss={selectedCountryForIoss}
        itemID={item}
        handleCheckPrice={handleCheckPrice}
        openPackageList={openPackageList}
        setOpenPackageList={setOpenPackageList}
        setSelectedPriceObject={setSelectedPriceObject}
        setOpenPriceUpdateConfirmation={setOpenPriceUpdateConfirmation}
        setOpenPriceUpdate={setOpenPriceUpdate}
        setUpdatedCommoduty={setUpdatedCommoduty}
        setPriceID={setPriceID}
        setPriceIDValue={setPriceIDValue}
        setProforma={setProforma}
        priceIsDisabled={priceIsDisabled}
      />
      <DeleteFastDialog
        open={deleteDialog}
        setOpen={setDeleteDialog}
        handleDelete={handleDeleteFastShipment}
      />
      <HSCodeMissingFastDialog
        open={hSCodeMissingDialog}
        setOpen={setHSCodeMissingDialog}
        handleSubmit={handleCreateShipment}
        setDialog={setOpenPackageList}
        commoduties={item?.get_commoduties}
      />
      <TableRow sx={{
      }}>
        <TableCell sx={{
          fontSize: "13px",
          textAlign: "center"
        }}>
          <Tooltip title={priceID ? "" : "Ürünü seçmek için önce fiyat seçmelisiniz."}>
            <span>
              <Checkbox
                disabled={!item?.isPriceTake || item?.isPriceTake !== "1" || item?.parcelRefNo || !item?.price_id}
                checked={isChecked || (allChecked && !!priceID)}
                onChange={handleChecked}
              />
            </span>
          </Tooltip>
        </TableCell>
        <TableCell sx={{
          fontSize: "13px",
          textAlign: "left"
        }}>
          <figure>
            <img
              style={{ height: "30px" }}
              loading="lazy"
              src={`/images/integrations/${item?.platform === "ETSY" || item?.platform === "etsy_gb"
                ? "etsy_fast"
                : item?.platform?.toLowerCase()
                }.svg`}
              alt={item?.platform}
            />
          </figure>
        </TableCell>
        <TableCell sx={{
          fontSize: "13px",
          textAlign: "left"
        }}>{item.shop_name}</TableCell>
        <TableCell sx={{
          fontSize: "13px",
          textAlign: "left"
        }}>{item.receipt_id}</TableCell>
        <TableCell sx={{
          fontSize: "13px",
          textAlign: "left"
        }}>{FormatDateTR(item?.receipt_date)}</TableCell>
        <TableCell sx={{
          fontSize: "13px",
          textAlign: "left"
        }}>
          <Tooltip title={country}>
            <div>
              <CircleFlag countryCode={!item?.country ? selectedCountryUpdated?.toLowerCase() : item?.country?.toLowerCase()} height="24" />
            </div>
          </Tooltip>
        </TableCell>
        <TableCell sx={{
          fontSize: "13px",
          textAlign: "left"
        }}>{item?.contact_name}</TableCell>
        <TableCell sx={{
          fontSize: "13px",
          textAlign: "left"
        }}>
          {item?.get_parcel?.parcel_ref_no && item?.get_parcel?.qr_created !== "1" && (
            <Button style={{
              color: "var(--blueBrand)",
              textTransform: "capitalize",
              textDecoration: "underline",
              "&:hover": {
                cursor: "pointer"
              }
            }} onClick={handleQrNavigate}>
              Etiket Sayfasına Git
            </Button>
          )}
          {item?.additionalText && item?.get_parcel?.qr_created !== "1" && (
            <Button style={{
              color: "var(--blueBrand)",
              textTransform: "capitalize",
              textDecoration: "underline",
              "&:hover": {
                cursor: "pointer"
              }
            }} onClick={handleQrNavigate}>
              Etiket Sayfasına Git
            </Button>
          )}

          {!item?.get_parcel?.parcel_ref_no && item?.deleted === "0" && !item?.additionalText && "Bekleniyor"}

          {item?.get_parcel?.qr_created === "1" && "Oluşturuldu"}

          {item?.deleted === "1" && !item?.get_parcel?.parcel_ref_no && "Silindi"}
        </TableCell>
        <TableCell sx={{
          fontSize: "13px",
          textAlign: "left"
        }}>{item?.parcelRefNo ? item?.parcelRefNo : item?.get_parcel?.parcel_ref_no}</TableCell>
        <TableCell sx={{
          padding: 0
        }}>
          {item?.get_parcel && (
            <Box sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center"
            }}>
              <Tooltip title={item?.get_parcel?.get_parcel_supplier.supplier_name}>
                <div style={{ display: "flex", alignItems: "right" }}>
                  <img
                    style={{ height: "30px", width: "60px" }}
                    loading="lazy"
                    src={`/images/${item?.get_parcel?.get_parcel_supplier.supplier_logo}`}
                    alt={item?.get_parcel?.get_parcel_supplier.supplier_name}
                  />
                </div>
              </Tooltip>
              <span style={{ color: "var(--btnBgBlue", fontSize: "16px" }}>
                {LocalPrice(priceCreated())}{" "}
                <span style={{ fontSize: "12px" }}>
                  {Currency(item?.get_parcel?.get_parcel_supplier?.currency)}
                </span>
              </span>{" "}
              <span style={{ fontSize: "12px" }}>
                ({LocalPrice(priceCreated("TRY"))}{" "}
                <span style={{ fontSize: "10px" }}>{Currency("TRY")}</span>)
              </span>
            </Box>
          )}
          {selectedPriceObject?.supplier_name?.length > 0 && (
            <Box sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center"
            }}>
              <Tooltip title={selectedPriceObject?.supplier_name}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    style={{ height: "30px", width: "30px" }}
                    loading="lazy"
                    src={`/images/${selectedPriceObject?.supplier_logo}`}
                    alt={selectedPriceObject?.supplier_name} />
                </div>
              </Tooltip><span style={{ color: "var(--btnBgBlue", fontSize: "16px" }}>
                {LocalPrice(price())}{" "}
                <span style={{ fontSize: "12px" }}>
                  {Currency(selectedPriceObject?.currency)}
                </span>
              </span>{" "}
              <span style={{ fontSize: "12px" }}>
                ({LocalPrice(price("TRY"))}{" "}
                <span style={{ fontSize: "10px" }}>{Currency("TRY")}</span>)
              </span>
            </Box>
          )}
        </TableCell>
        <TableCell sx={{
          display: "flex",
          paddingLeft: "0",
          gap: "5px",
          marginBottom: "-1px"
        }}>
          <div style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
          }}>
            <Button
              sx={{
                width: "120px",
                height: "30px",
                fontSize: "12px",
                ":disabled": {
                  opacity: "0.5"
                }
              }}
              disabled={!!item?.get_parcel?.parcel_ref_no || item?.deleted === "1" || item?.priceStatus === 1}
              className={
                item?.get_dimensions.length > 0 ||
                  fasterDimension.length > 0 ||
                  priceBtnCheck
                  ? "iyzi-button-greenBold fast-address"
                  : "iyzi-button-red fast-address"
              }
              onClick={() => setOpenFastDimension(true)}
            >
              Ölçü Girişi
            </Button>
            <Button
              sx={{
                width: "120px",
                height: "30px",
                fontSize: "12px",
                ":disabled": {
                  opacity: "0.5"
                }
              }}
              disabled={!!item?.get_parcel?.parcel_ref_no || item?.deleted === "1" || item?.priceStatus === 1}
              className={
                updatedSenderAddress || item?.get_parcel?.parcel_ref_no || item?.deleted === "1" || item?.priceStatus === 1
                  ? "iyzi-button-greenBold fast-address"
                  : "iyzi-button-yellow fast-address"
              }
              onClick={() => setOpenSenderAddress(true)}
            >
              Gönderici Adresi
            </Button>
            {
              !timer && (
                <Button
                  sx={{
                    width: "120px",
                    height: "30px",
                    fontSize: "12px",
                    ":disabled": {
                      opacity: "0.5"
                    }
                  }}
                  disabled={item?.get_parcel?.parcel_ref_no || item?.deleted === "1" || item?.priceStatus === 1}
                  onClick={handleEditReceiverAddressModal}
                  className="iyzi-button-blue fast-address"
                >
                  Alıcı Adresi
                </Button>
              )
            }
            {
              timer && (
                <Button
                  sx={{
                    width: "120px",
                    height: "30px",
                    fontSize: "12px",
                    ":disabled": {
                      opacity: "0.5"
                    }
                  }}
                  disabled={item?.get_parcel?.parcel_ref_no || item?.deleted === "1" || item?.priceStatus === 1}
                  onClick={handleEditReceiverAddressModal}
                  className={className}
                >
                  Alıcı Adresi
                </Button>
              )
            }
            <Tooltip title={item?.get_commoduties[0]?.parcel_type === "3" && item?.get_integration_parcel_documents.length <= 0 ? "Proformada fatura eklenmemiştir" : ""}>
              <span>
                <Button
                  sx={{
                    width: "120px",
                    height: "30px",
                    fontSize: "12px",
                    backgroundColor: item?.get_commoduties[0]?.parcel_type === "3" && item?.get_integration_parcel_documents.length <= 0 ? "red !important" : "",
                    borderColor: item?.get_commoduties[0]?.parcel_type === "3" && item?.get_integration_parcel_documents.length <= 0 ? "red !important" : "",
                    ":disabled": {
                      opacity: "0.5"
                    }
                  }}
                  disabled={item?.get_parcel?.parcel_ref_no || item?.deleted === "1" || item?.priceStatus === 1}
                  className={proforma || item?.updated_commoduty === "1" ? "iyzi-button-greenBold fast-address" : "iyzi-button-yellow fast-address"}
                  onClick={handleShowProduct}
                >
                  {item?.get_commoduties[0]?.parcel_type === "3" ? "ETGB Proforma" : "Proforma"}
                </Button>
              </span>
            </Tooltip>
          </div>
          <div style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px"
          }}>
            {dimensionChangeLoading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "70px",
                }}
              >
                <CircularProgress sx={{ width: "25px!important", height: "25px!important" }} />
              </div>
            ) : (
              <Tooltip
                title={
                  !fastDimension || item?.get_dimensions.length <= 0 ? fasterDimension.length <= 0 : item?.get_dimensions.length <= 0 || !item?.get_commoduties[0]?.parcel_type || !city || !town || !stateCode || !state || item?.priceStatus === "1" || item?.get_parcel?.parcel_ref_no ? "Fiyat seçebilmeniz için ölçü, alıcı adresi ve proformadaki eksik yerleri tamamlamanız gerekmektedir. Oluşturulan ölçüler için değiştirme yapılamamaktadır." : ""
                }
              >
                {
                  priceIsDisabled || openPriceUpdate ? (
                    <span style={{ width: "100%" }}>
                      <Button
                        disabled={
                          !addressID || isInvalid || item?.deleted === "1" ||
                          !item?.get_commoduties[0]?.parcel_type ||
                          item?.priceStatus === "1" ||
                          item?.get_parcel?.parcel_ref_no ||
                          !fastDimension ||
                          (item?.get_dimensions.length <= 0 ? fasterDimension.length <= 0 : false) ||
                          getDisabledOn()
                        }
                        onClick={handleCheckPrice}
                        sx={{
                          ":disabled": { opacity: 0.3 }, width: "120px",
                          height: "30px",
                          fontSize: "12px",
                        }}
                        className="iyzi-button-blue fast-price"
                        type="button"
                      >
                        Fiyat Al
                      </Button>

                    </span>
                  ) : (
                    <span style={{ width: "100%" }}>
                      <Button
                        disabled={priceIsDisabled || item?.priceStatus === 1}
                        onClick={handleShowPrices}
                        sx={{
                          ":disabled": { opacity: 0.3 }, width: "120px",
                          height: "30px",
                          fontSize: "12px",
                        }}
                        className="iyzi-button-blue fast-price"
                        type="button"
                      >
                        {priceID === null || !item?.isPriceTake === "1" ? "Fiyat Seç" : "Fiyatı Değiştir"}
                      </Button>
                    </span>
                  )
                }


              </Tooltip>
            )}

            {deleteLoading ? (
              <div
                style={{
                  width: "37px",
                  height: "37px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress sx={{ width: "20px!important", height: "20px!important" }} />
              </div>
            ) : (
              <IconButton
                sx={{
                  width: "120px",
                  borderRadius: "5px",
                  height: "30px",
                  fontSize: "12px",
                  fontWeight: "500",
                  backgroundColor: "var(--pastelRed)",
                  color: "var(--shipmentRed)",
                  transition: "all 150ms linear",
                  ":hover": {
                    backgroundColor: "var(--notRed)",
                    color: "var(--pastelRed)",
                    transition: "all 150ms linear",
                  },
                }}
                onClick={handleDeleteIntegerShipment}
                disabled={item?.get_parcel?.parcel_ref_no ? true : false || item?.deleted === "1" || item?.priceStatus === 1}
              >
                Gönderiyi Sil
              </IconButton>
            )}
            {
              item?.deleted === "1" ? (
                <Button
                  className="iyzi-button-blue shipment-send"
                  onClick={handleRecoverDeleted}
                  sx={{
                    ":disabled": { opacity: 0.3 }, width: "120px", height: "30px",
                    fontSize: "12px"
                  }}
                >
                  Geri Getir
                </Button>
              ) : loading ? (
                <div
                  style={{
                    width: "37px",
                    height: "37px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center"
                  }}
                >
                  <CircularProgress sx={{ width: "18px!important", height: "18px!important" }} />
                </div>
              ) : (
                <Button
                  className="iyzi-button-blue shipment-send"
                  onClick={handleFastSubmit}
                  disabled={!item?.isPriceTake === "1" || item?.get_parcel?.parcel_ref_no || item?.get_parcel === null || item?.priceStatus || !item?.price_id}
                  sx={{
                    ":disabled": { opacity: 0.3 }, width: "120px", height: "30px",
                    fontSize: "12px"
                  }}
                >
                  Gönderi Oluştur
                </Button>
              )
            }
          </div>
        </TableCell>
      </TableRow >
    </>
  );
};

export default FastShipmentListItem;
