import { useCallback, useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  checkedFastCustomsState,
  checkedFastInsurancesState,
  checkedFastLucidsState,
  fastPricesState,
  priceIDValueState,
  fastListState
} from "../../../atoms/fastShipment";
import SupplierServicesType from "../../../utils/SupplierServicesType";
import Currency from "../../../utils/CurrencySymbol";
import { Button, Checkbox, Divider, FormControlLabel, Tooltip } from "@mui/material";
import LocalPrice from "../../../utils/LocalPrice";
import { insuranceSlideWarningState } from "../../../atoms/createShipment";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DisplayOverCharges from "../../createShipment/shipmentPrice/DisplayOverCharges";

const FastShipmentPrices = ({
  item,
  items,
  setSelectedPriceObject,
  handleClose,
  dataID,
  setPriceID,
  setPriceIDValue,
  setInsuranceFast,
  setDdpFast,
  setLucidFast,
}) => {
  const [price, setPrice] = useState(null);
  const [checkedFastCustoms, setCheckedFastCustoms] = useRecoilState(checkedFastCustomsState);
  const [fastList, setFastList] = useRecoilState(fastListState);
  const [checkedFastInsurances, setCheckedFastInsurances] = useRecoilState(
    checkedFastInsurancesState
  );
  const [checkedFastLucids, setCheckedFastLucids] = useRecoilState(checkedFastLucidsState);
  const [fastPrices, setFastPrices] = useRecoilState(fastPricesState);

  const setInsuranceSlideWarning = useSetRecoilState(insuranceSlideWarningState);

  const handleInsuranceOnChange = useCallback(() => {
    if (checkedFastInsurances?.price_id === item?.price_id) {
      setCheckedFastInsurances({});
      setInsuranceFast(0);
      setPrice(parseFloat(price) - parseFloat(item?.insurance?.insurance_price));
    } else {
      setInsuranceSlideWarning({ status: true, data: item });
      setCheckedFastInsurances(item);
      setInsuranceFast(1);
      setPrice(parseFloat(price) + parseFloat(item?.insurance?.insurance_price));
    }
  }, [
    setInsuranceSlideWarning,
    setCheckedFastInsurances,
    checkedFastInsurances,
    setInsuranceFast,
    item,
    setPrice,
    price,
  ]);

  const handleCustomsOnChange = useCallback(() => {
    if (checkedFastCustoms?.price_id === item?.price_id) {
      setCheckedFastCustoms({});
      setDdpFast(0);
      setPrice(parseFloat(price) - parseFloat(item?.ddp?.ex_custom_price));
    } else {
      setCheckedFastCustoms(item);
      setDdpFast(1);
      setPrice(parseFloat(price) + parseFloat(item?.ddp?.ex_custom_price));
    }
  }, [checkedFastCustoms, setCheckedFastCustoms, setDdpFast, item, price, setPrice]);

  const handleLucidOnChange = useCallback(() => {
    if (checkedFastLucids?.price_id === item?.price_id) {
      setCheckedFastLucids({});
      setLucidFast(0);
      setPrice(parseFloat(price) - parseFloat(item?.lucid_price_detail?.lucid_price));
    } else {
      setCheckedFastLucids(item);
      setLucidFast(1);
      setPrice(parseFloat(price) + parseFloat(item?.lucid_price_detail?.lucid_price));
    }
  }, [checkedFastLucids, price, item, setLucidFast, setCheckedFastLucids]);

  const handleChangePrice = useCallback(() => {
    const fastPrice = fastPrices.find((e) => e.id === dataID);
    setPriceID(item?.price_id);
    setPriceIDValue(item?.price_id);
    setFastList((prevList) => {
      const updatedList = prevList.map((fast) => {
        if (fast.id === dataID) {
          return {
            ...fast,
            price_id: item?.price_id,
          };
        }
        return fast;
      });
      return updatedList;
    });
    setSelectedPriceObject(item);
    if (fastPrice) {
      setFastPrices([
        ...fastPrices?.filter((e) => e.id !== dataID),
        {
          id: dataID,
          obj: item,
        },
      ]);

    } else {
      setFastPrices([
        ...fastPrices,
        {
          id: dataID,
          obj: item,
        },
      ]);
    }
    handleClose();
  }, [item, fastPrices, setFastPrices, setSelectedPriceObject, handleClose, setPriceID, dataID]);

  useEffect(() => {
    setPrice(() => {
      let initialPrice = parseFloat(item?.selling_price);

      if (checkedFastCustoms.price_id === item?.price_id) {
        initialPrice += parseFloat(item?.ddp?.ex_custom_price);
      }
      if (checkedFastInsurances.price_id === item?.price_id) {
        initialPrice += parseFloat(item?.insurance?.insurance_price);
      }

      return initialPrice;
    });
  }, [checkedFastInsurances, checkedFastCustoms, item, setPrice]);

  const isEmptyValue = (value) => {
    if (Array.isArray(value)) {
      return value.length === 0; // Eğer bir dizi ise ve uzunluğu 0 ise boş kabul ediyoruz.
    } else if (typeof value === "object") {
      return Object.keys(value).length === 0; // Eğer bir obje ise ve anahtar sayısı 0 ise boş kabul ediyoruz.
    }
    return true; // Diğer durumlar için boş kabul ediyoruz.
  };

  const nonEmptyEntries = Object.entries(item?.extra_information).filter(
    ([key, value]) => !isEmptyValue(value)
  );

  const nonEmptyObject = Object.fromEntries(nonEmptyEntries);

  const isEmpty = Number(item.extra_information.total_over_charge_price) === 0;

  return (
    <div className="get-card">
      <div className="get-card-header">
        <div className="get-card-header-headline">
          {item?.supplier_note_tr && (
            <Tooltip title={item?.supplier_note_tr}>
              <InfoOutlinedIcon sx={{ color: "var(--info)" }} className="logo-warning" />
            </Tooltip>
          )}
          <img
            src={`/images/${item?.supplier_logo}`}
            alt={`${item?.supplier_name} Logo Görseli`}
            style={{
              marginLeft: item?.supplier_note_tr ? 0 : "31px",
              height: "80px",
              width: "80px"
            }}
          />
          {SupplierServicesType(item?.service_type)}
          {item.supplier_logo === "347.svg" && (
            <span>(ETGB'siz)</span>
          )}
          {item.supplier_id === 336 && (
            <span>(ETGB'li)</span>
          )}
        </div>
        <div className="get-card-header-subtext">
          Teslimat Süresi:&nbsp;<span>{item?.transit}</span>&nbsp;iş günü
        </div>
      </div>
      <Divider />
      <div className="get-card-main">
        <div className="get-card-main-price-wrapper">
          <div className="get-card-main-price">
            <span className="main-price-out">
              {Currency(item?.currency)}
              {LocalPrice(price)}
            </span>
            <span className="main-price-in">
              &nbsp;({LocalPrice(Number(price) * Number(item?.exchange_rate))}
              &nbsp;₺)
            </span>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            İade Masrafı: {Currency(item?.currency)}
            {LocalPrice(item.return_price_detail.return_price)}
            {item.return_price_detail.return_price_txt?.length !== 0 && (
              <Tooltip title={item.return_price_detail.return_price_txt}>
                <InfoOutlinedIcon
                  sx={{
                    width: "21px!important",
                    height: "21px!important",
                    color: "var(--btnBgBlue)",
                  }}
                />
              </Tooltip>
            )}
          </div>
          {!isEmpty && (
            <DisplayOverCharges item={item} data={nonEmptyObject} currency={item?.currency} />
          )}
        </div>
        <div className="shipmentPrice-get-insurance">
          {Number(item?.lucid_price_detail.lucid) === 1 && (
            <FormControlLabel
              label={`${Currency(item?.lucid_price_detail.currency)}${LocalPrice(
                item?.lucid_price_detail.lucid_price
              )} - Lucid`}
              control={
                <Checkbox
                  checked={checkedFastLucids.price_id === item?.price_id}
                  onChange={handleLucidOnChange}
                />
              }
            />
          )}
          {Number(item?.insurance.insurance_available) === 1 && (
            <FormControlLabel
              label={`${Currency(item?.currency)}${LocalPrice(
                item?.insurance.insurance_price
              )} - Sigorta`}
              control={
                <Checkbox
                  checked={checkedFastInsurances.price_id === item?.price_id}
                  onChange={handleInsuranceOnChange}
                />
              }
            />
          )}
          {Number(item?.ddp.ddp) === 1 && (
            <FormControlLabel
              label={`${Currency(item?.ddp.currency)}${LocalPrice(
                item?.ddp.ex_custom_price
              )} - Yurtdışı Gümrükleme`}
              control={
                <Checkbox
                  checked={checkedFastCustoms.price_id === item?.price_id}
                  onChange={handleCustomsOnChange}
                />
              }
            />
          )}
        </div>
      </div>
      <Tooltip
        title={
          items?.get_commoduties[0]?.parcel_type === "3" &&
            item.supplier_logo === "347.svg"
            ? "Seçebilmek için proformadaki Gönderi Açıklamasının, Normal Gönderi veya Numune ya da Hediye şeklinde seçilmesi gerekmektedir."
            : items?.get_commoduties[0]?.parcel_type !== "3" &&
              item.supplier_id === 336
              ? "Seçebilmek için proformadaki Gönderi Açıklamasının, Mikro İhracat (ETGB'li) E-ihracat şeklinde seçilmesi gerekmektedir."
              : ""
        }
        arrow // Optional: adds an arrow to the tooltip
      >
        <span>
          <Button
            sx={{
              '&:disabled': {
                opacity: 0.2,
                cursor: 'auto',
              },
              width:"100%"
            }}
            disabled={
              (items?.get_commoduties[0]?.parcel_type === "3" &&
                item.supplier_logo === "347.svg") ||
              (items?.get_commoduties[0]?.parcel_type !== "3" &&
                item.supplier_id === 336)
            }
            className="iyzi-button-blue"
            onClick={handleChangePrice}
          >
            Fiyat Seç
          </Button>
        </span>
      </Tooltip>
    </div>
  );
};

export default FastShipmentPrices;
