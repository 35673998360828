import { useCallback, useState } from "react";
import { Button, Checkbox, Divider, FormControlLabel, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CurrencySymbol from "../../../utils/CurrencySymbol";
import {
  checkedLucidsState,
  isShipmentPriceCalculationState,
  lucidState,
} from "../../../atoms/createShipment";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  activeStepState,
  checkedCustomsState,
  checkedInsurancesState,
  ddpState,
  insuranceSlideWarningState,
  insuranceState,
  priceIdState,
  supplierIdState,
} from "../../../atoms/createShipment";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import LocalPrice from "../../../utils/LocalPrice";
import DisplayOverCharges from "./DisplayOverCharges";
import SupplierServicesType from "../../../utils/SupplierServicesType";

const ShipmentPriceGetCardItem = ({ item }) => {
  const isShipmentPriceCalculation = useRecoilValue(isShipmentPriceCalculationState);

  const [priceId, setPriceId] = useRecoilState(priceIdState);
  const [checkedInsurances, setCheckedInsurances] = useRecoilState(checkedInsurancesState);
  const [checkedCustoms, setCheckedCustoms] = useRecoilState(checkedCustomsState);
  const [checkedLucids, setCheckedLucids] = useRecoilState(checkedLucidsState);

  const setActiveStep = useSetRecoilState(activeStepState);
  const setInsurance = useSetRecoilState(insuranceState);
  const setDdp = useSetRecoilState(ddpState);
  const setLucid = useSetRecoilState(lucidState);
  const setInsuranceSlideWarning = useSetRecoilState(insuranceSlideWarningState);
  const setSupplierId = useSetRecoilState(supplierIdState);

  const [price, setPrice] = useState(() => {
    let initialPrice = parseFloat(item.selling_price);

    if (item.supplier_id === 339 || checkedCustoms.price_id === item.price_id) {
      initialPrice += parseFloat(item.ddp.ex_custom_price);
    }
    if (checkedInsurances.price_id === item.price_id) {
      initialPrice += parseFloat(item.insurance.insurance_price);
    }

    return initialPrice;
  });

  const handleInsuranceOnChange = useCallback(() => {
    if (checkedInsurances.price_id === item.price_id) {
      setCheckedInsurances({});
      setInsurance(0);
      setPrice(parseFloat(price) - parseFloat(item.insurance.insurance_price));
    } else {
      setInsuranceSlideWarning({ status: true, data: item });
      setCheckedInsurances(item);
      setInsurance(1);
      setPrice(parseFloat(price) + parseFloat(item.insurance.insurance_price));
    }
  }, [
    setInsuranceSlideWarning,
    setCheckedInsurances,
    checkedInsurances,
    setInsurance,
    item,
    setPrice,
    price,
  ]);

  const handleCustomsOnChange = useCallback(() => {
    if (item.supplier_id === 339) {
      return;
    } else {
      if (checkedCustoms.price_id === item.price_id) {
        setCheckedCustoms({});
        setDdp(0);
        setPrice(parseFloat(price) - parseFloat(item.ddp.ex_custom_price));
      } else {
        setCheckedCustoms(item);
        setDdp(1);
        setPrice(parseFloat(price) + parseFloat(item.ddp.ex_custom_price));
      }
    }
  }, [checkedCustoms, setCheckedCustoms, setDdp, item, price]);

  const handleLucidOnChange = useCallback(() => {
    if (checkedLucids.price_id === item.price_id) {
      setCheckedLucids({});
      setLucid(0);
      setPrice(parseFloat(price) - parseFloat(item.lucid_price_detail.lucid_price));
    } else {
      setCheckedLucids(item);
      setLucid(1);
      setPrice(parseFloat(price) + parseFloat(item.lucid_price_detail.lucid_price));
    }
  }, [checkedLucids, price, item, setLucid, setCheckedLucids]);

  const choosePriceHandler = useCallback(() => {
    setPriceId(item.price_id);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSupplierId(item.supplier_id);
    if (item.price_id === checkedInsurances.price_id) {
      setInsurance(1);
    } else if (item.price_id !== checkedInsurances.price_id) {
      setInsurance(0);
      setCheckedInsurances({});
    }

    if (item.price_id === checkedCustoms.price_id) {
      setDdp(1);
    } else if (item.price_id !== checkedCustoms.price_id) {
      setDdp(0);
      setCheckedCustoms({});
    }
  }, [
    setCheckedCustoms,
    setCheckedInsurances,
    checkedCustoms,
    checkedInsurances,
    setInsurance,
    setDdp,
    setSupplierId,
    item,
    setActiveStep,
    setPriceId,
  ]);

  const isEmptyValue = (value) => {
    if (Array.isArray(value)) {
      return value.length === 0; // Eğer bir dizi ise ve uzunluğu 0 ise boş kabul ediyoruz.
    } else if (typeof value === "object") {
      return Object.keys(value).length === 0; // Eğer bir obje ise ve anahtar sayısı 0 ise boş kabul ediyoruz.
    }
    return true; // Diğer durumlar için boş kabul ediyoruz.
  };

  const nonEmptyEntries = Object.entries(item?.extra_information).filter(
    ([key, value]) => !isEmptyValue(value)
  );

  const nonEmptyObject = Object.fromEntries(nonEmptyEntries);

  const isEmpty = Number(item.extra_information.total_over_charge_price) === 0;

  return (
    <div className={priceId === item.price_id ? "get-card-wrapper selected" : "get-card-wrapper"}>
      <div className="get-card">
        <div className="get-card-header">
          <div className="get-card-header-headline">
            {item.supplier_note_tr && (
              <Tooltip title={item.supplier_note_tr}>
                <InfoOutlinedIcon sx={{ color: "var(--info)" }} className="logo-warning" />
              </Tooltip>
            )}
            <img
              src={`/images/${item.supplier_logo}`}
              alt={`${item.supplier_name} Logo Görseli`}
              style={{
                marginLeft: item.supplier_note_tr ? 0 : "31px",
                height: "80px", width: "80px"
              }}
            />
            {SupplierServicesType(item.service_type)}
            {item.supplier_logo === "347.svg" && (
              <span>(ETGB'siz)</span>
            )}
            {item.supplier_id === 336 && (
              <span>(ETGB'li)</span>
            )}
          </div>
          <div className="get-card-header-subtext">
            Teslimat Süresi:&nbsp;<span>{item.transit}</span>&nbsp;iş günü
          </div>
        </div>
        <Divider />
        <div className="get-card-main">
          <div className="get-card-main-price-wrapper">
            <div className="get-card-main-price">
              <span className="main-price-out">
                {CurrencySymbol(item.currency)}
                {LocalPrice(price)}
              </span>
              <span className="main-price-in">
                &nbsp;({LocalPrice(Number(price) * Number(item.exchange_rate))}
                &nbsp;₺)
              </span>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              İade Masrafı: {CurrencySymbol(item.currency)}
              {LocalPrice(item.return_price_detail.return_price)}
              {item.return_price_detail.return_price_txt?.length !== 0 && (
                <Tooltip title={item.return_price_detail.return_price_txt}>
                  <InfoOutlinedIcon
                    sx={{
                      width: "21px!important",
                      height: "21px!important",
                      color: "var(--btnBgBlue)",
                    }}
                  />
                </Tooltip>
              )}
            </div>
          </div>
          {!isShipmentPriceCalculation && (
            <div className="shipmentPrice-get-insurance">
              {Number(item.insurance.insurance_available) === 1 && (
                <FormControlLabel
                  label={`${CurrencySymbol(item.currency)}${LocalPrice(
                    item.insurance.insurance_price
                  )} - Sigorta`}
                  control={
                    <Checkbox
                      checked={checkedInsurances.price_id === item.price_id}
                      onChange={handleInsuranceOnChange}
                    />
                  }
                />
              )}
              {Number(item?.lucid_price_detail?.lucid) === 1 && (
                <FormControlLabel
                  label={`${CurrencySymbol(item?.lucid_price_detail?.currency)}${LocalPrice(
                    item?.lucid_price_detail?.lucid_price
                  )} - Lucid`}
                  control={
                    <Checkbox
                      checked={checkedLucids.price_id === item.price_id}
                      onChange={handleLucidOnChange}
                    />
                  }
                />
              )}
              {Number(item.ddp.ddp) !== 0 && (
                <FormControlLabel
                  label={`${CurrencySymbol(item.ddp.currency)}${LocalPrice(
                    item.ddp.ex_custom_price
                  )} - Yurtdışı Gümrükleme`}
                  control={
                    <Checkbox
                      checked={
                        item.supplier_id === 339 || checkedCustoms.price_id === item.price_id
                      }
                      onChange={handleCustomsOnChange}
                      disabled={item.supplier_id === 339}
                    />
                  }
                />
              )}
            </div>
          )}
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "var(--gap)" }}>
        {<Divider sx={{ height: "1px", width: "100%", marginBottom: "var(--gap)" }} />}
        {!isEmpty && (
          <DisplayOverCharges item={item} data={nonEmptyObject} currency={item?.currency} />
        )}
        {!isShipmentPriceCalculation && (
          <Button
            sx={{ ":disabled": { opacity: 0.4 } }}
            className={priceId === item.price_id ? "iyzi-button" : "iyzi-button-blue"}
            onClick={choosePriceHandler}
          >
            {priceId === item.price_id ? (
              <div
                style={{
                  color: "inherit",
                  fontSize: "inherit",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                }}
              >
                <CheckCircleOutlinedIcon /> Seçildi
              </div>
            ) : (
              "Seç"
            )}
          </Button>
        )}
      </div>
      {(item.supplier_id === "337" || item.supplier_id === "338") && (
        <div className="supplier-id-warning">
          <InfoOutlinedIcon className="logo-warning" />
          Bu tedarikçi servisinde iade seçeneği bulunmamaktadır. Adres eksikliği sebebi ile veya
          alıcının adreste bulunmaması durumunda teslim edilemeyen gönderileriniz için sistem
          otomatik olarak imha süreci başlatır.
        </div>
      )}
    </div>
  );
};

export default ShipmentPriceGetCardItem;
