import {
  Box,
  createTheme,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Menu,
  MenuItem,
  Pagination,
  Select,
  ThemeProvider,
  Button,
  Checkbox,
} from "@mui/material";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import axios from "../../api/axios";
import {
  awbState,
  basicSearchState,
  detailedStatusState,
  exportSelectedDesktopState,
  myShipmentListLoadingState,
  myShipmentListState,
  myShipmentsListLimitState,
  receiverCountryCodeState,
  receiverState,
  refIdState,
  selectedExportStatusState,
  selectedDomesticStatusState,
  selectedCaseStatusState,
  selectedMyShipmentListItemState,
  selectedPriceStatusState,
  selectedSupplierState,
  senderCountryCodeState,
  senderState,
  shipmentListCurrentPageState,
  shipmentListOrderDirectionState,
  shipmentStatusState,
  timeState,
  totalPagesState,
  listStyleState,
  confirmationModalOpenState,
} from "../../atoms/myShipments";
import MyShipmentsListItem from "./MyShipmentsListItem";
import miniLogo from "../../img/logos/logo_white_mini_loading.svg";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { errorModalState } from "../../atoms/error";
import { basketItemsState, basketTotalItemsState } from "../../atoms/basket";
import Errors from "../../utils/Errors";

const theme = createTheme({
  components: {
    MuiSelect: {
      styleOverrides: {
        select: {
          display: "flex",
        },
      },
    },
  },
});

const MyShipmentsList = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleExportMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleExportMenuClose = () => {
    setAnchorEl(null);
  };

  const shipmentOrderDirectionPage = useRecoilValue(shipmentListOrderDirectionState);

  const [myShipmentListLoading, setMyShipmentListLoading] = useRecoilState(
    myShipmentListLoadingState
  );
  const [myShipmentList, setMyShipmentList] = useRecoilState(myShipmentListState);
  const [selectedMyShipmentListItem, setSelectedMyShipmentListItem] = useRecoilState(
    selectedMyShipmentListItemState
  );
  const [myShipmentsListLimit, setMyShipmentsListLimit] = useRecoilState(myShipmentsListLimitState);
  const [shipmentListCurrentPage, setShipmentListCurrentPage] = useRecoilState(
    shipmentListCurrentPageState
  );
  const setConfirmationModalOpen = useSetRecoilState(confirmationModalOpenState);
  const setExportSelectedDesktop = useSetRecoilState(exportSelectedDesktopState);
  const [totalPages, setTotalPages] = useRecoilState(totalPagesState);
  const [listStyle, setListStyle] = useRecoilState(listStyleState);
  const setErrorModal = useSetRecoilState(errorModalState);

  const setBasketItems = useSetRecoilState(basketItemsState);
  const setBasketTotalItems = useSetRecoilState(basketTotalItemsState);

  const time = useRecoilValue(timeState);
  const senderCountryCode = useRecoilValue(senderCountryCodeState);
  const receiverCountryCode = useRecoilValue(receiverCountryCodeState);
  const selectedSupplier = useRecoilValue(selectedSupplierState);
  const selectedPriceStatus = useRecoilValue(selectedPriceStatusState);
  const selectedExportStatus = useRecoilValue(selectedExportStatusState);
  const selectedDomesticStatus = useRecoilValue(selectedDomesticStatusState);
  const selectedCaseStatus = useRecoilValue(selectedCaseStatusState);
  const awb = useRecoilValue(awbState);
  const refId = useRecoilValue(refIdState);
  const sender = useRecoilValue(senderState);
  const receiver = useRecoilValue(receiverState);
  const shipmentStatus = useRecoilValue(shipmentStatusState);
  const detailedStatus = useRecoilValue(detailedStatusState);
  const basicSearch = useRecoilValue(basicSearchState);

  const isSelectDisabled = selectedMyShipmentListItem.length === 0;

  const handlePageChange = async (e, pageValue) => {
    setShipmentListCurrentPage(pageValue);
    setMyShipmentListLoading(true);

    const params =
      Number(detailedStatus) === 1
        ? {
            order_direction: shipmentOrderDirectionPage,
            limit: myShipmentsListLimit,
            page: pageValue,
            tracking_awb: awb,
            parcel_ref_no: refId,
            supplier_id: selectedSupplier !== "" ? Number(selectedSupplier) : selectedSupplier,
            status: shipmentStatus,
            tracking_awb_date: time,
            sender_name: sender,
            receiver_name: receiver,
            sender_country: senderCountryCode,
            receiver_country: receiverCountryCode,
            parcel_type: selectedExportStatus,
            paid: selectedPriceStatus,
            is_domestic: selectedDomesticStatus,
            is_case: selectedCaseStatus,
            detailed: Number(detailedStatus),
          }
        : {
            order_direction: shipmentOrderDirectionPage,
            limit: myShipmentsListLimit,
            page: pageValue,
            search: basicSearch,
          };

    await axios
      .get(`parcel`, { params: params })
      .then((response) => {
        setMyShipmentList(response.data.data);
        setTotalPages(response.data.totalPages);
      })
      .catch((error) => Errors(error))
      .finally(() => setMyShipmentListLoading(false));
  };

  const handleLimitChange = useCallback(
    async (value) => {
      setMyShipmentListLoading(true);
      setMyShipmentsListLimit(value);
      if (value !== 10) {
        setShipmentListCurrentPage(1);
      }

      const params =
        Number(detailedStatus) === 1
          ? {
              order_direction: shipmentOrderDirectionPage,
              limit: value,
              tracking_awb: awb,
              parcel_ref_no: refId,
              supplier_id: selectedSupplier !== "" ? Number(selectedSupplier) : selectedSupplier,
              status: shipmentStatus,
              tracking_awb_date: time,
              sender_name: sender,
              receiver_name: receiver,
              sender_country: senderCountryCode,
              receiver_country: receiverCountryCode,
              parcel_type: selectedExportStatus,
              paid: selectedPriceStatus,
              is_domestic: selectedDomesticStatus,
              is_case: selectedCaseStatus,
              detailed: Number(detailedStatus),
              page: 1,
            }
          : {
              order_direction: shipmentOrderDirectionPage,
              limit: value,
              page: 1,
              search: basicSearch,
            };

      await axios
        .get(`parcel`, { params: params })
        .then((response) => {
          setMyShipmentList(response.data.data);
          setTotalPages(response.data.totalPages);
        })
        .catch((error) => Errors(error))
        .finally(() => setMyShipmentListLoading(false));
    },
    [
      awb,
      basicSearch,
      detailedStatus,
      receiver,
      receiverCountryCode,
      selectedExportStatus,
      selectedDomesticStatus,
      selectedCaseStatus,
      refId,
      selectedPriceStatus,
      selectedSupplier,
      sender,
      senderCountryCode,
      setMyShipmentList,
      setMyShipmentListLoading,
      setMyShipmentsListLimit,
      shipmentOrderDirectionPage,
      setTotalPages,
      shipmentStatus,
      time,
      setShipmentListCurrentPage,
    ]
  );

  const handleAllCheck = useCallback(() => {
    if (selectedMyShipmentListItem.length === 0) {
      setSelectedMyShipmentListItem(myShipmentList);
    } else {
      if (selectedMyShipmentListItem.length !== myShipmentList.length) {
        setSelectedMyShipmentListItem(myShipmentList);
      } else {
        setSelectedMyShipmentListItem([]);
      }
    }
  }, [selectedMyShipmentListItem, myShipmentList, setSelectedMyShipmentListItem]);

  const payedUnpayedText =
    "Seçili gönderileriniz arasında ödemesi yapılmış gönderi veya gönderiler bulunmakta. Ödemesi yapılmayan gönderiler ise sepete eklendi.";
  const payedText = "Seçili gönderilerin ödemesi yapılmış. Bu sebeple sepete eklenmedi.";
  const unpayedText = "Seçili gönderileriniz sepete başarıyla eklendi.";
  const alreadyAllInBasket = "Seçili gönderi veya gönderiler sepette bulunmaktadır.";
  const alreadyInBasketAndPayed =
    "Seçili gönderilerden bazıları ödenmiş, diğerleri ise halihazırda sepette bulunmaktadır.";
  const alreadyInBasketAndUnpayedAndPayed =
    "Seçili gönderilerden bazıları ödenmiş, bazıları halihazırda sepette bulunmaktadır, diğerleri ise başarıyla sepete eklendi.";

  const handleAddBasket = () => {
    axios
      .post(`basket/add`, {
        parcel_ids: selectedMyShipmentListItem.map((e) => e.id),
      })
      .then((response) => {
        const failedCount = response.data.data?.failed_items_count;
        const failedItems = response.data.data?.failed_items;
        const successCount = response.data.data?.success_items_count;

        const isAllAlreadyInBasket = failedItems.every(
          (e) => e?.failed_reason === "Parcel Sepetinizde Bulunmaktadır."
        );

        const isAlreadyInBasket = failedItems.some(
          (e) => e?.failed_reason === "Parcel Sepetinizde Bulunmaktadır."
        );

        const isPayedItem = failedItems.some(
          (e) => e?.failed_reason === "Parcel Zaten Ödenmiş. Tekrar Sepetinize Ekleyemezsiniz."
        );

        if (response.data.status === 200) {
          if (failedCount > 0 && successCount === 0) {
            if (isAllAlreadyInBasket) {
              toast.info(alreadyAllInBasket);
            } else if (isAlreadyInBasket && isPayedItem) {
              toast.info(alreadyInBasketAndPayed);
            } else {
              toast.info(payedText);
            }
          } else if (failedCount > 0 && successCount > 0) {
            if (isPayedItem && !isAlreadyInBasket) {
              toast.success(payedUnpayedText);
            } else {
              toast.success(alreadyInBasketAndUnpayedAndPayed);
            }
          } else if (failedCount === 0 && successCount > 0) {
            toast.success(unpayedText);
          }
        } else if (response.data.status === 400) {
          toast.error(response.data.message);
        }

        if (successCount > 0) {
          axios.get("basket").then((response) => {
            setBasketItems(response.data.data);
            setBasketTotalItems(response.data.totalItems);
          });
        }
      })
      .catch((error) => Errors(error));
  };

  const handleExport = useCallback(
    (value) => {
      setExportSelectedDesktop(value);
      handleExportMenuClose();
    },
    [setExportSelectedDesktop]
  );

  const handleConfirmationModalOpen = () => {
    setConfirmationModalOpen(true);
  };

  const bulkRequests = (e) => {
    if (!isSelectDisabled) {
      if (e === 1) {
        handleAddBasket();
      } else if (e === 3) {
        handleConfirmationModalOpen();
      } else if (e === 4) {
        handleExportMenu();
      }
    } else
      return setErrorModal({
        status: true,
        page: "myShipments",
        text: "Seçili gönderi bulunmamaktadır. İşlem yapmak istiyorsanız gönderi seçiniz.",
      });
  };

  return (
    <ThemeProvider theme={theme}>
      {myShipmentListLoading ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "500px",
          }}
        >
          <img className="loading-logo" src={miniLogo} alt="" />
        </div>
      ) : myShipmentList.length === 0 ? (
        <Box className="myShipments-list-none">Gönderi bulunamadı.</Box>
      ) : (
        <Box className="myShipments-list">
          <Box className="myShipments-list-selectors">
            <Box className="list-selectors-position start">
              <FormGroup
                sx={{
                  backgroundColor: "var(--pastelBlue)",
                  padding: "0 10px",
                  color: "var(--blueBrand)",
                  borderRadius: "var(--wrapperBorderRadius)",
                  width: "180px",
                }}
              >
                <FormControlLabel
                  className="selector-switch-all"
                  control={
                    <Checkbox
                      checked={selectedMyShipmentListItem.length === myShipmentList.length}
                      onChange={handleAllCheck}
                    />
                  }
                  label={`Tümünü Seç(${selectedMyShipmentListItem.length})`}
                />
              </FormGroup>
              <FormControl className="selector-limit" size="small" sx={{ minWidth: 100 }}>
                <InputLabel id="limit-label">Gönderi Sayısı</InputLabel>
                <Select
                  labelId="limit-label"
                  id="limit"
                  label="Gönderi Sayısı"
                  value={myShipmentsListLimit}
                  onChange={(e) => handleLimitChange(e.target.value)}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>
              <Box>
                Sayfadaki Gönderi Sayısı: {myShipmentList.length}
              </Box>
            </Box>
            <Box
              sx={{ justifyContent: "flex-end !important" }}
              className="list-selectors-position-wrapper"
            >
              <Box className="list-selectors-position middle">
                <FormControl size="small" sx={{ minWidth: 135 }}>
                  <InputLabel id="batch-processing-select-label">Toplu İşlemler</InputLabel>
                  <Select
                    labelId="batch-processing-select-label"
                    id="batch-processing-select"
                    label="Toplu İşle"
                  >
                    <MenuItem onClick={() => bulkRequests(1)}>Seçilenleri Sepete Ekle</MenuItem>
                    <MenuItem onClick={() => bulkRequests(3)}>
                      Seçilenlerin Barkodunu Oluştur
                    </MenuItem>
                    <MenuItem
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={() => bulkRequests(4)}
                    >
                      Seçilenleri Dışarı Aktar
                    </MenuItem>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleExportMenuClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem onClick={() => handleExport(0)}>Excel</MenuItem>
                      <MenuItem onClick={() => handleExport(1)}>PDF</MenuItem>
                    </Menu>
                  </Select>
                </FormControl>
              </Box>
              <Box className="list-selectors-position end">
                <Pagination
                  showFirstButton
                  showLastButton
                  size="small"
                  count={totalPages}
                  page={shipmentListCurrentPage}
                  onChange={handlePageChange}
                />
              </Box>
            </Box>
          </Box>
          <Button onClick={() => setListStyle(!listStyle)} className="iyzi-button">
            {listStyle ? <UnfoldMoreIcon /> : <UnfoldLessIcon />}
            {listStyle ? "Detaylı Görünüme Geç" : "Liste Görünümüne Geç"}
          </Button>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: listStyle ? "var(--gap)" : "var(--gap2x)",
            }}
          >
            {myShipmentList.map((shipment, key) => (
              <MyShipmentsListItem key={key} shipment={shipment} />
            ))}
          </div>
          <Box
            sx={{
              marginBottom: "20px",
              backgroundColor: "var(--lighterBg)",
              borderRadius: "var(--inputBorderRadius)",
              width: "100%",
              padding: "20px",
              boxShadow: "0px 2px 10px rgb(0 0 0 / 10%)",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Pagination
              showFirstButton
              showLastButton
              size="small"
              count={totalPages}
              page={shipmentListCurrentPage}
              onChange={handlePageChange}
            />
          </Box>
        </Box>
      )}
    </ThemeProvider>
  );
};

export default MyShipmentsList;
