import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  Tooltip,
} from "@mui/material";
import ListItemLinks from "./ListItemLinks";
import FlightTakeoffOutlinedIcon from "@mui/icons-material/FlightTakeoffOutlined";
import FlightLandOutlinedIcon from "@mui/icons-material/FlightLandOutlined";
import { CircleFlag } from "react-circle-flags";
import LiveHelpOutlinedIcon from "@mui/icons-material/LiveHelpOutlined";
import shipmentLogo_0 from "../../img/myShipments/gonderilerim_0.svg";
import shipmentLogo_1 from "../../img/myShipments/gonderilerim_1.svg";
import shipmentLogo_2 from "../../img/myShipments/gonderilerim_2.svg";
import shipmentLogo_4 from "../../img/myShipments/gonderilerim_4.svg";
import shipmentLogo_5 from "../../img/myShipments/gonderilerim_5.svg";
import Currency from "../../utils/CurrencySymbol";
import countriesDetailed from "../../json/countriesDetailed.json";
import { useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  listStyleState,
  selectedMyShipmentListItemState,
  selectedPaymentShipmentState,
  shipmentDetailModalState,
  shipmentDimensionDetailState,
  shipmentPaymentOpenState,
  openInfoModalState
} from "../../atoms/myShipments";
import MessageTime from "../../utils/MessageTime";
import FormatDateDots from "../../utils/FormatDateDots";
import { Link, NavLink, useNavigate } from "react-router-dom";
import LocalPrice from "../../utils/LocalPrice";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { toast } from "react-toastify";
import axios from "../../api/axios";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ListItemLinksMobil from "./options/ListItemLinksMobil";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import FormatHeadlineText from "../../utils/FormatHeadlineText";
import ParcelType from "../../utils/ParcelType";
import Errors from "../../utils/Errors";

const MyShipmentListItem = ({ shipment }) => {
  const navigate = useNavigate();

  // Disabled Payment Button
  const member = JSON.parse(localStorage.getItem("user"));
  const memberStatus = member?.user?.user_member?.status;
  const isButtonDisabled = Number(memberStatus) !== 2;

  const paidPrice = shipment?.get_parcel_price_informations
    ?.filter((e) => Number(e.paid) === Number(shipment.paid))
    .reduce((acc, curr) => acc + (Number(curr.paid_price) || 0), 0);

  const [selectedMyShipmentListItem, setSelectedMyShipmentListItem] =
    useRecoilState(selectedMyShipmentListItemState);

  const [openInfoModal, setOpenInfoModal] =
    useRecoilState(selectedMyShipmentListItemState);

  const setShipmentPaymentOpen = useSetRecoilState(shipmentPaymentOpenState);
  const setSelectedPaymentShipment = useSetRecoilState(
    selectedPaymentShipmentState
  );
  const setShipmentDetailModal = useSetRecoilState(shipmentDetailModalState);

  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
  const [exchangeRate, setExchangeRate] = useState(null);
  const [detailLoading, setDetailLoading] = useState(false);
  const [options, setOptions] = useState(null);
  const [expand, setExpand] = useState(false);
  const [loading, setLoading] = useState(false);

  const getCurrency = useCallback(async () => {
    if (isButtonDisabled) {
      navigate("/profile-settings/general");
    } else {
      setLoading(true);
      await axios
        .post("exchange-rate/get-current-rate", {
          currency: shipment.currency,
        })
        .then((response) => {
          setExchangeRate(response.data.data);
          setPaymentDialogOpen(true);
        })
        .catch((error) => Errors(error))
        .finally(() => setLoading(false));
    }
  }, [shipment, isButtonDisabled, navigate]);

  const handleExpand = () => {
    setExpand(!expand);
  };

  const listStyle = useRecoilValue(listStyleState);

  const handleOptions = (e) => {
    setOptions(e.currentTarget);
  };

  const handlePayment = async () => {
    setShipmentPaymentOpen(true);
    setSelectedPaymentShipment(shipment);
    setPaymentDialogOpen(false);
  };

  const handleSwitchChange = useCallback(() => {
    setSelectedMyShipmentListItem(
      selectedMyShipmentListItem.find((e) => e.id === shipment.id)
        ? selectedMyShipmentListItem.filter((e) => e.id !== shipment.id)
        : [...selectedMyShipmentListItem, shipment]
    );
  }, [setSelectedMyShipmentListItem, selectedMyShipmentListItem, shipment]);

  /* Copy Ref Number */

  const [copied, setCopied] = useState(false);

  const handleClick = async (e) => {
    try {
      await navigator.clipboard.writeText(e);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Kopyalama Hatası: ", err);
    }
  };

  const handlePaymentDialogClose = () => {
    setPaymentDialogOpen(false);
  };

  const handlePaymentDialogOpen = () => {
    if (isButtonDisabled) {
      navigate("/profile-settings/general");
    } else {
      getCurrency();
    }
  };

  // Ölçü Detaylarında Modal Açılması

  const [, setShipmentDimensionDetail] = useRecoilState(
    shipmentDimensionDetailState
  );

  const handleShipmentListItemDimension = useCallback(() => {
    setShipmentDimensionDetail({ status: true, data: shipment });
  }, [setShipmentDimensionDetail, shipment]);

  // Gönderi takip modal açılması ve gelen veri

  const handleShipmentDetail = useCallback(async () => {
    setDetailLoading(true);
    await axios
      .get(`parcel/detail-parcel/${shipment.id}`)
      .then((response) => {
        if (response.data.data.length === 0) {
          toast.info(response.data.message);
        } else {
          setShipmentDetailModal({
            status: true,
            data: response.data.data,
            shipment: shipment,
          });
        }
      })
      .catch((error) => Errors(error))
      .finally(() => setDetailLoading(false));
  }, [shipment, setShipmentDetailModal]);

  // Gönderi takip buton renklendirmesi

  const trackingShipmentButtonColor =
    Number(shipment.paid) === 0 || Number(shipment.status) === 8
      ? "iyzi-button-red"
      : "iyzi-button-green";

  useEffect(() => {
    if (!listStyle || listStyle) {
      setExpand(true);
    }
  }, [listStyle]);

  // Shipment status
  // const result =
  //   shipment.status === "8.00"
  //     ? "Tutar İade Edildi"
  //     : shipment.status === "0.05" || shipment.status === "4.06"
  //     ? "İptal Edildi - Para İadesi Yapıldı"
  //     : "Ödendi";

  const getClassName = (status) => {
    return `steps-info-done-button ${Number(status) === 0.05 ? "cancel" : "paid"
      }`;
  };

  const getPaidClassName = (status) => {
    return `steps-info-done-price ${Number(status) === 0.05 ? "refund" : "paid"
      }`;
  };

  const getPaidListClassName = (status) => {
    return `steps-info-done ${Number(status) === 0.05 ? "refund" : "paid"}`;
  };

  const getRefundColorClassName = (status) => {
    return `steps-info-done-price ${Number(status) === 0.05 ? "refund-price" : "paid-price"
      }`;
  };

  return listStyle ? (
    <Box sx={{ display: "flex", gap: "var(--gap2x)" }}>
      <Box
        sx={{
          padding: "10px",
          borderRadius: "var(--inputBorderRadius)",
          boxShadow: "var(--shadow)",
          width: "100%",
          display: "flex",
          gap: "var(--gap)",
          alignItems: "center",
          backgroundColor:
            Number(shipment.status) === 8 ||
              Number(shipment.status) === 13 ||
              Number(shipment.status) === 14 ||
              Number(shipment.status) === 15 ||
              Number(shipment.status) === 16 ||
              Number(shipment.status) === 17 ||
              Number(shipment.status) === 18 ||
              Number(shipment.status) === 19 ||
              Number(shipment.status) === 20 ||
              Number(shipment.status) === 36 ||
              shipment.status === "0.05"
              ? "#fdfd965c !important"
              : "var(--lighterBg) !important",
        }}
        className="wrapper-list-item"
      >
        <Button
          className="iyzi-button"
          onClick={handleExpand}
          sx={{
            height: "30px",
            width: "30px",
            minWidth: "30px",
            borderRadius: "50% !important",
            cursor: "pointer",
          }}
        >
          <ExpandMoreIcon
            sx={{ transform: expand ? "rotate(0)" : "rotate(180deg)" }}
          />
        </Button>
        {expand ? (
          <>
            <div className="list-item-checkbox">
              <Checkbox
                checked={
                  !!selectedMyShipmentListItem.find(
                    (item) => item.id === shipment.id
                  )
                }
                onChange={handleSwitchChange}
                sx={{ padding: 0 }}
              />
            </div>
            <div className="listStyle-list-item">
              <Dialog
                open={paymentDialogOpen}
                onClose={handlePaymentDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Ödeme yapacağınız miktar{" "}
                    <span
                      style={{
                        fontWeight: 700,
                        fontSize: "inherit",
                        color: "inherit",
                      }}
                    >
                      {LocalPrice(
                        Number(shipment?.remaining_payment) * exchangeRate
                      )}{" "}
                      ₺
                    </span>
                    . Devam etmek istiyor musunuz?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    className="iyzi-button"
                    onClick={handlePaymentDialogClose}
                  >
                    Hayır
                  </Button>
                  <Button
                    className="iyzi-button"
                    onClick={handlePayment}
                    autoFocus
                  >
                    Evet
                  </Button>
                </DialogActions>
              </Dialog>
              <div className="list-item-supp-message-countries">
                <div className="list-item-supp-message">
                  <div className="list-item-supplier">
                    <Tooltip
                      title={shipment.get_parcel_supplier?.supplier_name}
                    >
                      <img
                        className="step-shipment-logo-css"
                        src={`/images/${shipment.get_parcel_supplier?.supplier_logo}`}
                        style={{
                          width: "60px",
                          height: "62px"
                        }}
                      />
                    </Tooltip>
                  </div>
                  <div className="list-item-message">
                    <span
                      style={{
                        color: "var(--btnBgBlue)",
                        fontWeight: 600,
                        fontSize: "inherit",
                      }}
                    >
                      {shipment.doc_type === "D" ? (
                        <Tooltip title="Döküman">
                          <SummarizeOutlinedIcon />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Paket">
                          <Inventory2OutlinedIcon />
                        </Tooltip>
                      )}
                    </span>
                    {FormatDateDots(shipment.created_at)}
                    <span
                      style={{
                        fontWeight: 400,
                        fontSize: "inherit",
                      }}
                      className="list-steps-parcel-ref-no"
                    >
                      <span
                        style={{
                          color: "var(--btnBgBlue)",
                          fontWeight: 500,
                          fontSize: "inherit",
                        }}
                      >
                        {shipment.parcel_ref_no}
                      </span>
                    </span>
                  </div>
                </div>
                <div className="list-item-countries">
                  <Tooltip
                    title={countriesDetailed
                      .find((e) => e.iso_code === shipment.sender_country)
                      ?.country_name_tr?.toUpperCase()}
                  >
                    <span>
                      <CircleFlag
                        countryCode={countriesDetailed
                          .find((e) => e.iso_code === shipment.sender_country)
                          ?.iso_code?.toLowerCase()}
                        height="24"
                      />
                    </span>
                  </Tooltip>
                  <KeyboardDoubleArrowRightIcon />
                  <Tooltip
                    title={countriesDetailed
                      .find((e) => e.iso_code === shipment.receiver_country)
                      ?.country_name_tr?.toUpperCase()}
                  >
                    <span>
                      <CircleFlag
                        countryCode={countriesDetailed
                          .find((e) => e.iso_code === shipment.receiver_country)
                          ?.iso_code?.toLowerCase()}
                        height="24"
                      />
                    </span>
                  </Tooltip>
                </div>
              </div>
              <div className="list-item-buttons">
                <div className="list-item-buttons-wrapper">
                  <div
                    style={{
                      width: "60px",
                      color: "var(--btnBgBlue)",
                      textAlign: "center",
                    }}
                  >
                    {shipment?.receiver_name}
                  </div>
                  <Button
                    sx={{
                      width: "98px",
                      height: "35px",
                      backgroundColor:
                        shipment?.status === "0.05"
                          ? "#fdfd965c !important"
                          : "inherit",
                    }}
                    onClick={handleShipmentDetail}
                    className={trackingShipmentButtonColor}
                  >
                    {detailLoading ? (
                      <CircularProgress
                        sx={{
                          width: "21px!important",
                          height: "21px!important",
                        }}
                      />
                    ) : (
                      "Gönderi Takip"
                    )}
                  </Button>
                </div>
                {isButtonDisabled ? (
                  <NavLink
                    className="list-item-buttons-navlink"
                    to={"/profile-settings/general"}
                  >
                    Ödeme Yapmak İçin Profili Tamamla
                  </NavLink>
                ) : Number(shipment.paid) === 0 ? (
                  <Box id="integer_done" className="steps-info-done">
                    <span className="steps-info-done-price">
                      {Currency(shipment.currency)}
                      {LocalPrice(shipment.remaining_payment)}
                    </span>
                    {loading ? (
                      <CircularProgress
                        sx={{
                          width: "21px!important",
                          height: "21px!important",
                        }}
                      />
                    ) : (
                      <Button
                        sx={{
                          width: "180px",
                        }}
                        onClick={handlePaymentDialogOpen}
                        className="steps-info-done-button"
                      >
                        {shipment?.paid_name}
                      </Button>
                    )}
                  </Box>
                ) : Number(shipment.paid) === 2 ? (
                  <Box
                    id="integer_done"
                    className="steps-info-done"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <span
                        style={{
                          color: "var(--shipmentYellow)",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {Currency(shipment.currency)}
                        {LocalPrice(paidPrice)}
                      </span>
                      <Button
                        sx={{
                          backgroundColor:
                            Number(shipment.status) === 0.05
                              ? "var(--shipmentYellow)!important"
                              : "inherit",
                        }}
                        style={{ textAlign: "center" }}
                        className={getClassName(shipment.status)}
                        disabled
                      >
                        {/* {result} */}
                        {shipment?.paid_name}
                      </Button>
                    </div>
                  </Box>
                ) : (
                  <Box
                    id="integer_done"
                    className={getPaidListClassName(shipment.status)}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "var(--gap)",
                        width: "100%",
                      }}
                    >
                      <span
                        style={{ minWidth: "60px" }}
                        className={getRefundColorClassName(shipment.status)}
                      >
                        {Currency(shipment.currency)}
                        {LocalPrice(paidPrice)}
                      </span>
                      {/* <Button
                        style={{ textAlign: "center", width: "191px" }}
                        sx={{
                          backgroundColor:
                            shipment.status === "0.05"
                              ? "var(--shipmentYellow)!important"
                              : "inherit",
                        }}
                        className={getClassName(shipment.status)}
                      >
                        {result}
                      </Button> */}
                      <span
                        style={{
                          fontSize: "12px",
                          fontWeight: 600,
                          textAlign: "center",
                          width: "100%",
                        }}
                      >
                        {/* {result} */}
                        {shipment?.paid_name}
                      </span>
                    </div>
                  </Box>
                )}
              </div>
            </div>
            <Button
              id="basic-menu"
              onClick={handleOptions}
              className="list-items-options"
              sx={{
                minWidth: "33px",
                width: "33px",
                height: "33px",
                borderRadius: "50%",
              }}
            >
              <MoreVertIcon />
            </Button>
            <ListItemLinksMobil
              shipment={shipment}
              options={options}
              setOptions={setOptions}
            />
          </>
        ) : (
          <Box
            className="myShipments-list-wrapper"
            sx={{
              width: "100%",
              backgroundColor:
                Number(shipment.status) === 8 ||
                  Number(shipment.status) === 13 ||
                  Number(shipment.status) === 14 ||
                  Number(shipment.status) === 15 ||
                  Number(shipment.status) === 16 ||
                  Number(shipment.status) === 17 ||
                  Number(shipment.status) === 18 ||
                  Number(shipment.status) === 19 ||
                  Number(shipment.status) === 20 ||
                  Number(shipment.status) === 36 ||
                  shipment.status === "0.05"
                  ? "#fdfd965c !important"
                  : "var(--lighterBg) !important",
              padding: "0 !important",
              boxShadow: "none !important",
              borderRadius: "0 !important",
            }}
          >
            <Dialog
              open={paymentDialogOpen}
              onClose={handlePaymentDialogClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Ödeme yapacağınız miktar{" "}
                  <span
                    style={{
                      fontWeight: 700,
                      fontSize: "inherit",
                      color: "inherit",
                    }}
                  >
                    {LocalPrice(
                      Number(shipment?.remaining_payment) * exchangeRate
                    )}{" "}
                    ₺
                  </span>
                  . Devam etmek istiyor musunuz?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  className="iyzi-button"
                  onClick={handlePaymentDialogClose}
                >
                  Hayır
                </Button>
                <Button
                  className="iyzi-button"
                  onClick={handlePayment}
                  autoFocus
                >
                  Evet
                </Button>
              </DialogActions>
            </Dialog>
            <Box className="list-steps">
              <div className="list-steps-headline">
                <FormGroup>
                  <FormControlLabel
                    className="list-picker"
                    control={
                      <Checkbox
                        checked={
                          !!selectedMyShipmentListItem.find(
                            (item) => item.id === shipment.id
                          )
                        }
                        onChange={handleSwitchChange}
                      />
                    }
                  />
                </FormGroup>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <div style={{ textAlign: "start" }}>
                    <span
                      style={{
                        fontSize: "inherit",
                        color: "var(--btnBgBlue)",
                        fontWeight: 600,
                      }}
                    >
                      Alıcı Adı:{" "}
                    </span>
                    {shipment?.receiver_name}
                  </div>
                  <div>
                    <span
                      style={{
                        color: "var(--btnBgBlue)",
                        fontWeight: 600,
                        fontSize: "inherit",
                      }}
                    >
                      {shipment.doc_type === "N" ? "Paket" : "Döküman"}
                    </span>
                    &nbsp;-&nbsp;{MessageTime(shipment.created_at)}
                    <span
                      style={{
                        fontWeight: 400,
                        fontSize: "inherit",
                      }}
                      className="list-steps-parcel-ref-no"
                    >
                      &nbsp;-&nbsp;
                      <span
                        style={{
                          color: "var(--btnBgBlue)",
                          fontWeight: 500,
                          fontSize: "inherit",
                          textDecoration: "underline",
                        }}
                      >
                        {shipment.parcel_ref_no}
                      </span>
                    </span>
                    &nbsp;-&nbsp;
                    <span
                      style={{
                        color: "var(--btnBgBlue)",
                        fontWeight: 500,
                        fontSize: "inherit",
                      }}
                    >
                      {ParcelType(shipment.parcel_type)}
                    </span>
                  </div>
                </div>
              </div>
              <Box
                sx={{
                  backgroundColor: shipment?.tracking_awb
                    ? "inherit"
                    : shipment?.status === "0.05"
                      ? "#fdfd965c !important"
                      : "var(--lighterBg) !important",
                  width: "150px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  borderRadius: "var(--inputBorderRadius)!important",
                  boxShadow: shipment.tracking_awb
                    ? "var(--shadow)"
                    : "inherit",
                }}
                className="to-tracking"
              >
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: 700,
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  {shipment.tracking_awb ? "Takip No" : ""}
                </div>
                <div
                  style={{
                    color: "inherit",
                    fontSize: "inherit",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {shipment.tracking_awb}
                  {shipment.tracking_awb && (
                    <Tooltip
                      title={copied ? "Kopyalandı!" : "Kopyalamak için tıkla"}
                    >
                      <IconButton
                        onClick={() => handleClick(shipment.tracking_awb)}
                        onMouseLeave={() => setCopied(false)}
                      >
                        <ContentCopyOutlinedIcon
                          sx={{ width: "16px", height: "16px" }}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              </Box>
              <Box className="steps-where-to">
                <Box className="to-integration">
                  {shipment?.get_integration_parcel?.platform && (
                    <>
                      <Tooltip
                        title={shipment?.get_integration_parcel?.platform}
                      >
                        <img
                          className="to-integration-platform"
                          src={`images/integrations/${shipment?.get_integration_parcel?.platform ===
                            "ETSY"
                            ? "etsy_fast"
                            : shipment?.get_integration_parcel?.platform?.toLowerCase()
                            }.svg`}
                          alt={shipment?.get_integration_parcel?.platform}
                        />
                      </Tooltip>
                      <span
                        style={{
                          fontSize: "10px",
                          fontWeight: 700,
                          color: "var(--text)",
                        }}
                      >
                        {shipment?.get_integration_parcel?.receipt_id}
                      </span>
                    </>
                  )}
                </Box>
                <Box className="to-plane">
                  {countriesDetailed.find(
                    (e) => e.iso_code === shipment.sender_country
                  ).country_name_tr === "Turkey"
                    ? "Türkiye"
                    : countriesDetailed.find(
                      (e) => e.iso_code === shipment.sender_country
                    )?.country_name_tr}
                  <div className="plane-flag">
                    <Tooltip
                      title={
                        countriesDetailed.find(
                          (e) => e.iso_code === shipment.sender_country
                        )?.country_name_tr
                      }
                    >
                      <span>
                        <CircleFlag
                          countryCode={countriesDetailed
                            .find((e) => e.iso_code === shipment.sender_country)
                            ?.iso_code?.toLowerCase()}
                          height="24"
                        />
                      </span>
                    </Tooltip>
                    <FlightTakeoffOutlinedIcon id="take-off" />
                  </div>
                </Box>

                <Box className="to-plane">
                  {
                    countriesDetailed.find(
                      (e) => e.iso_code === shipment.receiver_country
                    )?.country_name_tr
                  }
                  <div className="plane-flag">
                    <FlightLandOutlinedIcon id="land" />
                    <Tooltip
                      title={
                        countriesDetailed.find(
                          (e) => e.iso_code === shipment.receiver_country
                        )?.country_name_tr
                      }
                    >
                      <span>
                        <CircleFlag
                          countryCode={countriesDetailed
                            .find(
                              (e) => e.iso_code === shipment.receiver_country
                            )
                            ?.iso_code?.toLowerCase()}
                          height="24"
                        />
                      </span>
                    </Tooltip>
                  </div>
                </Box>
              </Box>
              <Box className="stepper-wrapper-css">
                <Tooltip
                  title={
                    Number(shipment.status_stage) === 0 && shipment.status_name
                  }
                >
                  <div className="step-wrapper-css">
                    <div
                      className="step-css"
                      style={
                        Number(shipment.status) === 8
                          ? { backgroundColor: "var(--shipmentRed)" }
                          : Number(shipment.status_stage) === 0
                            ? { backgroundColor: "var(--shipmentYellow)" }
                            : { backgroundColor: "var(--shipmentGreen)" }
                      }
                    >
                      <img
                        src={shipmentLogo_0}
                        className="step-logo-css"
                        alt="Gönderi 1.Aşama Görseli"
                      />
                    </div>
                  </div>
                </Tooltip>
                <Tooltip
                  title={
                    Number(shipment.status_stage) === 1 && shipment.status_name
                  }
                >
                  <div className="step-wrapper-css">
                    <div
                      className="step-css"
                      style={
                        Number(shipment.status) === 8
                          ? { backgroundColor: "var(--shipmentRed)" }
                          : Number(shipment.status_stage) === 1
                            ? { backgroundColor: "var(--shipmentYellow)" }
                            : Number(shipment.status_stage) < 1
                              ? { backgroundColor: "var(--shipmentLight)" }
                              : { backgroundColor: "var(--shipmentGreen)" }
                      }
                    >
                      <img
                        src={shipmentLogo_1}
                        className="step-logo-css"
                        alt="Gönderi 2.Aşama Görseli"
                      />
                    </div>
                  </div>
                </Tooltip>
                <Tooltip
                  title={
                    Number(shipment.status_stage) === 2 && shipment.status_name
                  }
                >
                  <div className="step-wrapper-css">
                    <div
                      className="step-css"
                      style={
                        Number(shipment.status) === 8
                          ? { backgroundColor: "var(--shipmentRed)" }
                          : Number(shipment.status_stage) === 2
                            ? { backgroundColor: "var(--shipmentYellow)" }
                            : Number(shipment.status_stage) < 2
                              ? { backgroundColor: "var(--shipmentLight)" }
                              : { backgroundColor: "var(--shipmentGreen)" }
                      }
                    >
                      <img
                        src={shipmentLogo_2}
                        className="step-logo-css"
                        alt="Gönderi 3.Aşama Görseli"
                      />
                    </div>
                  </div>
                </Tooltip>
                <Tooltip
                  title={
                    Number(shipment.status_stage) === 3 && shipment.status_name
                  }
                >
                  <div className="step-wrapper-css">
                    <div>
                      <img
                        className="step-shipment-logo-css"
                        src={`/images/${shipment.get_parcel_supplier?.supplier_logo}`}
                        style={{
                          width: "60px",
                          height: "62px"
                        }}
                      />
                    </div>
                  </div>
                </Tooltip>
                <Tooltip
                  title={
                    Number(shipment.status_stage) === 4 && shipment.status_name
                  }
                >
                  <div className="step-wrapper-css">
                    <div
                      className="step-css"
                      style={
                        Number(shipment.status) === 8
                          ? { backgroundColor: "var(--shipmentRed)" }
                          : Number(shipment.status_stage) === 4
                            ? { backgroundColor: "var(--shipmentYellow)" }
                            : Number(shipment.status_stage) < 4
                              ? { backgroundColor: "var(--shipmentLight)" }
                              : { backgroundColor: "var(--shipmentGreen)" }
                      }
                    >
                      <img
                        src={shipmentLogo_4}
                        className="step-logo-css"
                        alt="Gönderi 5.Aşama Görseli"
                      />
                    </div>
                  </div>
                </Tooltip>
                <Tooltip
                  title={
                    Number(shipment.status_stage) === 5 && shipment.status_name
                  }
                >
                  <div className="step-wrapper-css">
                    <div
                      className="step-css"
                      style={
                        Number(shipment.status) === 8
                          ? { backgroundColor: "var(--shipmentRed)" }
                          : Number(shipment.status_stage) === 5
                            ? { backgroundColor: "var(--shipmentYellow)" }
                            : Number(shipment.status_stage) < 5
                              ? { backgroundColor: "var(--shipmentLight)" }
                              : { backgroundColor: "var(--shipmentGreen)" }
                      }
                    >
                      <img
                        src={shipmentLogo_5}
                        className="step-logo-css"
                        alt="Gönderi 6.Aşama Görseli"
                      />
                    </div>
                  </div>
                </Tooltip>
                {/* <div className="step-connector-css">
                  <div
                    className={shipment.status >= 1 ? "step active" : "step1"}
                  ></div>
                  <div
                    className={shipment.status >= 2 ? "step active" : "step2"}
                  ></div>
                  <div
                    className={shipment.status >= 3 ? "step active" : "step3"}
                  ></div>
                  <div
                    className={shipment.status >= 4 ? "step active" : "step4"}
                  ></div>
                  <div
                    className={shipment.status >= 5 ? "step active" : "step5"}
                  ></div>
                </div> */}
              </Box>
              <Box className="steps-info">
                <Box
                  sx={{
                    padding: "10px",
                    borderRadius: "var(--inputBorderRadius)",
                    ":hover": {
                      boxShadow: "var(--shadow)",
                      cursor: "pointer",
                    },
                  }}
                  onClick={
                    shipment.doc_type === "N"
                      ? handleShipmentListItemDimension
                      : ""
                  }
                  className="steps-info-wrapper info-member"
                >
                  <div className="info-top">
                    Üye Tarafından Beyan Edilen Kilo
                  </div>
                  <div className="info-top-mobil">Üye Ölçü Beyanı</div>
                  {shipment.doc_type === "N" ? (
                    <div className="info-bottom">
                      <div className="info-bottom-subline">
                        NET KG
                        <div className="info-bottom-subline-number">
                          {Number(shipment.gw).toFixed(2)}
                        </div>
                      </div>
                      <div className="info-bottom-subline">
                        DESİ
                        <div className="info-bottom-subline-number">
                          {Number(shipment.vw).toFixed(2)}
                        </div>
                      </div>
                      <div className="info-bottom-subline">
                        NAVLUN KG
                        <div className="info-bottom-subline-number">
                          {Number(shipment.cw).toFixed(2)}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="info-bottom"
                      style={{ justifyContent: "center" }}
                    >
                      <div className="info-bottom-subline">
                        NET KG
                        <div className="info-bottom-subline-number">
                          {Number(shipment?.cw).toFixed(2)}
                        </div>
                      </div>
                    </div>
                  )}
                </Box>
                {Number(shipment.paid) === 0 ? (
                  <Box
                    id="integer_done"
                    className="steps-info-done"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <span className="steps-info-done-price">
                      {Currency(shipment.currency)}
                      {LocalPrice(shipment.remaining_payment)}
                    </span>
                    {loading ? (
                      <CircularProgress
                        sx={{
                          width: "21px!important",
                          height: "21px!important",
                        }}
                      />
                    ) : (
                      <Button
                        onClick={handlePaymentDialogOpen}
                        className="steps-info-done-button"
                      // disabled={isButtonDisabled}
                      >
                        {isButtonDisabled
                          ? "Ödeme Yapmak İçin Profilinizi Tamamlayın!"
                          : shipment?.paid_name}
                      </Button>
                    )}
                    <div
                      style={{
                        color: "var(--priceText)",
                        backgroundColor: "var(--background)",
                        padding: "10px",
                        borderRadius: "var(--wrapperBorderRadius)",
                        boxShadow: "var(--shadow)",
                        fontSize: "12px",
                      }}
                    >
                      Güncel:{" "}
                      <span style={{ fontWeight: 600, fontSize: "inherit" }}>
                        {FormatHeadlineText(shipment.status_name)}
                      </span>
                      <span
                        style={{ fontWeight: 600, fontSize: "inherit" }}
                      ></span>
                    </div>
                  </Box>
                ) : (
                  <Box
                    id="integer_done"
                    className="steps-info-done"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                      }}
                    >
                      <span className="steps-info-done-price paid">
                        {Currency(shipment.currency)}
                        {LocalPrice(paidPrice)}
                      </span>
                      <Button
                        style={{ textAlign: "center" }}
                        className={getClassName(shipment.status)}
                        sx={{
                          backgroundColor:
                            shipment.status === "0.05"
                              ? "var(--shipmentYellow)!important"
                              : "inherit",
                        }}
                      >
                        {/* {result} */}
                        {shipment?.paid_name}
                      </Button>
                    </div>
                    <div
                      style={{
                        color: "var(--priceText)",
                        backgroundColor: "var(--background)",
                        padding: "10px",
                        borderRadius: "var(--wrapperBorderRadius)",
                        boxShadow: "var(--shadow)",
                        fontSize: "12px",
                      }}
                    >
                      Güncel:{" "}
                      <span style={{ fontWeight: 600, fontSize: "inherit" }}>
                        {FormatHeadlineText(shipment.status_name)}
                      </span>
                      <span style={{ fontWeight: 600, fontSize: "inherit" }}>
                        {shipment?.get_parcel_documents.length > 0 && (
                          <div>
                            {shipment.get_parcel_documents
                              .slice(-1)
                              .map((document, index) => {
                                const result =
                                  document.type === "booking_damaged_parcel"
                                    ? "Hasarlı / Teslim Alındı"
                                    : document.type === "booking_cancel_parcel"
                                      ? "Hasarlı / Reddedildi"
                                      : null;

                                if (result) {
                                  return (
                                    <div>
                                      {shipment?.domestic_supplier === "iyziCourier" && (
                                        <div
                                          style={{
                                            fontSize: "12px",
                                            color: "var(--priceText)",
                                            fontWeight: "lighter",
                                          }}
                                          key="additionalStatus"
                                        >
                                          Kurye Kaydı Durumu:{" "}
                                          <span
                                            style={{
                                              fontWeight: 600,
                                              fontSize: "inherit",
                                            }}
                                          >
                                            {result}
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  );
                                }

                                return null;
                              })}
                          </div>
                        )}
                      </span>
                    </div>
                  </Box>
                )}
                <Box
                  sx={{
                    padding: "10px",
                    borderRadius: "var(--inputBorderRadius)",
                    ":hover": {
                      boxShadow: "var(--shadow)",
                      cursor: "pointer",
                    },
                  }}
                  onClick={
                    shipment.doc_type === "N"
                      ? handleShipmentListItemDimension
                      : ""
                  }
                  className="steps-info-wrapper info-system"
                >
                  <div className="info-top">
                    iyziTeam Tarafından Beyan Edilen Kilo
                  </div>
                  <div className="info-top-mobil">iyziTeam Ölçü Beyanı</div>
                  {shipment.doc_type === "N" ? (
                    <div className="info-bottom">
                      <div className="info-bottom-subline">
                        NET KG
                        <div className="info-bottom-subline-number">
                          {Number(shipment?.gw_real).toFixed(2)}
                        </div>
                      </div>
                      <div className="info-bottom-subline">
                        DESİ
                        <div className="info-bottom-subline-number">
                          {Number(shipment?.vw_real).toFixed(2)}
                        </div>
                      </div>
                      <div className="info-bottom-subline">
                        NAVLUN KG
                        <div className="info-bottom-subline-number">
                          {Number(shipment?.cw_real).toFixed(2)}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="info-bottom"
                      style={{ justifyContent: "center" }}
                    >
                      <div className="info-bottom-subline">
                        NET KG
                        <div className="info-bottom-subline-number">
                          {Number(shipment?.cw_real).toFixed(2)}
                        </div>
                      </div>
                    </div>
                  )}
                </Box>
              </Box>
              {Number(shipment.paid) === 0 ? (
                <Box
                  id="seperate_done"
                  className="steps-info-done show"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span className="steps-info-done-price">
                    {Currency(shipment.currency)}
                    {LocalPrice(shipment.remaining_payment)}
                  </span>
                  {loading ? (
                    <CircularProgress
                      sx={{
                        width: "21px!important",
                        height: "21px!important",
                      }}
                    />
                  ) : (
                    <Button
                      onClick={handlePaymentDialogOpen}
                      className="steps-info-done-button"
                    // disabled={isButtonDisabled}
                    >
                      {isButtonDisabled
                        ? "Ödeme Yapmak İçin Profilinizi Tamamlayın!"
                        : shipment?.paid_name}
                    </Button>
                  )}
                  <div
                    style={{
                      color: "var(--priceText)",
                      backgroundColor: "var(--background)",
                      padding: "10px",
                      borderRadius: "var(--wrapperBorderRadius)",
                      boxShadow: "var(--shadow)",
                      fontSize: "12px",
                    }}
                  >
                    Güncel:{" "}
                    <span style={{ fontWeight: 600, fontSize: "inherit" }}>
                      {FormatHeadlineText(shipment.status_name)}
                    </span>
                    <span style={{ fontWeight: 600, fontSize: "inherit" }}>
                      {shipment?.get_parcel_documents.length > 0 && (
                        <div>
                          {shipment.get_parcel_documents
                            .slice(-1)
                            .map((document, index) => {
                              const result =
                                document.type === "booking_damaged_parcel"
                                  ? "Hasarlı / Teslim Alındı"
                                  : document.type === "booking_cancel_parcel"
                                    ? "Hasarlı / Reddedildi"
                                    : null;

                              if (result) {
                                return (
                                  <div>
                                    {shipment?.domestic_supplier === "iyziCourier" && (
                                      <div
                                        style={{
                                          fontSize: "12px",
                                          color: "var(--priceText)",
                                          fontWeight: "lighter",
                                        }}
                                        key="additionalStatus"
                                      >
                                        Kurye Kaydı Durumu:{" "}
                                        <span
                                          style={{
                                            fontWeight: 600,
                                            fontSize: "inherit",
                                          }}
                                        >
                                          {result}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                );
                              }

                              return null;
                            })}
                        </div>
                      )}
                    </span>
                  </div>
                </Box>
              ) : (
                <Box
                  id="seperate_done"
                  className="steps-info-done show"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                    }}
                  >
                    <span className={getPaidClassName(shipment.status)}>
                      {Currency(shipment.currency)}
                      {LocalPrice(paidPrice)}
                    </span>
                    <Button
                      style={{ textAlign: "center" }}
                      className={getClassName(shipment.status)}
                      sx={{
                        backgroundColor:
                          shipment.status === "0.05"
                            ? "var(--shipmentYellow)!important"
                            : "inherit",
                      }}
                    >
                      {/* {result} */}
                      {shipment?.paid_name}
                    </Button>
                  </div>
                  <div
                    style={{
                      color: "var(--priceText)",
                      backgroundColor: "var(--background)",
                      padding: "10px",
                      borderRadius: "var(--wrapperBorderRadius)",
                      boxShadow: "var(--shadow)",
                      fontSize: "12px",
                    }}
                  >
                    Güncel:{" "}
                    <span style={{ fontWeight: 600, fontSize: "inherit" }}>
                      {FormatHeadlineText(shipment.status_name)}
                    </span>
                    <span style={{ fontWeight: 600, fontSize: "inherit" }}>
                      {shipment?.get_parcel_documents.length > 0 && (
                        <div>
                          {shipment.get_parcel_documents
                            .slice(-1)
                            .map((document, index) => {
                              const result =
                                document.type === "booking_damaged_parcel"
                                  ? "Hasarlı / Teslim Alındı"
                                  : document.type === "booking_cancel_parcel"
                                    ? "Hasarlı / Reddedildi"
                                    : null;

                              if (result) {
                                return (
                                  <div>
                                    {shipment?.domestic_supplier === "iyziCourier" && (
                                      <div
                                        style={{
                                          fontSize: "12px",
                                          color: "var(--priceText)",
                                          fontWeight: "lighter",
                                        }}
                                        key="additionalStatus"
                                      >
                                        Kurye Kaydı Durumu:{" "}
                                        <span
                                          style={{
                                            fontWeight: 600,
                                            fontSize: "inherit",
                                          }}
                                        >
                                          {result}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                );
                              }

                              return null;
                            })}
                        </div>
                      )}
                    </span>
                  </div>
                </Box>
              )}
              {shipment.get_parcel_notes.length !== 0 &&
                shipment.get_parcel_notes
                  .filter((e) => e.type === "1")
                  .map((e) => (
                    <Box key={e.id} className="steps-note">
                      <span>NOT :</span> {e.description}
                    </Box>
                  ))}
              <div className="support-desktop-wrapper">
                <Button
                  fullWidth
                  onClick={handleShipmentDetail}
                  className={trackingShipmentButtonColor}
                  sx={{ height: "35px" }}
                >
                  {detailLoading ? (
                    <CircularProgress
                      sx={{
                        width: "21px!important",
                        height: "21px!important",
                        backgroundColor:
                          shipment?.status === "0.05"
                            ? "#fdfd965c !important"
                            : "inherit",
                      }}
                    />
                  ) : (
                    "Gönderi Takip"
                  )}
                </Button>
                <Link
                  className="support-desktop"
                  to={`/support?awb=${shipment.parcel_ref_no}`}
                >
                  <LiveHelpOutlinedIcon />
                  <span>Destek Talebi</span>
                </Link>
              </div>
            </Box>
            <ListItemLinksMobil
              shipment={shipment}
              options={options}
              setOptions={setOptions}
            />
            <Divider
              sx={{
                width: "1px",
                backgroundColor: "var(--phoneDigitBg)",
                margin: "0 10px",
              }}
              className="steps-divider"
            />
            <Box className="list-links-wrapper">
              <div className="list-links-container">
                <h6 style={{ fontSize: "13px", textAlign: "center" }}>
                  {shipment.parcel_ref_no}
                </h6>
                <ListItemLinks shipment={shipment} />
                <Link
                  className="list-links-help"
                  style={{
                    padding: "10px",
                    borderRadius: "var(--wrapperBorderRadius)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textDecoration: "none",
                  }}
                  to={`/support?awb=${shipment.parcel_ref_no}`}
                >
                  <LiveHelpOutlinedIcon />
                  <span>Destek Talebi</span>
                </Link>
              </div>
            </Box>
            <Box className="list-picker-wrapper">
              <Button
                id="basic-menu"
                onClick={handleOptions}
                className="list-options"
                sx={{ minWidth: "33px", borderRadius: "50%" }}
              >
                <MoreVertIcon />
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  ) : (
    <Box
      className="myShipments-list-wrapper"
      sx={{
        backgroundColor:
          Number(shipment.status) === 8 ||
            Number(shipment.status) === 13 ||
            Number(shipment.status) === 14 ||
            Number(shipment.status) === 15 ||
            Number(shipment.status) === 16 ||
            Number(shipment.status) === 17 ||
            Number(shipment.status) === 18 ||
            Number(shipment.status) === 19 ||
            Number(shipment.status) === 20 ||
            Number(shipment.status) === 36 ||
            shipment.status === "0.05"
            ? "#fdfd965c !important"
            : "var(--lighterBg) !important",
      }}
    >
      <Dialog
        open={paymentDialogOpen}
        onClose={handlePaymentDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Ödeme yapacağınız miktar{" "}
            <span
              style={{ fontWeight: 700, fontSize: "inherit", color: "inherit" }}
            >
              {LocalPrice(Number(shipment?.remaining_payment) * exchangeRate)} ₺
            </span>
            . Devam etmek istiyor musunuz?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="iyzi-button" onClick={handlePaymentDialogClose}>
            Hayır
          </Button>
          <Button className="iyzi-button" onClick={handlePayment} autoFocus>
            Evet
          </Button>
        </DialogActions>
      </Dialog>
      <Box className="list-steps">
        <div className="list-steps-headline">
          <FormGroup>
            <FormControlLabel
              className="list-picker"
              control={
                <Checkbox
                  checked={
                    !!selectedMyShipmentListItem.find(
                      (item) => item.id === shipment.id
                    )
                  }
                  onChange={handleSwitchChange}
                />
              }
            />
          </FormGroup>
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <div style={{ textAlign: "start" }}>
              <span
                style={{
                  fontSize: "inherit",
                  color: "var(--btnBgBlue)",
                  fontWeight: 600,
                }}
              >
                Alıcı Adı:{" "}
              </span>
              {shipment?.receiver_name}
            </div>
            <div>
              <span
                style={{
                  color: "var(--btnBgBlue)",
                  fontWeight: 600,
                  fontSize: "inherit",
                }}
              >
                {shipment.doc_type === "N" ? "Paket" : "Döküman"}
              </span>
              &nbsp;-&nbsp;{MessageTime(shipment.created_at)}
              <span
                style={{
                  fontWeight: 400,
                  fontSize: "inherit",
                }}
                className="list-steps-parcel-ref-no"
              >
                &nbsp;-&nbsp;
                <span
                  style={{
                    color: "var(--btnBgBlue)",
                    fontWeight: 500,
                    fontSize: "inherit",
                    textDecoration: "underline",
                  }}
                >
                  {shipment.parcel_ref_no}
                </span>
              </span>
              &nbsp;-&nbsp;
              <span
                style={{
                  color: "var(--btnBgBlue)",
                  fontWeight: 500,
                  fontSize: "inherit",
                }}
              >
                {ParcelType(shipment.parcel_type)}
              </span>
            </div>
          </div>
        </div>
        <Box
          sx={{
            backgroundColor: shipment.tracking_awb
              ? "inherit"
              : shipment?.status === "0.05"
                ? "#fdfd965c !important"
                : "var(--lighterBg) !important",
            width: "150px",
            display: "flex",
            alignItems: "center",
            gap: "5px",
            borderRadius: "var(--inputBorderRadius)!important",
            boxShadow: shipment.tracking_awb ? "var(--shadow)" : "inherit",
          }}
          className="to-tracking"
        >
          <div
            style={{
              fontSize: "14px",
              fontWeight: 700,
              textAlign: "center",
              width: "100%",
            }}
          >
            {shipment.tracking_awb ? "Takip No" : ""}
          </div>
          <div
            style={{
              color: "inherit",
              fontSize: "inherit",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {shipment.tracking_awb}
            {shipment.tracking_awb && (
              <Tooltip title={copied ? "Kopyalandı!" : "Kopyalamak için tıkla"}>
                <IconButton
                  onClick={() => handleClick(shipment.tracking_awb)}
                  onMouseLeave={() => setCopied(false)}
                >
                  <ContentCopyOutlinedIcon
                    sx={{ width: "16px", height: "16px" }}
                  />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </Box>
        <Box className="steps-where-to">
          <Box className="to-integration">
            {shipment?.get_integration_parcel?.platform && (
              <>
                <Tooltip title={shipment?.get_integration_parcel?.platform}>
                  <img
                    className="to-integration-platform"
                    src={`images/integrations/${shipment?.get_integration_parcel?.platform === "ETSY"
                      ? "etsy_fast"
                      : shipment?.get_integration_parcel?.platform?.toLowerCase()
                      }.svg`}
                    alt={shipment?.get_integration_parcel?.platform}
                  />
                </Tooltip>
                <span
                  style={{
                    fontSize: "10px",
                    fontWeight: 700,
                    color: "var(--text)",
                  }}
                >
                  {shipment?.get_integration_parcel?.receipt_id}
                </span>
              </>
            )}
          </Box>
          <Box className="to-plane">
            {countriesDetailed.find(
              (e) => e.iso_code === shipment.sender_country
            ).country_name_tr === "Turkey"
              ? "Türkiye"
              : countriesDetailed.find(
                (e) => e.iso_code === shipment.sender_country
              )?.country_name_tr}
            <div className="plane-flag">
              <Tooltip
                title={
                  countriesDetailed.find(
                    (e) => e.iso_code === shipment.sender_country
                  )?.country_name_tr
                }
              >
                <span>
                  <CircleFlag
                    countryCode={countriesDetailed
                      .find((e) => e.iso_code === shipment.sender_country)
                      ?.iso_code?.toLowerCase()}
                    height="24"
                  />
                </span>
              </Tooltip>
              <FlightTakeoffOutlinedIcon id="take-off" />
            </div>
          </Box>
          <Box className="to-plane">
            {
              countriesDetailed.find(
                (e) => e.iso_code === shipment.receiver_country
              )?.country_name_tr
            }
            <div className="plane-flag">
              <FlightLandOutlinedIcon id="land" />
              <Tooltip
                title={
                  countriesDetailed.find(
                    (e) => e.iso_code === shipment.receiver_country
                  )?.country_name_tr
                }
              >
                <span>
                  <CircleFlag
                    countryCode={countriesDetailed
                      .find((e) => e.iso_code === shipment.receiver_country)
                      ?.iso_code?.toLowerCase()}
                    height="24"
                  />
                </span>
              </Tooltip>
            </div>
          </Box>
        </Box>
        <Box className="stepper-wrapper-css">
          <Tooltip
            title={Number(shipment.status_stage) === 0 && shipment.status_name}
          >
            <div className="step-wrapper-css">
              <div
                className="step-css"
                style={
                  Number(shipment.status) === 8
                    ? { backgroundColor: "var(--shipmentRed)" }
                    : Number(shipment.status_stage) === 0
                      ? { backgroundColor: "var(--shipmentYellow)" }
                      : { backgroundColor: "var(--shipmentGreen)" }
                }
              >
                <img
                  src={shipmentLogo_0}
                  className="step-logo-css"
                  alt="Gönderi 1.Aşama Görseli"
                />
              </div>
            </div>
          </Tooltip>
          <Tooltip
            title={Number(shipment.status_stage) === 1 && shipment.status_name}
          >
            <div className="step-wrapper-css">
              <div
                className="step-css"
                style={
                  Number(shipment.status) === 8
                    ? { backgroundColor: "var(--shipmentRed)" }
                    : Number(shipment.status_stage) === 1
                      ? { backgroundColor: "var(--shipmentYellow)" }
                      : Number(shipment.status_stage) < 1
                        ? { backgroundColor: "var(--shipmentLight)" }
                        : { backgroundColor: "var(--shipmentGreen)" }
                }
              >
                <img
                  src={shipmentLogo_1}
                  className="step-logo-css"
                  alt="Gönderi 2.Aşama Görseli"
                />
              </div>
            </div>
          </Tooltip>
          <Tooltip
            title={Number(shipment.status_stage) === 2 && shipment.status_name}
          >
            <div className="step-wrapper-css">
              <div
                className="step-css"
                style={
                  Number(shipment.status) === 8
                    ? { backgroundColor: "var(--shipmentRed)" }
                    : Number(shipment.status_stage) === 2
                      ? { backgroundColor: "var(--shipmentYellow)" }
                      : Number(shipment.status_stage) < 2
                        ? { backgroundColor: "var(--shipmentLight)" }
                        : { backgroundColor: "var(--shipmentGreen)" }
                }
              >
                <img
                  src={shipmentLogo_2}
                  className="step-logo-css"
                  alt="Gönderi 3.Aşama Görseli"
                />
              </div>
            </div>
          </Tooltip>
          <Tooltip
            title={Number(shipment.status_stage) === 3 && shipment.status_name}
          >
            <div className="step-wrapper-css">
              <div>
                <img
                  className="step-shipment-logo-css"
                  src={`/images/${shipment.get_parcel_supplier?.supplier_logo}`}
                  style={{
                    width: "60px",
                    height: "62px"
                  }}
                />
              </div>
            </div>
          </Tooltip>
          <Tooltip
            title={Number(shipment.status_stage) === 4 && shipment.status_name}
          >
            <div className="step-wrapper-css">
              <div
                className="step-css"
                style={
                  Number(shipment.status) === 8
                    ? { backgroundColor: "var(--shipmentRed)" }
                    : Number(shipment.status_stage) === 4
                      ? { backgroundColor: "var(--shipmentYellow)" }
                      : Number(shipment.status_stage) < 4
                        ? { backgroundColor: "var(--shipmentLight)" }
                        : { backgroundColor: "var(--shipmentGreen)" }
                }
              >
                <img
                  src={shipmentLogo_4}
                  className="step-logo-css"
                  alt="Gönderi 5.Aşama Görseli"
                />
              </div>
            </div>
          </Tooltip>
          <Tooltip
            title={Number(shipment.status_stage) === 5 && shipment.status_name}
          >
            <div className="step-wrapper-css">
              <div
                className="step-css"
                style={
                  Number(shipment.status) === 8
                    ? { backgroundColor: "var(--shipmentRed)" }
                    : Number(shipment.status_stage) === 5
                      ? { backgroundColor: "var(--shipmentYellow)" }
                      : Number(shipment.status_stage) < 5
                        ? { backgroundColor: "var(--shipmentLight)" }
                        : { backgroundColor: "var(--shipmentGreen)" }
                }
              >
                <img
                  src={shipmentLogo_5}
                  className="step-logo-css"
                  alt="Gönderi 6.Aşama Görseli"
                />
              </div>
            </div>
          </Tooltip>
          {/* <div className="step-connector-css">
            <div
              className={shipment.status >= 1 ? "step active" : "step1"}
            ></div>
            <div
              className={shipment.status >= 2 ? "step active" : "step2"}
            ></div>
            <div
              className={shipment.status >= 3 ? "step active" : "step3"}
            ></div>
            <div
              className={shipment.status >= 4 ? "step active" : "step4"}
            ></div>
            <div
              className={shipment.status >= 5 ? "step active" : "step5"}
            ></div>
          </div> */}
        </Box>
        <Box className="steps-info">
          <Box
            sx={{
              padding: "10px",
              borderRadius: "var(--inputBorderRadius)",
              ":hover": { boxShadow: "var(--shadow)", cursor: "pointer" },
            }}
            onClick={
              shipment.doc_type === "N" ? handleShipmentListItemDimension : ""
            }
            className="steps-info-wrapper info-member"
          >
            <div className="info-top">Üye Tarafından Beyan Edilen Kilo</div>
            <div className="info-top-mobil">Üye Ölçü Beyanı</div>
            {shipment.doc_type === "N" ? (
              <div className="info-bottom">
                <div className="info-bottom-subline">
                  NET KG
                  <div className="info-bottom-subline-number">
                    {shipment.get_parcel_dimensions
                      .filter((e) => e.status === "1")
                      .reduce(
                        (acc, val) => Number(acc) + Number(val.total_gw),
                        0
                      )
                      .toFixed(2)}
                  </div>
                </div>
                <div className="info-bottom-subline">
                  DESİ
                  <div className="info-bottom-subline-number">
                    {shipment.get_parcel_dimensions
                      .filter((e) => e.status === "1")
                      .reduce(
                        (acc, val) => Number(acc) + Number(val.total_vw),
                        0
                      )
                      .toFixed(2)}
                  </div>
                </div>
                <div className="info-bottom-subline">
                  NAVLUN KG
                  <div className="info-bottom-subline-number">
                    {shipment.get_parcel_dimensions
                      .filter((e) => e.status === "1")
                      .reduce(
                        (acc, val) => Number(acc) + Number(val.total_cw),
                        0
                      )
                      .toFixed(2)}
                  </div>
                </div>
              </div>
            ) : (
              <div className="info-bottom" style={{ justifyContent: "center" }}>
                <div className="info-bottom-subline">
                  NET KG
                  <div className="info-bottom-subline-number">
                    {Number(shipment?.cw).toFixed(2)}
                  </div>
                </div>
              </div>
            )}
          </Box>
          {Number(shipment.paid) === 0 ? (
            <Box
              id="integer_done"
              className="steps-info-done"
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                }}
              >
                <span className="steps-info-done-price">
                  {Currency(shipment.currency)}
                  {LocalPrice(shipment.remaining_payment)}
                </span>
                {loading ? (
                  <CircularProgress
                    sx={{
                      width: "21px!important",
                      height: "21px!important",
                    }}
                  />
                ) : (
                  <Button
                    onClick={handlePaymentDialogOpen}
                    className="steps-info-done-button"
                  // disabled={isButtonDisabled}
                  >
                    {isButtonDisabled
                      ? "Ödeme Yapmak İçin Profilinizi Tamamlayın!"
                      : shipment?.paid_name}
                  </Button>
                )}
              </div>
              <div
                style={{
                  color: "var(--priceText)",
                  backgroundColor: "var(--background)",
                  padding: "10px",
                  borderRadius: "var(--wrapperBorderRadius)",
                  boxShadow: "var(--shadow)",
                  fontSize: "12px",
                }}
              >
                Güncel:{" "}
                <span style={{ fontWeight: 600, fontSize: "inherit" }}>
                  {FormatHeadlineText(shipment.status_name)}
                </span>
                <span style={{ fontWeight: 600, fontSize: "inherit" }}>
                  {shipment?.get_parcel_documents.length > 0 && (
                    <div>
                      {shipment.get_parcel_documents
                        .slice(-1)
                        .map((document, index) => {
                          const result =
                            document.type === "booking_damaged_parcel"
                              ? "Hasarlı / Teslim Alındı"
                              : document.type === "booking_cancel_parcel"
                                ? "Hasarlı / Reddedildi"
                                : null;

                          if (result) {
                            return (
                              <div>
                                {shipment?.domestic_supplier === "iyziCourier" && (
                                  <div
                                    style={{
                                      fontSize: "12px",
                                      color: "var(--priceText)",
                                      fontWeight: "lighter",
                                    }}
                                    key="additionalStatus"
                                  >
                                    Kurye Kaydı Durumu:{" "}
                                    <span
                                      style={{
                                        fontWeight: 600,
                                        fontSize: "inherit",
                                      }}
                                    >
                                      {result}
                                    </span>
                                  </div>
                                )}
                              </div>
                            );
                          }

                          return null;
                        })}
                    </div>
                  )}
                </span>
              </div>
            </Box>
          ) : Number(shipment.paid) === 2 ? (
            <Box
              id="integer_done"
              className="steps-info-done"
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                }}
              >
                <span className={getPaidClassName(shipment.status)}>
                  {Currency(shipment.currency)}
                  {LocalPrice(paidPrice)}
                </span>
                <Button
                  sx={{
                    backgroundColor:
                      shipment.status === "0.05"
                        ? "var(--shipmentYellow)!important"
                        : "inherit",
                  }}
                  style={{ textAlign: "center" }}
                  className={getClassName(shipment.status)}
                  disabled
                >
                  {/* {result} */}
                  {shipment?.paid_name}
                </Button>
              </div>
              <div
                style={{
                  color: "var(--priceText)",
                  backgroundColor: "var(--background)",
                  padding: "10px",
                  borderRadius: "var(--wrapperBorderRadius)",
                  boxShadow: "var(--shadow)",
                  fontSize: "12px",
                }}
              >
                Güncel:{" "}
                <span style={{ fontWeight: 600, fontSize: "inherit" }}>
                  {FormatHeadlineText(shipment.status_name)}
                </span>
                <span style={{ fontWeight: 600, fontSize: "inherit" }}>
                  {shipment?.get_parcel_documents.length > 0 && (
                    <div>
                      {shipment.get_parcel_documents
                        .slice(-1)
                        .map((document, index) => {
                          const result =
                            document.type === "booking_damaged_parcel"
                              ? "Hasarlı / Teslim Alındı"
                              : document.type === "booking_cancel_parcel"
                                ? "Hasarlı / Reddedildi"
                                : null;

                          if (result) {
                            return (
                              <div>
                                {shipment?.domestic_supplier === "iyziCourier" && (
                                  <div
                                    style={{
                                      fontSize: "12px",
                                      color: "var(--priceText)",
                                      fontWeight: "lighter",
                                    }}
                                    key="additionalStatus"
                                  >
                                    Kurye Kaydı Durumu:{" "}
                                    <span
                                      style={{
                                        fontWeight: 600,
                                        fontSize: "inherit",
                                      }}
                                    >
                                      {result}
                                    </span>
                                  </div>
                                )}
                              </div>
                            );
                          }

                          return null;
                        })}
                    </div>
                  )}
                </span>
              </div>
            </Box>
          ) : (
            <Box
              id="integer_done"
              className="steps-info-done"
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                }}
              >
                <span className={getPaidClassName(shipment.status)}>
                  {Currency(shipment.currency)}
                  {LocalPrice(paidPrice)}
                </span>
                <Button
                  sx={{
                    backgroundColor:
                      shipment.status === "0.05"
                        ? "var(--shipmentYellow)!important"
                        : "inherit",
                  }}
                  style={{ textAlign: "center" }}
                  className={getClassName(shipment.status)}
                >
                  {/* {result} */}
                  {shipment?.paid_name}
                </Button>
              </div>
              <div
                style={{
                  color: "var(--priceText)",
                  backgroundColor: "var(--background)",
                  padding: "10px",
                  borderRadius: "var(--wrapperBorderRadius)",
                  boxShadow: "var(--shadow)",
                  fontSize: "12px",
                }}
              >
                Güncel:{" "}
                <span style={{ fontWeight: 600, fontSize: "inherit" }}>
                  {FormatHeadlineText(shipment.status_name)}
                </span>
                <span style={{ fontWeight: 600, fontSize: "inherit" }}>
                  {shipment?.get_parcel_documents.length > 0 && (
                    <div>
                      {shipment.get_parcel_documents
                        .slice(-1)
                        .map((document, index) => {
                          const result =
                            document.type === "booking_damaged_parcel"
                              ? "Hasarlı / Teslim Alındı"
                              : document.type === "booking_cancel_parcel"
                                ? "Hasarlı / Reddedildi"
                                : null;

                          if (result) {
                            return (
                              <div>
                                {shipment?.domestic_supplier === "iyziCourier" && (
                                  <div
                                    style={{
                                      fontSize: "12px",
                                      color: "var(--priceText)",
                                      fontWeight: "lighter",
                                    }}
                                    key="additionalStatus"
                                  >
                                    Kurye Kaydı Durumu:{" "}
                                    <span
                                      style={{
                                        fontWeight: 600,
                                        fontSize: "inherit",
                                      }}
                                    >
                                      {result}
                                    </span>
                                  </div>
                                )}
                              </div>
                            );
                          }

                          return null;
                        })}
                    </div>
                  )}
                </span>
              </div>
            </Box>
          )}
          <Box
            sx={{
              padding: "10px",
              borderRadius: "var(--inputBorderRadius)",
              ":hover": { boxShadow: "var(--shadow)", cursor: "pointer" },
            }}
            onClick={
              shipment.doc_type === "N" ? handleShipmentListItemDimension : ""
            }
            className="steps-info-wrapper info-system"
          >
            <div className="info-top">
              iyziTeam Tarafından Beyan Edilen Kilo
            </div>
            <div className="info-top-mobil">iyziTeam Ölçü Beyanı</div>
            {shipment.doc_type === "N" ? (
              <div className="info-bottom">
                <div className="info-bottom-subline">
                  NET KG
                  <div className="info-bottom-subline-number">
                    {Number(shipment?.gw_real).toFixed(2)}
                  </div>
                </div>
                <div className="info-bottom-subline">
                  DESİ
                  <div className="info-bottom-subline-number">
                    {Number(shipment?.vw_real).toFixed(2)}
                  </div>
                </div>
                <div className="info-bottom-subline">
                  NAVLUN KG
                  <div className="info-bottom-subline-number">
                    {Number(shipment?.cw_real).toFixed(2)}
                  </div>
                </div>
              </div>
            ) : (
              <div className="info-bottom" style={{ justifyContent: "center" }}>
                <div className="info-bottom-subline">
                  NET KG
                  <div className="info-bottom-subline-number">
                    {Number(shipment?.cw_real).toFixed(2)}
                  </div>
                </div>
              </div>
            )}
          </Box>
        </Box>
        {Number(shipment.paid) === 0 ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
            id="seperate_done"
            className="steps-info-done show"
          >
            <div
              style={{
                display: "flex",
                gap: "5px",
              }}
            >
              <span className="steps-info-done-price unpaid">
                {Currency(shipment.currency)}
                {LocalPrice(shipment.remaining_payment)}
              </span>
              {loading ? (
                <CircularProgress
                  sx={{
                    width: "21px!important",
                    height: "21px!important",
                  }}
                />
              ) : (
                <Button
                  onClick={handlePaymentDialogOpen}
                  className="steps-info-done-button"
                // disabled={isButtonDisabled}
                >
                  {isButtonDisabled
                    ? "Ödeme Yapmak İçin Profilinizi Tamamlayın!"
                    : shipment?.paid_name}
                </Button>
              )}
            </div>
            <div
              style={{
                color: "var(--priceText)",
                backgroundColor: "var(--background)",
                padding: "10px",
                borderRadius: "var(--wrapperBorderRadius)",
                boxShadow: "var(--shadow)",
                fontSize: "12px",
              }}
            >
              Güncel:{" "}
              <span style={{ fontWeight: 600, fontSize: "inherit" }}>
                {FormatHeadlineText(shipment.status_name)}
              </span>
              <span style={{ fontWeight: 600, fontSize: "inherit" }}>
                {shipment?.get_parcel_documents.length > 0 && (
                  <div>
                    {shipment.get_parcel_documents
                      .slice(-1)
                      .map((document, index) => {
                        const result =
                          document.type === "booking_damaged_parcel"
                            ? "Hasarlı / Teslim Alındı"
                            : document.type === "booking_cancel_parcel"
                              ? "Hasarlı / Reddedildi"
                              : null;

                        if (result) {
                          return (
                            <div>
                              {shipment?.domestic_supplier === "iyziCourier" && (
                                <div
                                  style={{
                                    fontSize: "12px",
                                    color: "var(--priceText)",
                                    fontWeight: "lighter",
                                  }}
                                  key="additionalStatus"
                                >
                                  Kurye Kaydı Durumu:{" "}
                                  <span
                                    style={{
                                      fontWeight: 600,
                                      fontSize: "inherit",
                                    }}
                                  >
                                    {result}
                                  </span>
                                </div>
                              )}
                            </div>
                          );
                        }

                        return null;
                      })}
                  </div>
                )}
              </span>
            </div>
          </Box>
        ) : Number(shipment.paid) === 2 ? (
          <Box
            id="integer_done"
            className="steps-info-done"
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "5px",
              }}
            >
              <span className={getPaidClassName(shipment.status)}>
                {Currency(shipment.currency)}
                {LocalPrice(paidPrice)}
              </span>
              <Button
                sx={{
                  backgroundColor:
                    shipment.status === "0.05"
                      ? "var(--shipmentYellow)!important"
                      : "inherit",
                }}
                style={{ textAlign: "center" }}
                className={getClassName(shipment.status)}
                disabled
              >
                {/* {result} */}
                {shipment?.paid_name}
              </Button>
            </div>
            <div
              style={{
                color: "var(--priceText)",
                backgroundColor: "var(--background)",
                padding: "10px",
                borderRadius: "var(--wrapperBorderRadius)",
                boxShadow: "var(--shadow)",
                fontSize: "12px",
              }}
            >
              Güncel:{" "}
              <span style={{ fontWeight: 600, fontSize: "inherit" }}>
                {FormatHeadlineText(shipment.status_name)}
              </span>
              <span style={{ fontWeight: 600, fontSize: "inherit" }}>
                {shipment?.get_parcel_documents.length > 0 && (
                  <div>
                    {shipment.get_parcel_documents
                      .slice(-1)
                      .map((document, index) => {
                        const result =
                          document.type === "booking_damaged_parcel"
                            ? "Hasarlı / Teslim Alındı"
                            : document.type === "booking_cancel_parcel"
                              ? "Hasarlı / Reddedildi"
                              : null;

                        if (result) {
                          return (
                            <div>
                              {shipment?.domestic_supplier === "iyziCourier" && (
                                <div
                                  style={{
                                    fontSize: "12px",
                                    color: "var(--priceText)",
                                    fontWeight: "lighter",
                                  }}
                                  key="additionalStatus"
                                >
                                  Kurye Kaydı Durumu:{" "}
                                  <span
                                    style={{
                                      fontWeight: 600,
                                      fontSize: "inherit",
                                    }}
                                  >
                                    {result}
                                  </span>
                                </div>
                              )}
                            </div>
                          );
                        }

                        return null;
                      })}
                  </div>
                )}
              </span>
            </div>
          </Box>
        ) : (
          <Box
            id="seperate_done"
            className="steps-info-done show"
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "5px",
              }}
            >
              <span className={getPaidClassName(shipment.status)}>
                {Currency(shipment.currency)}
                {LocalPrice(paidPrice)}
              </span>
              <Button
                style={{ textAlign: "center" }}
                className={getClassName(shipment.status)}
                sx={{
                  backgroundColor:
                    shipment.status === "0.05"
                      ? "var(--shipmentYellow)!important"
                      : "inherit",
                }}
              >
                {/* {result} */}
                {shipment?.paid_name}
              </Button>
            </div>

            {/* ONAY */}
            <div
              style={{
                color: "var(--priceText)",
                backgroundColor: "var(--background)",
                padding: "10px",
                borderRadius: "var(--wrapperBorderRadius)",
                boxShadow: "var(--shadow)",
                fontSize: "12px",
              }}
            >
              Güncel:{" "}
              <span style={{ fontWeight: 600, fontSize: "inherit" }}>
                {FormatHeadlineText(shipment.status_name)}
              </span>
              <span style={{ fontWeight: 600, fontSize: "inherit" }}>
                {shipment?.get_parcel_documents.length > 0 && (
                  <div>
                    {shipment.get_parcel_documents
                      .slice(-1)
                      .map((document, index) => {
                        const result =
                          document.type === "booking_damaged_parcel"
                            ? "Hasarlı / Teslim Alındı"
                            : document.type === "booking_cancel_parcel"
                              ? "Hasarlı / Reddedildi"
                              : null;

                        if (result) {
                          return (
                            <div>
                              {shipment?.domestic_supplier === "iyziCourier" && (
                                <div
                                  style={{
                                    fontSize: "12px",
                                    color: "var(--priceText)",
                                    fontWeight: "lighter",
                                  }}
                                  key="additionalStatus"
                                >
                                  Kurye Kaydı Durumu:{" "}
                                  <span
                                    style={{
                                      fontWeight: 600,
                                      fontSize: "inherit",
                                    }}
                                  >
                                    {result}
                                  </span>
                                </div>
                              )}
                            </div>
                          );
                        }

                        return null;
                      })}
                  </div>
                )}
              </span>
            </div>
          </Box>
        )}
        {shipment.get_parcel_notes.length !== 0 &&
          shipment.get_parcel_notes
            .filter((e) => e.type === "1")
            .map((e) => (
              <Box key={e.id} className="steps-note">
                <span>NOT :</span> {e.description}
              </Box>
            ))}
        <div className="support-desktop-wrapper">
          <Button
            fullWidth
            onClick={handleShipmentDetail}
            className={trackingShipmentButtonColor}
            sx={{
              height: "35px",
              backgroundColor:
                shipment?.status === "0.05"
                  ? "#fdfd965c !important"
                  : "inherit",
            }}
          >
            {detailLoading ? (
              <CircularProgress
                sx={{ width: "21px!important", height: "21px!important" }}
              />
            ) : (
              "Gönderi Takip"
            )}
          </Button>
          <Link
            className="support-desktop"
            to={`/support?awb=${shipment.parcel_ref_no}`}
          >
            <LiveHelpOutlinedIcon />
            <span>Destek Talebi</span>
          </Link>
        </div>
      </Box>
      <ListItemLinksMobil
        shipment={shipment}
        options={options}
        setOptions={setOptions}
      />
      <Divider
        sx={{
          width: "1px",
          backgroundColor: "var(--phoneDigitBg)",
          margin: "0 10px",
        }}
        className="steps-divider"
      />
      <Box className="list-links-wrapper">
        <div className="list-links-container">
          <h6 style={{ fontSize: "13px", textAlign: "center" }}>
            {shipment.parcel_ref_no}
          </h6>
          <ListItemLinks shipment={shipment} />
          <Link
            className={`list-links-help ${shipment.status === "0.05" ? "list-links-disabled" : ""
              }`}
            style={{
              padding: "10px",
              borderRadius: "var(--wrapperBorderRadius)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textDecoration: "none",
            }}
            to={`/support?awb=${shipment.parcel_ref_no}`}
          >
            <LiveHelpOutlinedIcon />
            <span>Destek Talebi</span>
          </Link>
        </div>
      </Box>
      <Box className="list-picker-wrapper">
        <Button
          id="basic-menu"
          onClick={handleOptions}
          className="list-options"
          sx={{ minWidth: "33px", borderRadius: "50%" }}
        >
          <MoreVertIcon />
        </Button>
      </Box>
    </Box>
  );
};

export default MyShipmentListItem;
