import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
} from "@mui/material";
import {
  basketCheckListState,
  basketInfoState,
  basketItemsState,
  basketPaymentOpenState,
  basketTotalPriceTryState,
  freshListState,
} from "../../atoms/basket";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useCallback, useEffect, useState } from "react";
import axios from "../../api/axios";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Errors from "../../utils/Errors";
import { NavLink, useLocation } from "react-router-dom";

const BasketDetailedPayment = () => {
  const location = useLocation();
  const [dialog, setDialog] = useState(false);
  const [amount, setAmount] = useState(null);
  const [dialogStatus, setDialogStatus] = useState(null);
  const [freshList, setFreshList] = useRecoilState(freshListState);

  const [basketItems, setBasketItems] = useRecoilState(basketItemsState);
  const setBasketPaymentOpen = useSetRecoilState(basketPaymentOpenState);
  const setBasketTotalPriceTry = useSetRecoilState(basketTotalPriceTryState);
  const setBasketInfo = useSetRecoilState(basketInfoState);
  const [basketCheckList, setBasketCheckList] = useRecoilState(basketCheckListState);

  useEffect(() => {
    if (basketCheckList.length !== 0) {
      setBasketCheckList([]);
    }
  }, []);

  const handleAllCheck = useCallback(() => {
    if (basketCheckList.length === 0) {
      setBasketCheckList(basketItems);

      const newTotalPriceTry = basketItems.reduce(
        (acc, item) => acc + parseFloat(item.get_payment.get_parcel.remaining_payment_try),
        0
      );

      setBasketTotalPriceTry(Number(newTotalPriceTry).toFixed(2));
    } else {
      if (basketCheckList.length !== basketItems.length) {
        setBasketCheckList(basketItems);

        const newTotalPriceTry = basketItems.reduce(
          (acc, item) => acc + parseFloat(item.get_payment.get_parcel.remaining_payment_try),
          0
        );

        setBasketTotalPriceTry(Number(newTotalPriceTry).toFixed(2));
      } else {
        setBasketCheckList([]);
        setBasketTotalPriceTry("0.00");
      }
    }
  }, [setBasketCheckList, basketCheckList, basketItems, setBasketTotalPriceTry]);

  const handleDialog = (e) => {
    if (e === 1) {
      setDialogStatus("Delete");
    } else if (e === 2) {
      setDialogStatus("Payment");
    }
    setDialog(true);
  };

  const handleDialogClose = () => {
    setDialog(false);
    setDialogStatus("");
  };

  const handlePayment = useCallback(() => {
    setDialog(false);
    setBasketPaymentOpen(true);
  }, [setBasketPaymentOpen]);

  const handleDelete = useCallback(() => {
    axios
      .post(`basket/delete-multiple`, {
        ids: basketCheckList.map((e) => e.id),
      })
      .then(() => {
        window.location.reload(true);
      })
      .catch((error) => Errors(error));
  }, [basketCheckList]);

  useEffect(() => {
    if (basketCheckList.length !== 0) {
      const value = basketCheckList
        .map((item) => parseFloat(item.get_payment.get_parcel.remaining_payment_try).toFixed(2))
        .reduce((sum, value) => sum + Number(value), 0);
      setAmount(parseFloat(value).toFixed(2));
    }
  }, [basketCheckList]);

  const formattedAmount = Number(amount).toLocaleString("tr-TR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  // Disabled Payment Button
  const member = JSON.parse(localStorage.getItem("user"));
  const memberStatus = member?.user?.user_member?.status;
  const isButtonDisabled = Number(memberStatus) !== 2;

  const handlePay = () => {
    /*/ if (basketItems.length > 5) {
       setBasketInfo(true);
     } else {
       handleDialog(2);
     }/*/
    handleDialog(2);
  };

  const handleGetPendingList = useCallback(() => {
    axios.get("basket", {
      params: {
        detailed: 1, unlimited: 1, status: 2.17, limit: "5"
      },
    }).then((response) => {
      if (response.status === 200 && response.data.data) {
        setBasketItems(response.data.data);
        setFreshList(true)
      }
    }).catch((error) => Errors(error));
  }, [setBasketItems]);

  const handleGetFreshList = () => {
    axios.get("basket", {
      params: {
        detailed: 1, limit: 5
      },
    }).then((response) => {
      if (response.status === 200 && response.data.data) {
        setBasketItems(response.data.data);
        setFreshList(false);
      }
    }).catch((error) => Errors(error));
  };

  return (
    <>
      <Dialog
        open={dialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ borderRadius: "var(--wrapperBorderRadius)" }}
      >
        {dialogStatus === "Payment" && (
          <DialogTitle id="alert-dialog-title">Toplam Tutar</DialogTitle>
        )}
        {dialogStatus === "Delete" && (
          <DialogTitle id="alert-dialog-title">Toplu Silme</DialogTitle>
        )}
        <DialogContent>
          {dialogStatus === "Payment" && (
            <DialogContentText id="alert-dialog-description">
              Ödeme yapacağınız toplam tutar: <b>{formattedAmount} ₺</b>
            </DialogContentText>
          )}
          {dialogStatus === "Delete" && (
            <DialogContentText id="alert-dialog-description">
              Seçili gönderileri sepetten kaldırmak istediğinize emin misiniz?
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions sx={{ padding: "8px 20px 20px" }}>
          <div
            style={{
              display: "flex",
              gap: "var(--gap2x)",
              justifyContent: "flex-end",
            }}
          >
            <Button type="button" className="iyzi-button" onClick={handleDialogClose}>
              Vazgeç
            </Button>
            <Button
              className="iyzi-button"
              onClick={dialogStatus === "Delete" ? handleDelete : handlePayment}
            >
              {dialogStatus === "Delete" ? "Sil" : "Ödeme Yap"}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      {isButtonDisabled ? (
        <div className="basket-detailed-payment error">
          <ErrorOutlineIcon /> Ödeme Yapmak İçin Profilinizi Tamamlayın{" "}
          <NavLink
            className="iyzi-button"
            style={{ padding: "10px 20px", textDecoration: "none" }}
            to={"/profile-settings/general"}
          >
            Profil Ayarlarına Git
          </NavLink>
        </div>
      ) : (
        <div className="basket-detailed-payment success">
          <FormControlLabel
            className="basket-detailed-payment-checkbox"
            control={
              <Checkbox
                checked={basketCheckList.length === basketItems.length}
                onChange={handleAllCheck}
              />
            }
            label="Tümünü Seç"
          />
          <div className="button-container">
            <Button
              onClick={() => handleGetPendingList()}
              className="iyzi-button-blue"
            >
              Çıkış Öncesi Ödeme Bekleyenleri Getir
            </Button>
            {freshList && (
              <Button
                onClick={() => handleGetFreshList()}
                className="iyzi-button-blue"
              >
                Sepeti Yenile
              </Button>
            )}

            <Button
              disabled={basketCheckList.length < 1}
              onClick={() => handleDialog(1)}
              className="iyzi-button-blue"
              sx={{
                ":disabled": {
                  opacity: 0.4,
                },
              }}
            >
              Seçilenleri Sil
            </Button>
            <Button
              disabled={basketCheckList.length < 1 || isButtonDisabled}
              onClick={handlePay}
              className="iyzi-button-blue"
              sx={{
                opacity: basketCheckList.length < 1 || isButtonDisabled ? 0.4 : 1,
              }}
            >
              Seçilenleri Öde
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default BasketDetailedPayment;
