import { useEffect } from "react";
import { TableCell, TableRow, Checkbox } from "@mui/material";
import countries from "../../../../json/countriesDetailed.json";
import { useRecoilState } from "recoil";
import { fastListState } from "../../../../atoms/fastShipment";

const SenderAddressTableBody = ({ item, address, setAddressID, addressID, setSenderName }) => {
  const [fastList, setFastList] = useRecoilState(fastListState);

  const handleCheckboxChange = (id) => {
    if (typeof setAddressID !== 'function' || typeof setSenderName !== 'function') {
      return;
    }

    setAddressID(prevSelected => (prevSelected === id ? null : id));
    setSenderName(address?.contact_name);

    setFastList(prevList => {
      return prevList.map(fast => {
        if (fast.id === item.id) {
          return {
            ...fast,
            addressID: id,
            senderName: address?.contact_name,
          };
        }
        return fast;
      });
    });
  };

  useEffect(() => {
    if (address?.is_default === "1" && !addressID) {
      if (typeof setAddressID !== 'function' || typeof setSenderName !== 'function') {
        console.error('setAddressID or setSenderName is not a function');
        return;
      }
      setAddressID(address.id);
      setSenderName(address.contact_name);

      setFastList(prevList => {
        return prevList.map(fast => {
          if (fast.id === item.id) {
            return {
              ...fast,
              addressID: address.id,
              senderName: address.contact_name,
            };
          }
          return fast;
        });
      });
    }
  }, [address?.is_default, addressID]);

  return (
    <TableRow>
      {window.location.href.includes("create-fast-shipment") && (
        <TableCell>
          <Checkbox
            checked={addressID === address?.id || (address?.is_default === "1" && !addressID)}
            onChange={() => handleCheckboxChange(address?.id)}
          />
        </TableCell>
      )}
      <TableCell>{address?.address_description}</TableCell>
      <TableCell>{address?.contact_name}</TableCell>
      <TableCell>{address?.postal_code}</TableCell>
      <TableCell>{address?.phone}</TableCell>
      <TableCell>{address?.email}</TableCell>
      <TableCell>{address?.state}</TableCell>
      <TableCell>{address?.town}</TableCell>
      <TableCell>{address?.city}</TableCell>
      <TableCell>
        {
          countries.find(country => country.iso_code === address?.country)?.country_name_tr
        }
      </TableCell>
      <TableCell>
        {address?.address1} {address?.address2}
      </TableCell>
    </TableRow>
  );
};

export default SenderAddressTableBody;
